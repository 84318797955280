import React, { useEffect, useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { accountSettings, me, uploadProfilePicture } from 'utils/service';
import { showToastMessage } from 'utils/Toast';
import {
  containAzDchar,
  containAzchar,
  containNumChar,
  maxDynamicChar,
  min3char,
  minDynamicChar,
  mustBeValidUrl,
  mustHaveLetters,
  containAzDcharDot,
} from 'utils/formRules';

import { convertNumber } from 'utils/generics';
import { errorMessage } from 'utils/getErrors';

export default function AccountDetails() {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [imageName, setImageName] = useState('');
  const [preview_image, setpreview_image] = useState('');
  const [file, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [settingForm] = Form.useForm();

  useEffect(() => {
    getProfile();
  }, []);

  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    let preview = URL.createObjectURL(e.target.files[0]);
    setpreview_image(preview);
    setImageName(e.target.files[0].name);
  };

  const SaveImage = async () => {
    setLoading(true);
    const payload = new FormData();
    payload.append('file', file);
    try {
      const { data } = await uploadProfilePicture(payload);
      if (data?.success) {
        getProfile();
        setLoading(false);
        setImageName('');
        showToastMessage({
          type: 'success',
          title: 'Updated',
          description: 'Image Updated',
        });
      }
    } catch (error) {
      setImageLoading(false);

      if (error.response.data?.success === false) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response.data?.message,
        });
      }
    }
  };

  const onFinishSetting = async (values) => {
    setLoading(true);
    try {
      const { data } = await accountSettings(values);
      if (data?.success) {
        setLoading(false);
        updateForm(data.data.data);
        showToastMessage({
          type: 'success',
          title: 'Update Successful',
          description: 'Account Details updated successfully',
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.success === false) {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response.data?.message,
        });
      }
    }
  };
  const onFinishFailedSetting = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getProfile = async () => {
    setIsLoading(true);
    let data = await me();
    if (data) {
      setIsLoading(false);
      setData(data);
      updateForm(data?.user);
    }
  };

  const updateForm = (data) => {
    settingForm.setFieldsValue({
      ['fullname']: data?.fullname,
      ['email']: data?.email,
      ['phoneNumber']: data?.phoneNumber,
      ['language']: data?.language,
      ['companyName']: data?.companyName,
      ['website']: data?.website,
      ['city']: data?.city,
      ['state']: data?.state,
      ['officeAddress']: data?.officeAddress ?? '',
    });
  };

  return (
    <div className="mt-5 AccountSettingsContainer borderShade1">
      <div className="profileWrapper">
        <div className="relative">
          <img
            alt="profile"
            className="accountAvatar"
            src={preview_image ? preview_image : data?.user?.picture ?? '/assets/avatar.svg'}
          ></img>
          <label className="uploadPicture centered" htmlFor="file">
            <input type="file" name="file" id="file" onChange={(e) => onImageChange(e)} />
            <EditFilled />
          </label>
        </div>

        {imageName && (
          <Button loading={loading} className="mt-4 ml-2" type="primary" size="middle" onClick={SaveImage}>
            Upload Image
          </Button>
        )}
      </div>
      <Form
        name="basic"
        initialValues={{
          remember: true,
        }}
        form={settingForm}
        onFinish={onFinishSetting}
        onFinishFailed={onFinishFailedSetting}
      >
        <div className="grid2 inputLists">
          <div>
            <label>Full Name</label>
            <Form.Item
              name="fullname"
              rules={[
                {
                  required: true,
                  message: 'Please input your name!',
                },
                ...min3char,
                ...mustHaveLetters,
                ...containAzDchar,
              ]}
            >
              <Input placeholder="Jane Doe" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Email Address</label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email address!',
                },
                {
                  type: 'email',
                  message: 'please input valid email address',
                },
              ]}
            >
              <Input placeholder="Janedoe@email.com" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Phone Number</label>
            <Form.Item name="phoneNumber" rules={[...containNumChar, ...minDynamicChar(7), ...maxDynamicChar(15)]}>
              <Input placeholder="Enter a valid phone number" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Language</label>
            <Form.Item name="language">
              <Input placeholder="Enter your language" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Company Name</label>
            <Form.Item name="companyName" rules={[...min3char, ...containAzDcharDot, ...mustHaveLetters]}>
              <Input placeholder="Enter you company name" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>Website</label>
            <Form.Item name="website" rules={mustBeValidUrl}>
              <Input placeholder="Enter your websit url" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>City</label>
            <Form.Item name="city" rules={[...min3char, ...containAzchar]}>
              <Input placeholder="Enter your city" className="form-control" />
            </Form.Item>
          </div>
          <div>
            <label>State</label>
            <Form.Item name="state" rules={[...min3char, ...containAzchar]}>
              <Input placeholder="Enter your state" className="form-control" />
            </Form.Item>
          </div>
        </div>
        <div>
          <label>Office Address</label>
          <Form.Item name="officeAddress">
            <textarea cols="4" placeholder="Enter your address" className="form-control" />
          </Form.Item>
          <div className="mb-4 text-xs">
            Your contact details are safe with us. it will be automatically included in your email footers to ensure you
            are legally covered when sending bulk email.
          </div>
        </div>
        <div className="flex justify-end">
          <Button type="primary" size={'large'} htmlType="submit" loading={loading}>
            Save Changes
          </Button>
        </div>
      </Form>
    </div>
  );
}
