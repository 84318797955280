import { Form, Button, Modal, Input, Tooltip, Spin } from 'antd';
import Cookie from 'js-cookie';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { changePassword, deactivateUser } from 'utils/service';
import { showToastMessage } from 'utils/Toast';
import { passwordRules } from '../emmaccen/shared-data/passwordRule';

export default function AccountSecurityTab() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [showError, setShowError] = useState('');
  const [passwordForm] = Form.useForm();
  const [FailedPass, setFailedPass] = useState(false);
  const [PassMismatch, setPassMismatch] = useState(false);
  const [inputValues, setInputValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const showModal = () => {
    setIsPasswordModalVisible(true);
  };

  const handleOk = () => {
    setIsPasswordModalVisible(false);
  };

  const handleCancel = () => {
    setIsPasswordModalVisible(false);
  };
  const deactivateProfile = async () => {
    setLoading(true);
    try {
      const res = await deactivateUser({
        isActive: false,
      });

      const { success, data } = res.data;
      if (success) {
        setLoading(false);
        showToastMessage({
          // Type error is intentional
          type: 'error',
          title: 'Account Deactivated Successfully',
          description: "We're unhappy to see you go. Do leave us a feedback so that we can do better next time.",
        });
        Cookie.remove('token');
        localStorage.removeItem('userDetails');
        history.push('/login');
      }
    } catch (error) {
      setLoading(false);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: error.response?.data?.message,
      });
    }
  };

  const onFinish = async (values) => {
    setShowError('');
    setPassMismatch(false);
    setFailedPass(false);
    if (values.newPassword !== values.confirmPassword) {
      setPassMismatch(true);
    } else {
      setLoading(true);
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      try {
        const { data } = await changePassword(payload);
        if (data?.success) {
          setLoading(false);
          showToastMessage({
            type: 'success',
            title: 'Updated',
            description: 'Password Changed',
          });
        }
      } catch (error) {
        setLoading(false);
        if (error.response?.data?.success === false) {
          setShowError(error.response?.data?.fieldsError.message);
        }
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    setFailedPass(false);
    errorInfo.errorFields.map((val) => {
      if (val.name[0] === 'newPassword') {
        setFailedPass(true);
        return;
      }
    });
  };

  return (
    <>
      <Modal
        footer={false}
        title="Change Password"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isPasswordModalVisible}
        close={handleCancel}
      >
        <div className="settings-modal-wrapper">
          {showError && (
            <div className="text-xs text-red" align="right">
              {showError}
            </div>
          )}
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            form={passwordForm}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="form-group">
              <Form.Item
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: 'Enter old password',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Old Password"
                  className={showError ? 'form-control form-error' : 'form-control'}
                />
              </Form.Item>
            </div>
            <div className="form-group newPass-input">
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Enter new password',
                  },
                  ...passwordRules,
                ]}
              >
                <Input.Password
                  placeholder="New Password"
                  className="form-control"
                  onChange={(e) => {
                    setInputValues((p) => ({ ...p, password: e.target.value }));
                  }}
                />
              </Form.Item>
              <div className={FailedPass ? 'text-xs text-red' : 'text-xs'}>
                Use 6 or more characters with an upper case, a lower case, <br /> numbers & special characters like
                !%@#$&*
              </div>
            </div>
            <div className="form-group newPass-input">
              <Form.Item name="confirmPassword">
                <Input.Password
                  placeholder="Confirm Password"
                  className="form-control"
                  onChange={(e) => {
                    if (inputValues.password === e.target.value) {
                      setPassMismatch(false);
                    } else {
                      setPassMismatch(true);
                    }
                  }}
                />
              </Form.Item>

              {PassMismatch && <div className="text-xs text-red">Password do not match</div>}
            </div>
            <div className="btns">
              <button className="btn btn-blue" type="submit" disabled={loading}>
                Save
                {loading && <Spin />}
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      <div className="accountSecurity grid2 border">
        <div className="dangerSection">
          <p>
            Want to deactivate your account? If you're experiencing security problems or facing minor challenges, please
            send us a mail at{' '}
            <a className="primaryColorLight" href="mailto:maildrip.io@gmail.com">
              support@maildrip.com
            </a>{' '}
            our support team will reach out to you
          </p>
          <Tooltip
            placement="topLeft"
            title={'An account-reactivation mail will be sent to you the next time you try to login'}
            color="blue"
          >
            <Button
              onClick={deactivateProfile}
              danger
              style={{ backgroundColor: '#ff4d4f' }}
              type="primary"
              size="large"
              loading={loading}
            >
              Deactivate Account
            </Button>
          </Tooltip>
        </div>
        <div>
          <p className="">Forgot your password? No worries, you can change your password here.</p>
          <Button onClick={showModal} type="primary" size="large">
            Change Password
          </Button>
        </div>
      </div>
    </>
  );
}
