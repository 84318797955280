import { Button, Input, Modal, Slider } from 'antd';
import React, { useEffect, useState } from 'react';
import { sendFeedback } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';
const marks = {
  0: '0',
  10: '1',
  20: '2',
  30: '3',
  40: '4',
  50: '5',
  60: '6',
  70: '7',
  80: '8',
  90: '9',
  100: '10',
};

export const FeedbackForm = () => {
  const [showForm, setShowForm] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const { TextArea } = Input;
  const [data, setData] = useState({
    message: '',
    rating: 80,
    feedbackType: 0,
  });
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 3000);
  }, []);

  const handleCreate = () => {
    setImLoading({ id: 'sendingFeedback' });
    sendFeedback(data)
      .then((response) => {
        showToastMessage({
          title: 'Feedback Sent Successfully',
          type: 'success',
          description: 'Thanks for giving us your feedback. We love it!',
        });
      })
      .catch((error) => {
        showToastMessage({
          title: 'Feedback',
          type: 'error',
          description: `we're unable to send your feedback. Please try again`,
        });
      })
      .finally(() => {
        setShowForm(false);
        setImLoading({ id: '' });
      });
  };
  return (
    <div className="emmaccen">
      <Modal
        visible={showForm}
        className="emmaccen"
        onOk={handleCreate}
        onCancel={() => setShowForm(!showForm)}
        footer={null}
      >
        <div className="feedbackForm">
          <div className="text-center feedTitle gap">Feedback</div>
          <div className="flex justify-center">
            <div className="contain flex">
              <div
                onClick={() => setData({ ...data, feedbackType: 0 })}
                className={`switch ${!data.feedbackType ? 'blueBg' : ''}`}
              >
                Support
              </div>
              <div
                onClick={() => setData({ ...data, feedbackType: 1 })}
                className={`switch ${data.feedbackType ? 'blueBg' : ''}`}
              >
                Suggestions
              </div>
            </div>
          </div>
          <TextArea
            value={data.message}
            onChange={(e) => setData({ ...data, message: e.target.value })}
            className="gap"
            placeholder="Please leave us a feedback..."
          />
          {!data.feedbackType && (
            <div className="rangeContainer">
              <p>How likely are you to recommend MailDrip to a friend or colleague?</p>
              <div>
                <Slider
                  value={data.rating}
                  onChange={(e) => setData({ ...data, rating: e })}
                  marks={marks}
                  step={10}
                  defaultValue={'80'}
                />
              </div>
              <div className="flex justify-between">
                <span>Not likely</span>
                <span>Extremely likely</span>
              </div>
            </div>
          )}
          <div className="text-center">
            <Button
              onClick={() => handleCreate()}
              loading={imLoading.id === 'sendingFeedback'}
              type="primary"
              size="large"
              className="submit"
            >
              Submit
            </Button>
          </div>
          {/* <div className="flex justify-end">
            <a href="mailto:" className="primaryColor">
              Send us a mail instead?
            </a>
          </div> */}
        </div>
      </Modal>
      <div onClick={() => setShowForm(!showForm)} className={`feedbackToggle ${animate ? 'shake' : ''}`}>
        <img src="/assets/feedbackIcon.png" alt="feedback icon" />
      </div>
    </div>
  );
};
