import axios from 'axios';
import crypto from 'crypto-js';
import Cookies from 'js-cookie';
import { appHistory } from 'App';
import { v4 } from 'uuid';

const API = process.env.REACT_APP_API_URL;
const token = Cookies.get('token');

// TODO: Merge this into the existing axios instance
const instance = axios.create({
  headers: {
    'x-access-token': token,
  },
  baseURL: API,
});

const encryptpayload = (plaintext, nonce) => {
  return crypto.AES.encrypt(JSON.stringify(plaintext), nonce).toString();
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    /** Redirect to login if a 401 status code is returned*/
    if (error?.response?.status === 401) appHistory.replace('/login');
    return Promise.reject(error.response.data);
  }
);
// Paystack
export const createPaygTrx = (payload) => {
  const nonce = v4();
  const cipher = encryptpayload(payload, nonce);
  return instance.post('/payment/paystack/transactions', { cipher, nonce });
};

export const createSubTrx = (payload) => {
  const nonce = v4();
  const cipher = encryptpayload(payload, nonce);
  return instance.post('/payment/paystack/subscriptions', { cipher, nonce });
};

export const cancelSubscription = () => {
  return instance.get('/payment/paystack/subscriptions/cancel');
};

export const enableSubscription = () => {
  return instance.get('/payment/paystack/subscriptions/enable');
};

export const createTopupTrx = (payload) => {
  const nonce = v4();
  const cipher = encryptpayload(payload, nonce);
  return instance.post('/payment/paystack/transactions/topup', { cipher, nonce });
};

export const verifyTrx = (txref, saveCard) => {
  return instance.get(`/payment/paystack/transactions/verify?txref=${txref}&savecard=${saveCard}`);
};

export const authorizeTrx = (payload) => {
  return instance.post('/payment/paystack/transactions/authorize', payload);
};

export const getPaystackCards = () => {
  return instance.get('/payment/paystack/cards');
};

export const deletePaystackCard = (authorization) => {
  return instance.delete(`/payment/paystack/cards/${authorization}`);
};

export const setPaystackCardStatus = (authorization) => {
  return instance.patch(`/payment/paystack/cards/${authorization}`);
};
