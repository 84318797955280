import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Form, Input, Select, Spin, Tooltip } from 'antd';
import { isUserOnFreePlan } from 'utils/generics';
import { containAaZzHyphenSpace } from 'utils/formRules';

// takes in time in seconds - 120
const convertSecondsToNearestDur = (dur) => {
  const newdur = moment.duration(Number(dur), 'seconds');

  let int = 0;
  let unit = 'years';
  int = newdur.asYears();

  const updateState = (t, u) => {
    int = t;
    unit = u;
  };
  if (int < 1) {
    updateState(newdur.asMonths(), 'months');
  }
  if (int < 1) {
    updateState(newdur.asWeeks(), 'weeks');
  }
  if (int < 1) {
    updateState(newdur.asDays(), 'days');
  }
  if (int < 1) {
    updateState(newdur.asHours(), 'hours');
  }
  if (int < 1) {
    updateState(newdur.asMinutes(), 'minutes');
  }

  if (int < 1) {
    throw Error('Invalid campaign interval');
  }

  // Floor or Ceil result
  if (int > int + 0.5) {
    Math.ceil(int);
  } else {
    Math.floor(int);
  }
  return { int, unit };
};

export const Settings = ({
  imLoading,
  handleInterval,
  campaignId,
  handleEmailConfiguration,
  handleVariables,
  handleDeleteVariable,
  campaignVariables,
  data,
}) => {
  const { Option } = Select;
  const { Panel } = Collapse;

  // Read Only
  const [intervals, _] = useState({
    minutes: 60,
    hours: 60 * 60,
    days: 24 * 60 * 60,
    weeks: 7 * 24 * 60 * 60,
    months: 30 * 24 * 60 * 60,
    years: 365 * 24 * 60 * 60,
  });

  const [user, __] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [userIsOnFreePlan] = useState(isUserOnFreePlan(user.user));

  const [emailConfigForm] = Form.useForm();
  const [intervalForm] = Form.useForm();
  const [variablesForm] = Form.useForm();

  useEffect(() => {
    if (data.interval) {
      const interval = convertSecondsToNearestDur(data?.interval);
      intervalForm.setFieldsValue({
        ['number']: interval?.int,
        ['seconds']: intervals[interval?.unit],
      });
    }
    emailConfigForm.setFieldsValue({
      ['emailFrom']: userIsOnFreePlan ? user?.emailSettings.emailFrom : data?.configurations?.emailSettings.from,
      ['emailReplyTo']: userIsOnFreePlan
        ? user?.emailSettings.emailReplyTo
        : data?.configurations?.emailSettings.replyTo,
    });
  }, [data]);
  const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
  `;

  const onChange = (key) => {
    console.log(key);
  };

  const CollapsePanelHeader = ({ title, subTitle, paid }) => (
    <div className="flex flex-col collapseHeader">
      <div className="flex items-center">
        <span>{title}</span>
        {paid && <span className="mx-4 paid_tag">PAID</span>}
      </div>
      <p>{subTitle}</p>
    </div>
  );
  return (
    <div className="settingsTab">
      <Collapse onChange={onChange} accordion expandIconPosition="right">
        <Panel
          header={
            <CollapsePanelHeader
              title="Campaign Interval"
              subTitle="Set the wait time between emails in your campaign"
            />
          }
          key="1"
        >
          <Form
            name="basic"
            onFinish={({ number, seconds }) => handleInterval(number, seconds, campaignId)}
            onFinishFailed={() => null}
            autoComplete="off"
            form={intervalForm}
            size="large"
            className="formOne"
            layout="vertical"
          >
            <div className="flex items-end form_container">
              <div className="flex form_inputs">
                <Form.Item
                  label="Number"
                  name="number"
                  rules={[{ required: true, message: 'Please provide a value' }]}
                  // labelCol={{ span: 2 }}
                  // wrapperCol={{ span: 2 }}
                >
                  <Input
                    onChange={({ target: { value } }) => setInterval((p) => ({ ...p, int: value }))}
                    type="number"
                    min={1}
                  />
                </Form.Item>
                <Form.Item
                  label="Select Interval"
                  name="seconds"
                  rules={[{ required: true, message: 'Please provide a value' }]}
                  // labelCol={{ span: 4 }}
                  // wrapperCol={{ span: 4 }}
                >
                  <Select
                    showSearch
                    placeholder="Select Interval"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    // defaultValue={selectDefValue}
                    // name="select"
                  >
                    <Option value={intervals.minutes}>Minute(s)</Option>
                    <Option value={intervals.hours}>Hour(s)</Option>
                    <Option value={intervals.days}>Day(s)</Option>
                    <Option value={intervals.weeks}>Week(s)</Option>
                    <Option value={intervals.months}>Month(s)</Option>
                    <Option value={intervals.years}>Year(s)</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="form_submit">
                <Button
                  size={'large'}
                  type="primary"
                  htmlType="submit"
                  // icon={<PlusOutlined />}
                  loading={imLoading?.id === 'setIntervalBtn'}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Panel>
        <Panel
          header={
            <CollapsePanelHeader
              title={'Custom “from” and “reply-to”'}
              subTitle="Set a custom persona and reply-to email for your campaign"
              paid
            />
          }
          key="2"
        >
          <Form
            name="basic"
            onFinish={(values) => handleEmailConfiguration(values, campaignId)}
            onFinishFailed={() => null}
            autoComplete="off"
            size="large"
            className="formTwo"
            layout="vertical"
            form={emailConfigForm}
          >
            <div className="flex items-end form_container">
              <div className="flex form_inputs">
                <Form.Item
                  name="emailFrom"
                  label="From Name"
                  rules={[{ required: true, message: 'Please provide a name' }, ...containAaZzHyphenSpace]}
                >
                  <Input placeholder="Default name" type="text" />
                </Form.Item>
                <Form.Item
                  name="emailReplyTo"
                  label="Reply-to"
                  rules={[
                    { required: true, message: 'Please provide a reply-to' },
                    {
                      type: 'email',
                      message: 'Please provide a valid email address',
                    },
                  ]}
                >
                  <Input
                    // onChange={this.onChange}
                    // onBlur={this.onBlur}
                    placeholder="Default email"
                    type="email"
                  />
                </Form.Item>
              </div>
              <div className="form_submit">
                <Button
                  size={'large'}
                  type="primary"
                  htmlType="submit"
                  // icon={<PlusOutlined />}
                  loading={imLoading?.id === 'configuringEmail'}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Panel>
        <Panel
          header={
            <CollapsePanelHeader
              title="Merge Tags"
              subTitle="Create reusable tags for your emails and save time when drafting content"
            />
          }
          key="3"
        >
          <Form
            name="basic"
            onFinish={({ name, value }) => {
              handleVariables(name, value, campaignId);
              variablesForm.resetFields();
            }}
            onFinishFailed={() => null}
            form={variablesForm}
            autoComplete="off"
            layout="vertical"
            size="large"
          >
            <div className="flex items-end form_container">
              <div className="flex form_inputs">
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please provide a name' }]}>
                  <Input placeholder="Input name" type="text" />
                </Form.Item>
                <Form.Item name="value" label="Value" rules={[{ required: true, message: 'Please provide a value' }]}>
                  <Input placeholder="Input value" type="text" />
                </Form.Item>
              </div>
              <div className="form_submit">
                <Button
                  size={'large'}
                  type="primary"
                  htmlType="submit"
                  // icon={<PlusOutlined />}
                  loading={imLoading?.id === 'addVariable'}
                >
                  Create tag
                </Button>
              </div>
            </div>

            {imLoading?.id !== 'loadingVariables' ? (
              <>
                {campaignVariables.map((variable) => (
                  <div className="mt-5 variablesContainer borderShade1" key={variable.name}>
                    <div className="header">
                      <h2 className="font-bold">{variable.name}</h2>
                      <div className="pointer">
                        <Tooltip trigger={['hover']} title={'Delete Variable'} color={'red'} placement="topLeft">
                          <DeleteOutlined
                            style={{ fontSize: '16px', color: '#f45b5b' }}
                            onClick={async () => {
                              await handleDeleteVariable(variable.name);
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <h2>{variable.value}</h2>
                  </div>
                ))}
              </>
            ) : (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />} />
            )}
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Settings;
