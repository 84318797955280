import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'antd';
import Cookie from 'js-cookie';
import { ReactComponent as LeftArrow } from '../asset/Icons/left-arrow-clk.svg';
import { ReactComponent as RightArrow } from '../asset/Icons/right-arrow-clk.svg';

import Modal from './Modal';

// const contentStyle = {
//   height: '260px',
//   color: '#fff',
//   lineHeight: '160px',
//   textAlign: 'center',
//   background: '#364d79',
// };

const whatsNewContentArr = [
  {
    img_banner: '/assets/whats_new-campaign_info.png',
    title: 'Campaign Information',
    info: 'With this new feature, you can now see the performance of your campaign in an overview. You can access analytics data and information about your campaign. You can find the feature in the campaign tab.',
  },
  {
    img_banner: '/assets/whats_new-email_analytics.png',
    title: 'Email Analytics',
    info: 'With email analytics, you can track how your subscribers interact with your emails. The information can help to draw valuable insights to optimize and strategize your email campaigns for better performance.',
  },
  {
    img_banner: '/assets/whats_new-contact_page.png',
    title: 'Contact Page',
    info: 'We simplified the process of adding subscribers to your email campaign.',
  },
  {
    img_banner: '/assets/whats_new-email_filter.png',
    title: 'Email Filter',
    info: 'Email filter cards allow you to see between your active, draft and deleted emails. With the tool, you can delete and restore emails easily.',
  },
  // {
  //   title: 'You’re all set!',
  //   info: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the since the industry`s standard dummy text ever since the ggh 1500s 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially fgggf unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum rrtr passages, and more recently with desktop publishing software like Aldus PageMaker including versions of jhj Lorem Ipsum.',
  // },
];

const WhatsNewModalPop = ({ modalIsActuve, setModalIsActive }) => {
  const [sliderIndxInView, setSliderIndxInView] = useState();

  const history = useHistory();
  const onChange = (currentSlide) => {
    setSliderIndxInView(currentSlide);
  };
  const onChange2 = (a, b) => {
    setSliderIndxInView(b);
  };

  const sliderMtd = useRef();

  const controlFirstViewerPop = () => {
    setModalIsActive(false);
    Cookie.set('firstTimeLog', 'false');
    history.push('/whats-new');
  };

  return (
    <div className="whats-new_modal-contain">
      <Modal isActive={modalIsActuve} width={608} maskClosable={false} className="whats-new_modal-pop">
        <Carousel
          arrows={false}
          dots={false}
          ref={(slick) => (sliderMtd.current = slick)}
          // afterChange={onChange}
          beforeChange={onChange2}
          infinite={false}
        >
          {whatsNewContentArr.map(({ img_banner, title, info }) => {
            return !img_banner ? (
              <React.Fragment key={title}>
                <article className="newUpdates-contain">
                  <div className="newUpdates-descrp">
                    <h3 className="newUpdates-descrp_title">
                      <span>🎊</span>
                      {title}
                    </h3>
                    <p className="newUpdates-descrp_info info_full">{info}</p>
                    <p className="newUpdates-descrp_info">The MailDrip Team</p>
                  </div>
                </article>
              </React.Fragment>
            ) : (
              <React.Fragment key={title}>
                <article className="newUpdates-contain">
                  <div className="newUpdates-img_banner">
                    <img src={img_banner} alt="" />
                  </div>
                  <div className="newUpdates-descrp">
                    <div className="newUpdates-descrp_tag">
                      <p>What's new</p>
                    </div>
                    <h3 className="newUpdates-descrp_title">{title}</h3>
                    <p className="newUpdates-descrp_info">{info}</p>
                  </div>
                </article>
              </React.Fragment>
            );
          })}
        </Carousel>
        <div className="flex justify-end slider-ctr">
          <button onClick={() => sliderMtd.current.prev()}>
            <LeftArrow />
          </button>
          {sliderIndxInView === 3 ? (
            <button className="imready" onClick={controlFirstViewerPop}>
              What’s New
            </button>
          ) : (
            <button onClick={() => sliderMtd.current.next()}>
              <RightArrow />
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default WhatsNewModalPop;
