import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { trunacteString } from '../../../utils/generics';
import { me } from '../../../utils/service';
import moment from 'moment';
const CampaignInformation = ({ data, campaigns }) => {
  const [emailSet, setEmailSet] = useState(JSON.parse(localStorage.getItem('userDetails'))?.emailSettings);

  const getProfile = async () => {
    // setIsLoading(true);
    let data = await me();
    console.log({ data });

    if (data) {
      // setIsLoading(false);
      setEmailSet(data?.emailSettings);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div>
      <div className="campaign-information">
        <div className="flex w-full">
          <div className="reporting_sect">
            <h3>REPORTING</h3>
            <div className="flex grid-3">
              <div className="flex flex-col g-items">
                <p>Recipients</p>
                <h3 className="title">{campaigns?.analytics?.recipients || '0'}</h3>
              </div>
              <div className="flex flex-col g-items">
                <p>Emails</p>
                <h3 className="title">{campaigns?.analytics?.emails || '0'}</h3>
              </div>
              <div className="flex flex-col g-items">
                <p>
                  Conversion Rate <img key="copy-icon" src="/assets/more-info.svg" />
                </p>
                <h3 className="title">{campaigns?.analytics?.conversionRate || '0'}%</h3>
              </div>
            </div>
            <div className="flex grid-3">
              <div className="flex flex-col g-items">
                <p>
                  Open Rate <img key="copy-icon" src="/assets/more-info.svg" />
                </p>
                <h3 className="title">{campaigns?.analytics?.openRate || '0'}%</h3>
              </div>
              <div className="flex flex-col g-items">
                <p>Spam</p>
                <h3 className="title">{campaigns?.analytics?.spams || '0'}</h3>
              </div>
              <div className="flex flex-col g-items">
                <p>Unsubscribe</p>
                <h3 className="title">{campaigns?.analytics?.unsubscribes || '0'}</h3>
              </div>
            </div>
          </div>
          <div className="information_sect">
            <h3>INFORMATION</h3>
            <div className="flex grid-2">
              <div className="flex flex-col g-items">
                <p>Campaign Title</p>
                <h3 className="title" title={campaigns?.name}>
                  {trunacteString(campaigns?.name, 17)}
                </h3>
              </div>
              <div className="flex flex-col g-items">
                <p>Campaign ID</p>
                <div className="flex items-center">
                  <h3 className="title">{trunacteString(campaigns?._id, 14)}</h3>
                  <Typography.Paragraph
                    style={{ margin: '0px', width: '65px' }}
                    copyable={{
                      icon: [
                        <img key="copy-icon" src="/assets/copyIcon2.svg" />,
                        // <img key="copy-icon" src="/assets/copyIcon2.svg" />,
                        // <CheckCircleFilled key="copied-icon" />,
                      ],
                      text: `${campaigns?._id}`,
                      tooltips: ['Copy Campaign ID', 'Campaign ID copied to clipboard'],
                    }}
                  ></Typography.Paragraph>
                </div>
              </div>
            </div>
            <div className="flex grid-2">
              <div className="flex flex-col g-items">
                <p>From</p>
                <h3 className="title" title={emailSet?.emailFrom}>
                  {trunacteString(emailSet?.emailFrom, 17)}
                </h3>
              </div>
              <div className="flex flex-col g-items" title={emailSet?.emailReplyTo}>
                <p>Reply-To</p>
                <h3 className="title">{trunacteString(emailSet?.emailReplyTo, 25)}</h3>
              </div>
            </div>
            <div className="flex grid-2">
              <div className="flex flex-col g-items">
                <p>Date Created</p>
                <h3 className="title">{moment.utc(campaigns?.createdAt).format('MMMM Do YYYY')}</h3>
              </div>
              {/* <div className="flex flex-col g-items">
                <p>Recepients</p>
                <h3 className="title">2032</h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignInformation;
