import { Button, Form, Modal } from 'antd';
import CSVReader from 'components/CSVReader';
import React, { useEffect, useState } from 'react';
import { importBulkRecipient } from 'utils/service';
import { showToastMessage } from 'utils/Toast';

const AddRecipientsToCampaign = ({ imLoading, setImLoading, imVisible, setImVisible, campaignId, setRefresh }) => {
  // const [imLoading, setImLoading] = useState({ id: '' });
  const [csvResult, setCsvResult] = useState([]);
  const [contactForm] = Form.useForm();

  const onSubmitContactDetail = (contact) => {
    setImLoading({ id: 'addingRecipient' });
    importBulkRecipient({ recipients: csvResult }, campaignId)
      .then((response) => {
        console.log(response.data);
        setRefresh(response.data);
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: response.data.data.message,
        });
      })
      .finally(() => {
        setImVisible({ id: '' });
      });
  };
  useEffect(() => {
    contactForm.resetFields();
  }, [imVisible]);

  return (
    <Modal
      // title="Delete Email"
      className="emmaccen"
      visible={imVisible}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
    >
      <div className="addNewContactModal">
        <h2 className="text-center title">Add Recipients</h2>
        <Form
          form={contactForm}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          // initialValues={{
          //   groups: [currentGroup._id],
          // }}
          layout={'vertical'}
        >
          <CSVReader setCSVResult={setCsvResult} />
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={imLoading?.id === 'addingRecipient'}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddRecipientsToCampaign;
