import { ApiOutlined, MailOutlined, SoundOutlined, TagOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';
import { Footer } from '../components/emmaccen/sharedComponents/Footer';
import '../styles/globals.scss';
import '../styles/helpcenter.scss';
const { Search } = Input;

export const HelpCenter = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="emmaccen">
      <div className="helpCenter">
        <div className="landing">
          <div className="container2">
            <div className="jumbotron">
              <div>
                <div className="header">
                  <h1 className="bold primaryColorLight">MailDrip</h1>
                  <h1 className="bold ">Help Center</h1>
                </div>
                <div className="margin-t-b">
                  <Search
                    placeholder="Search for an article..."
                    allowClear
                    enterButton="Search Article"
                    size="large"
                    onSearch={() => setLoading(!loading)}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pad homeShadeBg">
          <div className="container2">
            <div className="articles spacer">
              <div className="card pointer">
                <div className="centered cardIcon">
                  <SoundOutlined />
                </div>
                <div className="textCenter margin-t-b">
                  <h2 className="primaryColorLight bold">Getting Started</h2>
                </div>
                <ul>
                  <li className="paragraph">What is MailDrip?</li>
                  <li className="paragraph">How to setup campaigns</li>
                </ul>
              </div>
              <div className="card pointer">
                <div className="centered cardIcon">
                  <SoundOutlined />
                </div>
                <div className="textCenter margin-t-b">
                  <h2 className="primaryColorLight bold">APIs & Integration</h2>
                </div>
                <ul>
                  <li className="paragraph">What is MailDrip?</li>
                  <li className="paragraph">How to setup campaigns</li>
                </ul>
              </div>
              <div className="card pointer">
                <div className="centered cardIcon">
                  <MailOutlined />
                </div>
                <div className="textCenter margin-t-b">
                  <h2 className="primaryColorLight bold">Getting Started</h2>
                </div>
                <ul>
                  <li className="paragraph">What is MailDrip?</li>
                  <li className="paragraph">How to setup campaigns</li>
                </ul>
              </div>
              <div className="card pointer">
                <div className="centered cardIcon">
                  <TagOutlined />
                </div>
                <div className="textCenter margin-t-b">
                  <h2 className="primaryColorLight bold">Getting Started</h2>
                </div>
                <ul>
                  <li className="paragraph">What is MailDrip?</li>
                  <li className="paragraph">How to setup campaigns</li>
                </ul>
              </div>
              <div className="card pointer">
                <div className="centered cardIcon">
                  <ApiOutlined />
                </div>
                <div className="textCenter margin-t-b">
                  <h2 className="primaryColorLight bold">Getting Started</h2>
                </div>
                <ul>
                  <li className="paragraph">What is MailDrip?</li>
                  <li className="paragraph">How to setup campaigns</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpCenter;
