export const min3char = [
  {
    min: 3,
    message: 'Must be a minimum of 3 characters',
  },
];
export const minDynamicChar = (num) => {
  return [
    {
      min: num,
      message: `Must be a minimum of ${num} characters`,
    },
  ];
};
export const maxDynamicChar = (num) => {
  return [
    {
      max: num,
      message: `Must be a maximum of ${num} characters`,
    },
  ];
};
export const containAzDchar = [
  {
    pattern: /^[A-Za-z- ]+$/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];

export const containAaZzHyphenSpace = [
  {
    pattern: /^[A-Za-z-\x20]+$/,
    message: 'This field only accepts letters, hyphens and spaces',
  },
];

export const containAzDcharDot = [
  {
    pattern: /^[A-Za-z-. ]+$/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];

export const mustHaveLetters = [
  {
    pattern: /([A-Za-z])/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];

export const containAzchar = [
  {
    pattern: /^[A-Za-z]+$/,
    message: 'Must contain letters between (Aa-Zz)',
  },
];
export const containSpeshChar = [
  {
    pattern: new RegExp(/([!%@#$&*])/),
    message: 'Must contain special characters (!%@#$&*)',
  },
];
export const containNumChar = [
  {
    pattern: /^[0-9]+$/,
    whitespace: true,
    message: 'Must contain numbers between (0-9)',
  },
];
export const containLowAzChar = [
  {
    pattern: new RegExp(/([a-z])/),
    message: 'Must contain lowecase characters between (a-z)',
  },
];

export const mustBeValidUrl = [
  {
    type: 'url',
    message: 'This field must be a valid url',
  },
  // {
  //   pattern: new RegExp(/([www])/),
  //   message: 'Must contain special characters (!%@#$&*)',
  // },
];
