import Paragraph from 'antd/lib/typography/Paragraph';
import { CheckCircleFilled, CopyOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { getCredentials } from 'utils/service';
import { showToastMessage } from 'utils/Toast';

export default function Apikeys() {
  const [credencials, setCredencials] = useState({
    apiKey: '',
    secretKey: '',
  });

  const [apiVisible, setApiVisible] = useState({
    apiKey: false,
    secretKey: false,
  });

  useEffect(() => {
    getCredentials()
      .then(({ data: { data } }) => {
        setCredencials(data);
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to fetch API keys',
        });
      });
  }, []);
  return (
    <div className="border apiContainer mt-5">
      <p className="py-5 ml-5">Add users to your campaign from your app and other goodies</p>
      <div className="flex apicontentWrapper">
        <div>
          <h3>API Key</h3>
          <div className="border apiKeys centered mr-5">
            <div className="flex">
              <Paragraph
                copyable={{
                  icon: [<CopyOutlined key="copy-icon" />, <CheckCircleFilled key="copied-icon" />],
                  text: `${credencials.apiKey}`,
                  tooltips: ['click here to copy', 'Key copy to clipboard'],
                }}
              >
                {apiVisible.apiKey ? credencials.apiKey : '***************************************'}
              </Paragraph>
              <div className="centered pl-6 pointer mr-5 toggleIcon">
                {!apiVisible.apiKey && (
                  <EyeInvisibleOutlined
                    onClick={() =>
                      setApiVisible({
                        ...apiVisible,
                        apiKey: !apiVisible.apiKey,
                      })
                    }
                  />
                )}
                {apiVisible.apiKey && (
                  <EyeOutlined
                    onClick={() =>
                      setApiVisible({
                        ...apiVisible,
                        apiKey: !apiVisible.apiKey,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3>API Secret</h3>
          <div className="border apiKeys centered">
            <div className="flex">
              <Paragraph
                copyable={{
                  icon: [<CopyOutlined key="copy-icon" />, <CheckCircleFilled key="copied-icon" />],
                  text: `${credencials.secretKey}`,
                  tooltips: ['click here to copy', 'Key copy to clipboard'],
                }}
              >
                {apiVisible.secretKey ? credencials.secretKey : '***************************************'}
              </Paragraph>
            </div>
            <div className="centered pl-6 pointer mr-5 toggleIcon">
              {!apiVisible.secretKey && (
                <EyeInvisibleOutlined
                  onClick={() =>
                    setApiVisible({
                      ...apiVisible,
                      secretKey: !apiVisible.secretKey,
                    })
                  }
                />
              )}
              {apiVisible.secretKey && (
                <EyeOutlined
                  onClick={() =>
                    setApiVisible({
                      ...apiVisible,
                      secretKey: !apiVisible.secretKey,
                    })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
