import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import isValidForm from '../../components/emmaccen/shared-functions/inputValidator';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
import { EditorActionsTab } from '../../components/emmaccen/sharedComponents/EditorActionsTab';
import '../../styles/editorStyles.scss';
import {
  createCampaignMail,
  editEmails,
  getSingleCampaignInformation,
  getSingleEmailById,
  uploadFile,
  uploadMarkdownImage,
  saveDraftEmail,
} from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import { editorConfig } from './editorConfig';

export const DocumentEditor = ({
  match: {
    params: { campaignId },
  },
  location,
}) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const mailId = location?.state?.mailId;
  const [mail, setMail] = useState({
    subject: '',
    body: '',
    number: null,
    seconds: null,
    typeOfMail: 'text-editor',
    emailInterval: '',
    attachments: [],
    order: 0,
  });
  const { Option } = Select;
  const [breadCrumbs, setBreadCrumbs] = useState([
    // {
    //   name: 'Overview',
    //   link: `/campaign/${campaignId}/emails`,
    //   index: 0,
    // },
  ]);
  const history = useHistory();
  useEffect(() => {
    mailId &&
      (() => {
        getSingleEmailById(campaignId, mailId)
          .then(({ data: { data } }) => {
            setFiles(
              data?.email?.attachments.map((data, index) => {
                return data;
              })
            );
            setMail({ ...mail, ...data.email });
            const crumb = {
              name: data?.email.subject,
              link: `#`,
              index: 2,
            };
            getSingleCampaignInformation(campaignId).then(({ data: { data } }) => {
              setBreadCrumbs([
                ...breadCrumbs,
                {
                  name: data?.name,
                  link: `/campaign/${campaignId}/emails`,
                  index: 1,
                },
                crumb,
              ]);
            });
          })
          .catch((error) => {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: 'Something went wrong, please try again later.',
            });
          });
      })();
  }, []);

  useEffect(() => {
    isUploading &&
      files.forEach((file) => {
        file.status !== 'UPLOADED' &&
          uploadFile(file.file, file.id)
            .then(
              ({
                data: {
                  data: { id, location, filename, status },
                },
              }) => {
                console.log(id, location, filename, status);
                updateFileState(id, status, location, filename);
              }
            )
            .catch((err) => {
              updateFileState(file.id, 'ERROR', file.src, file.filename);
            })
            .finally(() => {
              setIsUploading(false);
            });
      });
  }, [isUploading]);

  const updateFileState = (id, status, location, filename) => {
    setFiles((previousFiles) => {
      return previousFiles.map((file) => (file.id === id ? { ...file, status, src: location, filename } : file));
    });
  };

  const handleEmailCreation = (interval) => {
    if (isValidForm([mail.body, mail.subject])) {
      setImLoading({ id: 'emailAction' });
      const attachments = files.filter((file) => file.status !== 'ERROR').map((file) => file);
      setMail({ ...mail, attachments, emailInterval: interval });
      mailId
        ? editEmails(campaignId, mailId, {
            ...mail,
            attachments,
            emailInterval: interval,
          })
            .then((data) => {
              showToastMessage({
                type: 'success',
                title: 'Email',
                description: 'Your mail has been updated successfully',
              });
              history.replace(`/campaign/${campaignId}/emails`);
            })
            .catch((e) => {
              showToastMessage({
                type: 'error',
                title: 'Error',
                description: 'Something went wrong, please try again later',
              });
            })
            .finally(() => {
              setImLoading({ id: '' });
            })
        : createCampaignMail({ ...mail, attachments, emailInterval: interval }, campaignId)
            .then((data) => {
              console.log('this request has been fufiled', data);
              history.replace(`/campaign/${campaignId}/emails`);
            })
            .catch((e) => {
              showToastMessage({
                type: 'error',
                title: 'Error',
                description: 'Something went wrong',
              });
            })
            .finally(() => {
              setImLoading({ id: '' });
            });
    } else {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please provide both email-body and subject',
      });
    }
  };
  const saveDraft = () => {
    if (isValidForm([mail.body, mail.subject])) {
      setImLoading({ id: 'draftingEmail' });
      const attachments = files.filter((file) => file.status !== 'ERROR').map((file) => file);
      setMail({ ...mail, attachments });
      saveDraftEmail({ campaignId, mail, setImLoading }).then((mail) => {
        console.log(mail);
        showToastMessage({
          type: 'success',
          title: 'Draft Email',
          description: 'Your mail has been saved as draft',
        });
        history.replace(`/campaign/${campaignId}/emails`);
      });
    } else {
      setImLoading({ id: '' });
      showToastMessage({
        type: 'warning',
        title: 'Required Fields',
        description: 'Please provide both email-body and subject',
      });
    }
  };
  class MyUploadAdapter {
    constructor(loader) {
      // The file loader instance to use during the upload.
      this.loader = loader;
    }
    // Starts the upload process.
    upload() {
      const file = this.loader.file;
      return file.then((blob) => {
        const uploadFile = new FormData();
        uploadFile.append('file', blob);
        return uploadMarkdownImage(uploadFile).then(({ data }) => {
          return { default: data?.data?.resource };
        });
      });
    }
    abort() {
      console.log('upload aborted');
    }
  }

  return (
    <div className="emmaccen">
      <div className="editorContainer">
        <div className="flex flex-space-btw align-items-center mb-5 editorHeaderTab">
          <BreadCrumb crumbs={breadCrumbs} />
          <EditorActionsTab
            imLoading={imLoading}
            setImLoading={setImLoading}
            campaignId={campaignId}
            setMail={setMail}
            mail={mail}
            handleEmailCreation={handleEmailCreation}
            saveDraft={saveDraft}
            filesProps={[files, setFiles, setIsUploading]}
          />
        </div>
        <div className="editorInputArea">
          <Input
            onChange={(subject) => setMail({ ...mail, subject: subject.target.value })}
            placeholder="Subject"
            size="large"
            value={mail?.subject}
            className="borderShade1"
          />
          <div className="ckeditorStyles">
            <CKEditor
              onReady={(editor) => {
                console.log('Editor is ready to use!', editor);

                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                  return new MyUploadAdapter(loader);
                };
              }}
              onError={(error, { willEditorRestart }) => {
                // If the editor is restarted, the toolbar element will be created once again.
                // The `onReady` callback will be called again and the new toolbar will be added.
                // This is why you need to remove the older toolbar.
                if (willEditorRestart) {
                  this.editor.ui.view.toolbar.element.remove();
                }
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMail((prev) => {
                  return { ...prev, body: data };
                });
              }}
              editor={DecoupledEditor}
              data={mail?.body}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
              config={editorConfig}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
