import { Button, Checkbox, Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';

import { passwordRules } from 'components/emmaccen/shared-data/passwordRule';
import 'styles/loginPage.scss';
import { containAzchar, containAzDchar, mustHaveLetters, min3char } from 'utils/formRules';
import { signUp } from 'utils/services/authService';
import { showToastMessage } from 'utils/Toast';
import GoogleAuthButton from './GoogleAuthButton';
import { CarouselSlider } from './Login';
import { me, setAuthToken } from 'utils/service';

export const SignUpPage = () => {
  const [imLoading, setImLoading] = useState({ formValid: false });
  const [loading, setLoading] = useState(false);

  const [formFields, setFormFields] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const checkField = formFields.filter((field) => field.value !== undefined && field.errors.length === 0);
    if (checkField.length === 6) {
      setImLoading({ ...imLoading, formValid: true });
    } else {
      setImLoading({ ...imLoading, formValid: false });
    }
  }, [formFields]);

  const createAccount = (values) => {
    setLoading(true);
    signUp(values)
      .then((res) => {
        setLoading(false);
        console.log({ res });

        const { verified, token } = res.data.data;
        if (!verified) {
          history.replace('/verify', {
            email: values.email,
            resendVerificationMail: false,
          });
        } else {
          //  Log user in successfully
          Cookie.set('token', token, { expires: 1 });
          setAuthToken(token);
          me()
            .then((result) => {
              history.replace('/campaigns');
            })
            .catch((err) => {
              showToastMessage({
                type: 'error',
                title: 'Error',
                description: 'Unable to get user details',
              });
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          const errors = err.response.data.fieldsError;
          console.error(errors);
          Object.entries(errors).forEach((field) =>
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: field[1].message,
            })
          );
        } else
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'Unable to process request',
          });
      });
  };

  const onFieldsChange = (changedFields, allField) => {
    setFormFields(allField);
  };
  return (
    <div className="emmaccen">
      <div className="loginPageContainer signupContainer">
        <div className="loginSections flex">
          <CarouselSlider />
          <div className="w-50 formArea">
            <div className="centered formContent">
              <div className="container2">
                <div className="flex-jst-right mb-5">
                  <Link to={{ pathname: 'https://maildrip.io/' }} target="_blank" className="flex-jst-right mb-5">
                    <img src="/assets/smallLogo.svg" alt="company logo"></img>
                  </Link>
                </div>
                <div className="margin-t-b">
                  <h1 className="bold title mb-2">Sign Up</h1>
                  <p className="paragraph">We need some information to get you access to maildrip</p>
                </div>
                <div className="centered margin-t-b">
                  <GoogleAuthButton>Sign up with Google</GoogleAuthButton>
                </div>
                <div className="margin-t-b">
                  <Divider plain>Or</Divider>
                </div>
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={createAccount}
                  onFieldsChange={onFieldsChange}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item
                    // label="Username"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name!',
                      },
                      ...min3char,
                      ...mustHaveLetters,
                      ...containAzDchar,
                    ]}
                  >
                    <Input placeholder="What should we call you?" />
                  </Form.Item>

                  <Form.Item
                    // label="email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                        type: 'email',
                      },
                    ]}
                  >
                    <Input placeholder="What is your email?" />
                  </Form.Item>
                  <div className="grid2">
                    <Form.Item
                      // label="email"
                      name="occupation"
                      rules={[
                        {
                          required: true,
                          message: 'Please, tell us what you do',
                        },
                        ...min3char,
                        { ...containAzchar, whitespace: true },
                      ]}
                    >
                      <Input placeholder="What do you do?" />
                    </Form.Item>
                    <Form.Item
                      // label="email"
                      name="companyName"
                      rules={[
                        {
                          // required: true,
                          message: 'Help us know you better',
                          // type: "text",
                        },
                        ...min3char,
                        { ...containAzchar, whitespace: true },
                      ]}
                    >
                      <Input placeholder="Where do you work?" />
                    </Form.Item>
                  </div>

                  <Form.Item
                    // label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Create a password',
                      },
                      ...passwordRules,
                    ]}
                  >
                    <Input.Password placeholder="Create a unique password" />
                  </Form.Item>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: async (_, checked) => {
                          if (!checked) {
                            return Promise.reject(new Error('Please check the box'));
                          }
                        },
                      },
                    ]}
                  >
                    <Checkbox>
                      <div className="flex tc_pp">
                        <div>
                          <span>By creating an account you agree to the </span>
                          <Link to="/" className="primaryColorLight ">
                            Terms of use
                          </Link>
                          <span> and our </span>
                          <Link to="/" className="primaryColorLight">
                            Privacy policy
                          </Link>
                        </div>
                      </div>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      // loading={imLoading.id === 'creatingAccount'}
                      loading={loading}
                      disabled={!imLoading?.formValid}
                    >
                      Create Account
                    </Button>
                  </Form.Item>
                </Form>
                {/* <Link to="/"> */}
                <p className="text-center">
                  Already have an account?
                  <Link to="/login" className="primaryColorLight ml-2">
                    Login
                  </Link>
                </p>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
