import React, { useContext } from 'react';
import { ReactComponent as Visa } from 'asset/Icons/visa.svg';
import { ReactComponent as MasterCard } from 'asset/Icons/mastercard.svg';
import { ReactComponent as CheckCircleFilled } from 'asset/Icons/checked.svg';

import './style.scss';
import { paymentContext } from 'contexts/payment';
import { SET_CARD, SET_CARDS, SET_MAKING_TRANSACTION } from 'contexts/payment/reducer/types';
import { deletePaystackCard, setPaystackCardStatus } from 'utils/services/paystack';
import { showToastMessage } from 'utils/Toast';

export default function Card({ card }) {
  const { state, dispatch } = useContext(paymentContext);
  const isCurrentCard = card._id == state.card._id;

  const CardLogo = () => {
    if (card.brand.trim() === 'visa') return <Visa />;
    else if (card.brand.trim() === 'mastercard') return <MasterCard />;
  };

  return (
    <div
      className={`trx_card ${isCurrentCard && 'trx_card__selected'}`}
      onClick={() => dispatch({ type: SET_CARD, payload: card })}
    >
      <div className="card_logo">
        <CardLogo />
      </div>

      <div className="card_data">
        <div className="">
          <p className="card_data__number">
            {card.brand} ending in {card.last4}
          </p>
          <p className="card_data__expiry">
            Expiry {card.expiryMonth}/{card.expiryYear.substring(2)}
          </p>
          <div className="card_status">
            <button
              className="card_status__button card_status__edit"
              disabled={card.isDefaultCard || state.making_transaction}
              onClick={async () => {
                try {
                  dispatch({ type: SET_MAKING_TRANSACTION, payload: true });
                  const { data: res } = await setPaystackCardStatus(card.authorization);

                  showToastMessage({
                    type: 'success',
                    title: 'Request Successful',
                    description: 'Card set as default successfully',
                  });
                  dispatch({ type: SET_CARDS, payload: res.data });
                  dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
                } catch (error) {
                  console.log({ error });
                  showToastMessage({
                    type: 'error',
                    title: 'Request Failed',
                    description: error.message,
                  });
                  dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
                }
              }}
            >
              {card.isDefaultCard ? 'Default' : 'Set as Default'}
            </button>
            <button
              className="card_status__button card_status__delete"
              disabled={state.making_transaction}
              onClick={async () => {
                try {
                  dispatch({ type: SET_MAKING_TRANSACTION, payload: true });
                  const { data: res } = await deletePaystackCard(card.authorization);
                  console.log({ res });

                  showToastMessage({
                    type: 'success',
                    title: 'Request Successful',
                    description: 'Card Deleted Successfully',
                  });
                  dispatch({ type: SET_CARDS, payload: res.data });
                  dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
                } catch (error) {
                  console.log({ error });
                  showToastMessage({
                    type: 'error',
                    title: 'Request Failed',
                    description: error.message,
                  });
                  dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
                }
              }}
            >
              Delete
            </button>
          </div>
        </div>
        <CheckCircleFilled className={`card_checked_icon ${isCurrentCard && 'card_checked_icon__visible'}`} />
      </div>
    </div>
  );
}
