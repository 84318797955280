import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import axios from 'axios';
import Cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
// import { EditorActionsTab } from '../../components/emmaccen/sharedComponents/EditorActionsTab';
// import "../../styles/campaigne.scss";
import './editor.scss';
import { showToastMessage } from '../../utils/Toast';
import Modal from '../../components/Modal';
import { EmailPreview } from '../../components/emmaccen/campaignEmails/EmailPreview';
const ConfirmMailTemplate = () => {
  const [appUser, setUserDetails] = useState('');
  const [newPreviewContent, setNewPreviewContent] = useState({
    content: {
      gjshtml: '',
      gjscss: '',
      gjsassets: '',
      gjsstyles: '',
      gjscomponents: '',
    },
  });
  const [mail, setMail] = useState({
    subject: '',
    number: null,
    seconds: null,
    typeOfMail: 'maildrip-editor',
    emailInterval: '',
    attachments: [],
    order: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadEditor, setLoadEditor] = useState(null);
  const [imLoading, setImLoading] = useState({ id: '' });
  const [modalActive, setModalActive] = useState(false);

  const token = Cookie.get('token');

  const [form] = Form.useForm();
  // const layout = {
  //   labelCol: {
  //     span: 8,
  //   },
  //   wrapperCol: {
  //     span: 16,
  //   },
  // };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const history = useHistory();
  const { campaignId, campaignMailId } = useParams();

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Overview',
      link: `/`,
      index: 0,
    },
    {
      name: 'Campaign Name',
      link: `/campaign/${campaignId}/emails`,
      index: 1,
    },
    {
      name: 'Confirm Mail',
      link: `#`,
      index: 2,
    },
  ]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('userDetails'))) {
      const { user } = JSON.parse(localStorage.getItem('userDetails'));
      setUserDetails(user);
    }
  }, []);

  useEffect(() => {
    (async function () {
      const response = await axios.get(`/campaigns/${campaignId}`, {
        'x-access-token': token,
      });
      const campaign = response.data.data;
      const pages = campaign.emails;

      const [fetchedMail] = pages.filter((f) => {
        if (f._id === campaignMailId) {
          return f;
        }
        return false;
      });

      setMail({ ...mail, ...fetchedMail, id: fetchedMail._id });
      setNewPreviewContent((prevData) => {
        return {
          ...prevData,
          content: {
            gjshtml: fetchedMail?.content['gjs-html'],
            gjscss: fetchedMail?.content['gjs-css'],
            gjsassets: fetchedMail?.content['gjs-assets'],
            gjsstyles: fetchedMail?.content['gjs-styles'],
            gjscomponents: fetchedMail?.content['gjs-components'],
          },
        };
      });
      let updatedList = breadCrumbs.map((data) => {
        if (data.index === 1) {
          return { ...data, name: campaign.name };
        }
        if (data.index === 2) {
          return { ...data, name: fetchedMail.subject };
        }
        return data;
      });
      setBreadCrumbs(updatedList);
      return;
    })();
  }, []);

  const openNotification = (message, description) => {
    notification.info({
      message,
      description,
    });
  };
  async function onFinish(values) {
    setIsLoading(true);
    // return;
    await axios
      .post('http://localhost:5151/pages/', {
        subject: values.subject,
        typeOfMail: 'maildrip-editor',
      })
      .then((response) => {
        // setNewMailDataFetched(prevData => {
        //   return {...prevData,
        //     id: response.data._id,
        //     subject: response.data.subject,
        //     typeOfMail:response.data.typeOfMail,
        //     date: response.data.createdAt,
        //   }
        // })
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    setTimeout(() => {
      setLoadEditor(true);
    }, 2000);
  }
  const handleConfirmTemplate = async () => {
    if (mail.subject === '') {
      openNotification('Error', 'Kindly fill the Subject of your mail');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.patch(`/campaigns/${campaignId}/${campaignMailId}`, { subject: mail.subject });

      response.data.success &&
        showToastMessage({
          type: 'success',
          title: 'Saved',
          description: 'Mail saved successfully',
        });
    } catch (error) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to process your request',
      });
    }

    setTimeout(() => {
      // history.replace(`/campaign/${campaignId}/emails`);

      setModalActive(true);
      // setIsLoading(true);
    }, 1000);
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleSubjectInput = (e) => {
    setMail({ ...mail, subject: e.target.value });
    let updatedList = breadCrumbs.map((data) => {
      if (data.index === 2) {
        return { ...data, name: e.target.value };
      }
      return data;
    });
    setBreadCrumbs(updatedList);
  };

  const declineSaveTemplate = () => {
    setImLoading({ id: 'decline' });
    setTimeout(() => {
      history.replace(`/campaign/${campaignId}/emails`);
      // setIsLoading(true);
      setModalActive(false);
      setImLoading({ id: '' });
    }, 2000);
  };
  const confirmSaveTemplate = async () => {
    setImLoading({ id: 'accept' });
    await axios.post('/templates', { emailId: campaignMailId, isDefaultTemplate: false });
    setTimeout(() => {
      history.replace(`/campaign/${campaignId}/emails`);
      // setIsLoading(true);
      setModalActive(false);
      setImLoading({ id: '' });
    }, 2000);
  };

  return (
    <>
      <div className="headerTab">
        <BreadCrumb
          crumbs={breadCrumbs}
          // rightPan={
          //   <TestMailComponent
          //     imLoading={imLoading}
          //     setImLoading={setImLoading}
          //     campaignId={campaignId}
          //     setMail={setMail}
          //     mail={mail}
          //   />
          // }
        />
      </div>
      <div className="boomshakar">
        <div className="templateContaner p-4 sm:p-20 sm:pt-12 pt-12 relative mt-5 h-full w-full">
          <div className="grid2">
            <div className="mailList px-8 py-10">
              <h3 className="header-title bold">Campaign details</h3>
              <div className="my-5" style={{ maxWidth: '450px', width: '100%' }}>
                <h5 className="my-2">Subject</h5>
                <Input
                  placeholder="Email Subject"
                  value={mail.subject}
                  onChange={handleSubjectInput}
                  style={{ height: '49px' }}
                  required={true}
                />
                <div style={{ textAlign: 'right' }} className="my-8">
                  <Button
                    onClick={() => history.replace(`/campaign/${campaignId}/${campaignMailId}/${mail.typeOfMail}`)}
                    className="mr-3 border"
                    size="large"
                    style={{
                      background: 'transparent',
                      color: '#717171',
                      border: '1px solid #E3E3E3',
                      borderRadius: '4px',
                      // padding: '8px 26px',
                    }}
                  >
                    Edit Design
                  </Button>

                  <Button
                    loading={isLoading}
                    size="large"
                    onClick={() => {
                      handleConfirmTemplate();
                    }}
                    style={{
                      background: '#273df2',
                      color: '#fff',
                      borderRadius: '4px',
                      border: '1px solid #273df2',
                      // padding: '8px 26px',
                    }}
                  >
                    Save & Continue
                  </Button>
                </div>
              </div>
            </div>
            <EmailPreview preview={mail} />
          </div>
        </div>
        <Modal
          isActive={modalActive}
          // onCancel={closeModalState}
          title="?"
          maskClosable={false}
        >
          <div>
            <h3>Would you like to save this template to My-Templates ?</h3>
          </div>
          <div className="p-6">
            <div className="flex justify-between">
              <Button
                onClick={declineSaveTemplate}
                className="mr-3 border"
                loading={imLoading.id === 'decline'}
                style={{
                  background: 'transparent',
                  color: '#000',
                  border: '1px solid #E3E3E3',
                  borderRadius: '4px',
                  // padding: '8px 26px',
                }}
              >
                No
              </Button>
              <Button
                type="primary"
                // htmlType="submit"
                loading={imLoading.id === 'accept'}
                onClick={confirmSaveTemplate}
                style={{
                  background: '#273df2',
                  color: '#fff',
                  borderRadius: '4px',
                  border: '1px solid #273df2',
                  // padding: '8px 26px',
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ConfirmMailTemplate;
