import { CheckOutlined, MailOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';
import { FacebookShareButton } from 'react-share';
const { Search } = Input;

export const CutDownExpenses = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="spacer cutDownExpense">
      <div className="container2">
        <div className="grid2">
          <div className="header2">
            <div className="margin-t-b">
              <h1>
                <span className="relative">
                  Cut
                  <img src="/assets/cutIcon.svg" alt="icon" className="overlay cutIcon"></img>
                </span>{' '}
                down expenses and Automate with{' '}
                <span className="relative">
                  Maildrip <img src="/assets/underline.svg" alt="icon" className="overlay underline"></img>
                </span>
                <span className="gif primaryColorLight">
                  <MailOutlined />
                  <FacebookShareButton url="https://www.facebook.com" />
                </span>
              </h1>
            </div>
            <p className="margin-t-b">
              MailDrip helps you send timely automated emails that connect with your audience
            </p>
            <div className="margin-t-b">
              <Search
                placeholder="Enter your email address"
                allowClear
                enterButton="Start Free"
                size="large"
                onSearch={() => setLoading(!loading)}
                loading={loading}
              />
            </div>
            <div className="flex-space-btw planList checkList">
              <div className="flex">
                <div className="centered">
                  <CheckOutlined />
                </div>
                <p>100 - 1000 free emails</p>
              </div>
              <div className="flex">
                <div className="centered">
                  <CheckOutlined />
                </div>
                <p>Zero coding</p>
              </div>
              <div className="flex">
                <div className="centered">
                  <CheckOutlined />
                </div>
                <p>Easy setup</p>
              </div>
            </div>
          </div>
          <div className="centered">
            <div
              className="sideImg backgroundFix"
              style={{
                backgroundImage: `url(${'/assets/cutdownexpenses.svg'})`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
