import { DeleteOutlined, EditOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as EmptyStateImage } from '../../../asset/images/emptyTableIcon.svg';
import { restoreMailAction } from '../../../utils/service';
import { EmailPreview } from './EmailPreview';

import { BarChartOutlined } from '@ant-design/icons';
import { getEmailAnalytics } from '../../../utils/service';
import LoadingScreen from '../../LoadingScreen';

export const Emails = ({ data, setData, setModalVisible, setIsDeleting, campaignId, isDeleting, setRefresh }) => {
  const history = useHistory();
  const [activeMail, setActiveMails] = useState({ id: 'mail' });
  const [preview, setPreview] = useState({});
  const [isFilterEmpty, setIsFilterEmpty] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const identifier = ['mail', 'draft', 'deleted'];
  const [mutableData, setMutableData] = useState({ emails: [], mail: [], draft: [], deleted: [] });
  const hotFetch = false;
  const [viewAnalytics, setViewAnalytics] = useState({});
  const [analyticsModal, setAnalyticsModal] = useState(false);

  const setNewPreviewContent = (mailId) => {
    const newPreview = data.emails.filter(({ _id: id }) => id === mailId);
    setPreview(newPreview[0]);
  };

  const setupMailData = () => {
    const mailData = { mail: [], draft: [], deleted: [] };
    data.emails.forEach((email) => identifier[email.status] !== -1 && mailData[identifier[email.status]].push(email));
    return mailData;
  };

  // useEffect(() => {
  // Figured this method is inefficient
  // if (data && activeMail.id !== '') {
  //   const filter =
  //     activeMail.id === 'mail'
  //       ? () => {
  //           setImLoading({ id: 'mail' });
  //           getActiveEmails({ campaignId, setImLoading }).then(
  //             ({
  //               data: {
  //                 data: { activeEmails, length },
  //               },
  //             }) => {
  //               setIsFilterEmpty(!(length > 0));
  //               length > 0 &&
  //                 setData({
  //                   ...data,
  //                   emails: activeEmails,
  //                 });
  //             }
  //           );
  //         }
  //       : activeMail.id === 'deleted'
  //       ? () => {
  //           setImLoading({ id: 'deleted' });
  //           getDeletedEmails({ campaignId, setImLoading }).then(
  //             ({
  //               data: {
  //                 data: { deletedEmails, length },
  //               },
  //             }) => {
  //               setIsFilterEmpty(!(length > 0));
  //               length > 0 &&
  //                 setData({
  //                   ...data,
  //                   emails: deletedEmails,
  //                 });
  //             }
  //           );
  //         }
  //       : () => {
  //           setImLoading({ id: 'draft' });
  //           getDraftEmails({ campaignId, setImLoading }).then(
  //             ({
  //               data: {
  //                 data: { draftEmails, length },
  //               },
  //             }) => {
  //               setIsFilterEmpty(!(length > 0));
  //               length > 0 &&
  //                 setData({
  //                   ...data,
  //                   emails: draftEmails,
  //                 });
  //             }
  //           );
  //         };
  // hotFetch
  //   ? filter()
  //   : setData({
  //       ...data,
  //       [activeMail.id]: mutableData[activeMail.id],
  //     });
  // }
  // }, [activeMail]);

  useEffect(() => {
    setIsFilterEmpty((mutableData[activeMail.id] && !mutableData[activeMail.id].length) || !data.emails.length);
    setPreview(
      (mutableData[activeMail.id] && !!mutableData[activeMail.id].length && mutableData[activeMail.id][0]) ||
        (!!data.emails.length && data.emails[0])
    );
  }, [activeMail, mutableData]);

  useEffect(() => {
    setMutableData({ ...data, ...setupMailData() });
  }, [data]);

  const restoreMail = (mailId, restoreType) => {
    setImLoading({ id: mailId });
    restoreMailAction(restoreType, { campaignId, mailId, setImLoading }).then(() => {
      setRefresh(Math.random());
    });
  };

  const handleAnalyticsView = (emailId) => {
    setImLoading({ id: 'analytics' });
    setAnalyticsModal(true);
    getEmailAnalytics({ emailId, setImLoading }).then((response) => {
      if (response) return setViewAnalytics(response?.data);
    });
  };

  const Analytics = () => {
    return (
      <Modal
        className="emmaccen"
        visible={analyticsModal}
        // onOk={() => handleDeleteEmail(isDeleting.id)}
        onCancel={() => {
          // setIsDeleting({ id: '' });
          setViewAnalytics({});
          setAnalyticsModal(false);
        }}
        closable={false}
        footer={null}
      >
        {imLoading.id === 'analytics' ? (
          <LoadingScreen loaderTitle="Loading" />
        ) : (
          <div className="mailAnalyticsContainer">
            <h1 className="text-center titleHead">Reporting</h1>
            <div className="analytics">
              <div className="grid-3 content">
                <div className="text-center item">
                  <h3 className="title">{viewAnalytics?.uniqueOpens || 0}</h3>
                  <p>Unique Opens</p>
                </div>
                <div className="text-center item">
                  <h3 className="title">{viewAnalytics?.uniqueClicks || 0}</h3>
                  <p>Unique Clicks</p>
                </div>
                <div className="text-center item">
                  <h3 className="title">{viewAnalytics?.deliveryRate || 0}%</h3>
                  <p>Delivery Rate</p>
                </div>
                <div className="text-center item">
                  <h3 className="title">{viewAnalytics?.bounces || 0}</h3>
                  <p>Bounced</p>
                </div>
                <div className="text-center item">
                  <h3 className="title">{viewAnalytics?.unsubscribes || 0}</h3>
                  <p>Unsubscribed</p>
                </div>
                <div className="text-center item">
                  <h3 className="title">{viewAnalytics?.spams || 0}</h3>
                  <p>Spam</p>
                </div>
              </div>
              <div className="flex grid-2">
                <div className="flex flex-col ">
                  <div className="flex items-center">
                    <span className="warningColorBg "></span>
                    <p>Total Opens</p>
                    <h3 className="title">{viewAnalytics?.opens || 0}</h3>
                  </div>
                  <div className="flex items-center">
                    <span className="skyColorBg "></span>
                    <p>Total Clicks</p>
                    <h3 className="title">{viewAnalytics?.clicks || 0}</h3>
                  </div>
                  <div className="flex items-center">
                    <span className="successColorLightBg "></span>
                    <p>Deliveries</p>
                    <h3 className="title">{viewAnalytics?.delivers || 0}</h3>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div className="flex items-center">
                    <span className="dangerColorBg "></span>
                    <p>Click-through Rate</p>
                    <h3 className="title">{viewAnalytics?.ctr || 0}%</h3>
                  </div>
                  <div className="flex items-center">
                    <span className="successColorLightBg "></span>
                    <p>Click-to-open Rate</p>
                    <h3 className="title">{viewAnalytics?.ctor || 0}%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  return (
    <div>
      <div className="templateContaner margin-t-b">
        <Analytics />
        <div className="grid2">
          <div className="mailList">
            <div className="grid-3 mailFilterContainer">
              <div
                onClick={() => setActiveMails({ id: 'mail' })}
                className={`filterCard mail ${activeMail.id === 'mail' ? 'active' : ''}`}
              >
                <div className="flex-space-btw align-items-center">
                  <img
                    className="icon"
                    src={`${
                      activeMail.id === 'mail' ? '/assets/mailFilterIconActive.svg' : '/assets/mailFilterIcon.svg'
                    }`}
                    alt="mail icon"
                  ></img>
                  {imLoading.id === 'mail' && <LoadingOutlined />}
                </div>
                <div className="flex align-items-center value">
                  <p>Active Mails</p> <span>{mutableData.mail.length}</span>
                </div>
                <p className="desc">Your current active mails</p>
              </div>
              <div
                onClick={() => setActiveMails({ id: 'draft' })}
                className={`filterCard draft ${activeMail.id === 'draft' ? 'active' : ''}`}
              >
                <div className="flex-space-btw align-items-center">
                  <img
                    className="icon"
                    src={`${
                      activeMail.id === 'draft' ? '/assets/draftFilterActiveIcon.svg' : '/assets/draftFilterIcon.svg'
                    }`}
                    alt="mail icon"
                  ></img>
                  {imLoading.id === 'draft' && <LoadingOutlined />}
                </div>
                <div className="flex align-items-center value">
                  <p>Drafts</p> <span>{mutableData.draft.length}</span>
                </div>
                <p className="desc">Continue editing mails</p>
              </div>
              <div
                onClick={() => setActiveMails({ id: 'deleted' })}
                className={`filterCard deleted ${activeMail.id === 'deleted' ? 'active' : ''}`}
              >
                <div className="flex-space-btw align-items-center">
                  <img
                    className="icon"
                    src={`${
                      activeMail.id === 'deleted'
                        ? '/assets/deletedFilterActiveIcon.svg'
                        : '/assets/deletedFilterIcon.svg'
                    }`}
                    alt="mail icon"
                  ></img>
                  {imLoading.id === 'deleted' && <LoadingOutlined />}
                </div>
                <div className="flex align-items-center value">
                  <p>Deleted</p> <span>{mutableData.deleted.length}</span>
                </div>
                <p className="desc">Restore deleted emails</p>
              </div>
            </div>
            {!isFilterEmpty ? (
              mutableData[activeMail.id || 'emails'].map(({ _id: id, subject, typeOfMail }, index) => (
                <div key={id} className={'card ' + `${preview._id == id ? 'selected' : ''}`}>
                  <>
                    <div onClick={() => setNewPreviewContent(id)} className="flex-space-btw mail">
                      <div className="flex items-center align-center">
                        <span className="px-1 py-0.5 mr-4 rounded-sm successColorLightBg  text-white">{++index}</span>
                        <h3 className="subject">{subject}</h3>
                      </div>
                      <div className="flex justify-center align-center">
                        <span title="Analytics" onClick={() => handleAnalyticsView(id)}>
                          <BarChartOutlined className="successColorLight actionBtn" />
                        </span>
                        {typeOfMail !== 'web-builder' ? (
                          <div
                            className="pointer"
                            title="Edit"
                            onClick={() => {
                              history.push({
                                pathname: `/campaign/${campaignId}/${typeOfMail}`,
                                state: {
                                  mailId: id,
                                },
                              });
                            }}
                          >
                            <EditOutlined className="primaryColorLight actionBtn" />
                          </div>
                        ) : (
                          <Link title="Edit" to={`/campaign/${campaignId}/${id}/${'web-builder'}`}>
                            <EditOutlined className="primaryColorLight actionBtn" />
                          </Link>
                        )}
                        {activeMail.id === 'deleted' &&
                          //  || activeMail.id === 'draft'
                          (imLoading.id === id ? (
                            <LoadingOutlined />
                          ) : (
                            <ReloadOutlined
                              style={{ color: '#37b34a' }}
                              title={activeMail.id === 'deleted' ? 'Restore Mail to draft' : 'Restore Mail to active'}
                              className="pl-2 actionBtn"
                              onClick={() => {
                                restoreMail(id, activeMail.id);
                              }}
                            />
                          ))}
                        <div>
                          {isDeleting.id === id ? (
                            <LoadingOutlined />
                          ) : (
                            <DeleteOutlined
                              style={{ color: '#f45b5b' }}
                              className="pl-2 actionBtn"
                              title="Delete"
                              onClick={() => {
                                setModalVisible({ id: 'deletingEmailModal' });
                                setIsDeleting({ id, activeMail: activeMail.id });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center mt-5 emptyState">
                <EmptyStateImage />
                <p className="mt-3 font-semibold descriptionTextColor font16">
                  Oops! You have no <strong className="capitalize">{activeMail.id}</strong> mail available.
                </p>
              </div>
            )}
          </div>
          <EmailPreview preview={preview} />
        </div>
      </div>
    </div>
  );
};
export default Emails;
