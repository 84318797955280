import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as EmptyStateImage } from '../asset/images/empty.svg';
import Navbar from '../components/Navbar';
import { getLayoutsRequest } from '../utils/service.js';

export const BlankLayouts = ({
  match: {
    params: { campaignId },
  },
}) => {
  useEffect(() => {
    getLayouts();
  }, []);

  const history = useHistory();
  const [layoutLists, setLayoutLists] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLayouts = () => {
    setLoading(true);
    getLayoutsRequest()
      .then((response) => {
        setLoading(false);
        setLayoutLists(response.data.data.layout);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const loadLayout = (data) => {
    localStorage.setItem('templateJson', data.designCode);
    history.push(`/campaign/${campaignId}/web-builder`);
  };
  return (
    <section>
      <div className="my-4">
        <Link to={`/campaign/${campaignId}/emails`} className="my-5 click flex align-items-center w-fit-content">
          <span className="mr-2 text-grey">
            <span className="iconify" data-icon="ant-design:arrow-left-outlined" data-inline="false"></span>
          </span>
          <span className="font24">Create New Email</span>
        </Link>
      </div>
      <Navbar
        links={[
          {
            name: 'Blank Layout',
            link: `/campaign/${campaignId}/layouts`,
          },
          { name: 'Templates', link: `/campaign/${campaignId}/templates` },
          { name: 'Past Emails', link: `/campaign/${campaignId}/past-emails` },
        ]}
      />
      {loading ? (
        <div className="text-center pt-10">
          <Spin />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 gap-6 py-10">
            {layoutLists?.map((each) => (
              <div key={each._id}>
                <p className="font-bold">{each.layoutType}</p>
                <div className="click">
                  <img
                    src={each.imgLocation}
                    alt=""
                    className="w-100"
                    onClick={() => {
                      loadLayout(each);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          {layoutLists.length < 1 && (
            <>
              <div className="flex justify-center items-center flex-col mt-20">
                <EmptyStateImage />
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};
