import { DownOutlined, LoadingOutlined, PaperClipOutlined, WarningOutlined, EyeOutlined } from '@ant-design/icons';
import { Dropdown, Form, Input, Menu, Select, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { sendTestMail } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';
import isValidForm from '../shared-functions/inputValidator';
import { onFileDropHandler } from '../shared-functions/onFileDropHandler';
import { onSystemFileSelected } from '../shared-functions/onSystemFileSelected';
import { deleteAttachment, saveDraftEmail } from '../../../utils/service';
import { ReactComponent as AttachIcon } from './assets/attachIcon.svg';
import { ReactComponent as CampaignIntervalIcon } from './assets/camp-interval-icon.svg';
import { errorMessage } from '../../../utils/getErrors';

const testMailDropdown = ({ mail, setImLoading, campaignId, setMail }) => {
  const { Option } = Select;

  return (
    <Menu className="emmaccen">
      <div className="p-5" style={{ maxWidth: '300px' }}>
        <p className="text-sm fade5">Campaign interval (optional)</p>
        <p className="text-xs fade2">Schedule a time to send this email after the previous one.</p>
        <Form
          name="basic"
          onFinish={({ number, seconds }) => testMail({ mail, setImLoading, campaignId })}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
        >
          <div className="flex-space-btw mt-5">
            <Form.Item name="number" className="sm:w-full md:w-full lg:w-1/4 mr-4">
              <Input
                name="number"
                onChange={(e) => setMail({ ...mail, [e.target.name]: e.target.value })}
                placeholder="Number"
                type="number"
                min={1}
              />
            </Form.Item>
            <Form.Item name="seconds" className="w-full">
              <Select
                showSearch
                placeholder="Select Interval"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => setMail({ ...mail, seconds: value })}
              >
                <Option value={60}>Minute(s)</Option>
                <Option value={60 * 60}>Hour(s)</Option>
                <Option value={24 * 60 * 60}>Day(s)</Option>
                <Option value={7 * 24 * 60 * 60}>Week(s)</Option>
                <Option value={30 * 24 * 60 * 60}>Month(s)</Option>
                <Option value={365 * 24 * 60 * 60}>Year(s)</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Menu>
  );
};

const testMail = ({ mail, setImLoading, campaignId }) => {
  if (isValidForm([mail.body, mail.subject])) {
    setImLoading({ id: 'testMail' });
    sendTestMail(mail, campaignId)
      .then((mail) => {
        showToastMessage({
          type: 'success',
          title: 'Test Mail',
          description: mail?.data?.data?.message || 'Test mail has been sent to your registered email address',
        });
      })
      .catch((e) => {
        const errMsg = errorMessage(e);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      })
      .finally(() => setImLoading({ id: '' }));
  } else {
    setImLoading({ id: '' });
    showToastMessage({
      type: 'warning',
      title: 'Required Fields',
      description: 'Please, provide both email-body and subject',
    });
  }
};

export const EditorActionsTab = ({
  imLoading,
  filesProps,
  handleEmailCreation,
  setImLoading,
  campaignId,
  setMail,
  mail,
  saveDraft,
}) => {
  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles, setIsUploading] = filesProps;
  const deleteFile = ({ src }) => {
    setImLoading({ id: src });
    deleteAttachment(src)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'File',
          description: 'File Deleted',
        });
        setFiles(files.filter((file) => file.src !== src));
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occurred',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  const LoadFilesUi = () =>
    files?.map((fileData, index) => {
      const { src, file, id, status, filename } = fileData;
      return (
        <div
          className={fileData.status + ' flex justify-between items-center h-10 w-full rounded borderShade1 p-6'}
          key={index}
        >
          <div title={filename} className="flex">
            <PaperClipOutlined className="text-xl" />
            <p className="mx-3">{filename.slice(0, 15).concat('...')}</p>
            {fileData?.status === 'WAITING' ? (
              <div className="flex items-center">
                <LoadingOutlined />
              </div>
            ) : (
              fileData?.status === 'ERROR' && (
                <div className="flex items-center">
                  <WarningOutlined className="text-xl" />
                </div>
              )
            )}
          </div>
          <div className="flex items-center">
            {fileData?.status === 'ERROR' && (
              <p
                // onClick={() => setIsUploading(true)}
                className="mr-3 pointer underline"
              >
                Retry
              </p>
            )}
            {<EyeOutlined className="text-xl mr-3" onClick={() => window.open(fileData.src)} />}
            <Button loading={imLoading?.id === src} onClick={() => deleteFile(fileData)} danger size="small">
              Remove
            </Button>
          </div>
        </div>
      );
    });
  const onDragHandler = (ev) => {
    ev.preventDefault();
  };
  return (
    <div>
      <Modal
        className="emmaccen"
        visible={isAttachmentModalVisible}
        onOk={() => setIsAttachmentModalVisible(false)}
        onCancel={() => setIsAttachmentModalVisible(false)}
        footer={null}
      >
        <div className="p-5 attachFileModal">
          <h1 className="text-center title">Attach Files</h1>
          <label
            htmlFor="fileUpload"
            title="Drag and Drop file(s) to be attached to this email"
            onDrop={(e) => onFileDropHandler(e, files, setFiles, setIsUploading)}
            onDragOver={onDragHandler}
            className="block h-32 w-full my-5 cursor-pointer fileUpload"
          >
            <input
              type="file"
              onChange={(e) => onSystemFileSelected(e, files, setFiles, setIsUploading)}
              className=""
              id="fileUpload"
              multiple={true}
            ></input>
            <div className="centered h-full">
              <p className="details">
                Attach files by dragging & dropping or <span className="primaryColor">selecting them</span>.
              </p>
            </div>
          </label>
        </div>
        <div className="flex flex-wrap my-5">{LoadFilesUi()}</div>
        <div className="flex justify-end">
          <Button onClick={() => setIsAttachmentModalVisible(false)} type="primary">
            Done
          </Button>
        </div>
      </Modal>
      <div className="flex align-items-center actions">
        <Dropdown overlay={() => testMailDropdown({ mail, setImLoading, campaignId, setMail })}>
          <a className="ant-dropdown-link flex justify-end" onClick={(e) => e.preventDefault()}>
            <Button size={'large'} type="default" className="mr-3 ghostBtn">
              <CampaignIntervalIcon />
            </Button>
          </a>
        </Dropdown>
        <Button
          onClick={() => setIsAttachmentModalVisible(true)}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn flex align-items-center"
        >
          Attach
          <div style={{ marginLeft: '10px' }}>
            <AttachIcon />
          </div>
        </Button>
        <Button
          loading={imLoading?.id === 'draftingEmail'}
          onClick={() => saveDraft()}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn"
        >
          Save to draft
        </Button>
        <Button
          loading={imLoading?.id === 'testMail'}
          onClick={() => testMail({ mail, setImLoading, campaignId })}
          size={'large'}
          type="default"
          className="mr-3 ghostBtn"
        >
          Send test email
        </Button>
        <Button
          onClick={() => {
            handleEmailCreation(mail.number * mail.seconds);
          }}
          loading={imLoading?.id === 'emailAction'}
          size={'large'}
          type="primary"
          className="primaryBtn"
          disabled={imLoading?.id === 'testMail'}
        >
          Send to campaign
        </Button>
      </div>
    </div>
  );
};
