import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import '../../../styles/modals.scss';
import { createContact } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';

export const AddNewContact = ({ imVisible, setImVisible, setRefresh }) => {
  const [imLoading, setImLoading] = useState({ id: '' });

  const { Search } = Input;
  const { Option } = Select;

  const onSubmitContactDetail = (data) => {
    setImLoading({ id: 'creatingContact' });
    createContact()
      .then((contact) => {
        setRefresh(contact.data);
        showToastMessage({
          type: 'success',
          title: 'Contact Added',
          description: 'Contact(s) added successfully',
        });
      })
      .catch((err) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to add contact(s)',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <Modal
      // title="Delete Email"
      className="emmaccen"
      visible={imVisible.id === 'addNewContact'}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
    >
      <div className="addNewContactModal">
        <h2 className="text-center title">Add new</h2>
        <Form
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
        >
          <label>Name</label>
          <Form.Item
            // label="Name"
            name="name"
            rules={[{ required: true, message: 'Please, provide a name!' }]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>

          <label>Email address</label>
          <Form.Item
            // label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please, provide an email!',
                type: 'email',
              },
            ]}
          >
            <Input type="email" placeholder="johndoe@person.com" />
          </Form.Item>
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={imLoading?.id === 'creatingContact'}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
