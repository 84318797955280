import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Modal, notification, Tabs } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
import { getCampaign } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import './editor.scss';
import createTempImg from '../../asset/images/create-template.svg';
import uploadCodeImg from '../../asset/images/upload-code.svg';
import emptyTableImg from '../../asset/images/flat-color-icons_template.svg';
import { createTemplateaArray, defaultTemplateaArray, savedTemplateaArray } from './MockedTemplateArray';
import LoadingScreen from '../../components/LoadingScreen';

const PreviewTemplate = ({ title, visible, icon, setVisible }) => {
  return (
    <Modal
      title={title}
      centered
      visible={visible}
      footer={null}
      onCancel={setVisible}
      width={1000}
      style={{ margin: '20px 0' }}
    >
      <div>
        <img src={icon} alt="preview_img" width="100%" height="100%" />
      </div>
    </Modal>
  );
};

const TemplateCard = ({ id, icon, title, fetchedTemplate, onClick, tabKey, currentCard }) => {
  const [modalvisible, setModalVisible] = useState(false);

  const isCurrentCard = id === currentCard;

  return (
    <div className={`template-card flex flex-wrap ${isCurrentCard && 'active'}`}>
      {fetchedTemplate ? (
        <div className="temp_card_contain text-center w-full h-full" onClick={onClick}>
          <img src={icon} alt="new-template" className="temp_img" />
          <div className="temp_overlay text-center">
            <div className="temp_overlay__content">
              <button
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                Preview
                <ZoomInOutlined />
              </button>
            </div>
          </div>
          <PreviewTemplate title={title} icon={icon} visible={modalvisible} setVisible={() => setModalVisible(false)} />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full" onClick={onClick}>
          <img src={icon} alt="new-template" className="mb-8" />
          <div>{title}</div>
        </div>
      )}
    </div>
  );
};

const TemplateSelection = ({
  setSelectedPlate,
  submitTemplateSelected,
  setSelectedPlateObj,
  submissionIsLoading,
  submissionDisabled,
}) => {
  const { TabPane } = Tabs;
  const [tabKey, setTabKey] = useState('1');
  const [currentCard, setCurrentCard] = useState(null);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [fetchDefaultTemplates, setFetchDefaultTemplates] = useState([]);
  const [mySavedFetchTemplates, setMySavedFetchTemplates] = useState([]);

  useEffect(() => {
    // http://api-dev.maildrip.io/api/v1/templates/default

    setIsTemplateLoading(true);
    (async () => {
      const resp = await axios.get('/templates/default');

      const resp2 = await axios.get('/templates/my-templates');
      setFetchDefaultTemplates(resp.data.data.templates);
      setMySavedFetchTemplates(resp2.data.data.templates);

      setIsTemplateLoading(false);
    })();
  }, []);

  const campaigneActions = {
    right: (
      <div className="flex">
        <div className="actionBtns">
          <Button
            onClick={submitTemplateSelected}
            size={'large'}
            type="primary"
            className="text-sm flex items-center btnPrimary"
            loading={submissionIsLoading}
            style={{ backgroundColor: `${submissionDisabled ? 'rgba(199, 199, 199, 1)' : ''}` }}
          >
            Save & Continue
          </Button>
        </div>
      </div>
    ),
  };

  return (
    <div className="mail_subject__template_select">
      <Tabs onTabClick={(key) => setTabKey(key)} tabBarExtraContent={campaigneActions}>
        <TabPane tab="Create New" key="1">
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <div className="templatePane flex flex-wrap overflow-y-auto">
              {createTemplateaArray.length === 0 ? (
                <>
                  <TemplateCard id="003" icon={createTempImg} title="" />
                  <TemplateCard id="004" icon={uploadCodeImg} title="" />
                  <TemplateCard id="005" icon={emptyTableImg} title="" />
                </>
              ) : (
                createTemplateaArray.map((temp, index) => {
                  return (
                    <TemplateCard
                      key={temp._id}
                      id={temp._id}
                      tabKey="1"
                      icon={temp.previewImage}
                      title={temp.title}
                      onClick={(e) => {
                        setCurrentCard(temp._id);
                        setSelectedPlate(temp._id);
                        setSelectedPlateObj(temp);
                      }}
                      // fetchedTemplate
                      currentCard={currentCard}
                    />
                  );
                })
              )}
            </div>
          )}
        </TabPane>
        <TabPane tab="Templates" key="2">
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {fetchDefaultTemplates.length === 0 ? (
                <div className="w-full">
                  <img className="mx-auto" src={emptyTableImg} alt="" />
                </div>
              ) : (
                <div className="templatePane  flex flex-wrap overflow-y-auto">
                  {fetchDefaultTemplates.map((temp, index) => {
                    return (
                      <TemplateCard
                        key={temp._id}
                        id={temp._id}
                        tabKey="2"
                        icon={temp.previewImage}
                        data-index={index}
                        onClick={(e) => {
                          setCurrentCard(temp._id);
                          setSelectedPlate(temp._id);
                          setSelectedPlateObj(temp);
                        }}
                        fetchedTemplate
                        currentCard={currentCard}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </TabPane>
        <TabPane tab="My Saved Templates" key="3">
          {isTemplateLoading ? (
            <LoadingScreen />
          ) : (
            <>
              {mySavedFetchTemplates.length === 0 ? (
                <div className="w-full">
                  <img className="mx-auto" src={emptyTableImg} alt="" />
                </div>
              ) : (
                <div className="templatePane  flex flex-wrap overflow-y-auto">
                  {mySavedFetchTemplates.map((temp, index) => {
                    return (
                      <TemplateCard
                        key={temp._id}
                        id={temp._id}
                        tabKey="3"
                        icon={temp.previewImage}
                        data-index={index}
                        onClick={(e) => {
                          setCurrentCard(temp._id);
                          setSelectedPlate(temp._id);
                          setSelectedPlateObj(temp);
                        }}
                        fetchedTemplate
                        currentCard={currentCard}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </TabPane>
      </Tabs>
      <Divider />
    </div>
  );
};

const SubjectOfMail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [submissionDisabled, setSubmissionDisabled] = useState(true);
  const [subjectOfMail, setSubjectOfMail] = useState('');

  const [form] = Form.useForm();

  const { campaignId } = useParams();

  const openNotification = (message, description) => {
    notification.info({
      message,
      description,
    });
  };

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Overview',
      link: `/`,
      index: 0,
    },
    {
      name: '...',
      link: `/campaign/${campaignId}/emails`,
      index: 1,
    },
    // {
    //   name: '',
    //   link: `#`,
    //   index: 2,
    // },
  ]);

  const [selectedPlate, setSelectedPlate] = useState(null);
  const [selectedPlateObj, setSelectedPlateObj] = useState({});
  console.log({ selectedPlate });
  console.log({ selectedPlateObj });
  useEffect(() => {
    getCampaign({ campaignId })
      .then((campaign) => {
        let updatedList = breadCrumbs.map((data) => {
          if (data.index === 1) {
            return { ...data, name: campaign.name };
          }
          return data;
        });
        setBreadCrumbs(updatedList);
      })
      .catch(() =>
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to fetch data',
        })
      );
    if (subjectOfMail !== '' && selectedPlate !== null) {
      setSubmissionDisabled(false);
    }
  }, [subjectOfMail, selectedPlate]);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`/campaigns/${campaignId}/addmail`, {
        subject: values.subject,
        typeOfMail: 'web-builder',
      });
      // get the response of the data created last👇
      const getLatestData = response.data.data.campaign.emails.slice(-1).pop();
      showToastMessage({
        type: 'success',
        title: 'Saved',
        description: 'Saved successfully',
      });
      setTimeout(() => {
        window.location.replace(`/campaign/${campaignId}/${getLatestData._id}/${getLatestData.typeOfMail}`);

        setIsLoading(false);
      }, 4000);
    } catch (error) {
      openNotification('ERROR', 'Something went wrong, please try again later.');
    }
  };
  const submitTemplateSelected = async () => {
    if (subjectOfMail === '') {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: "Sorry, you can't have an empty subject",
      });
    } else if (selectedPlate === null) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Kindly select a template to continue',
      });
    } else {
      setIsLoading(true);
      // return;
      const { body, content, typeOfMail, key } = selectedPlateObj;
      try {
        const response = await axios.post(`/campaigns/${campaignId}/addmail`, {
          subject: subjectOfMail,
          //data to update with (through the template selected)
          // typeOfMail: selectedPlateObj?.temp,
          body,
          content,
          typeOfMail,
        });
        showToastMessage({
          type: 'success',
          title: 'Saved',
          description: 'Saved successfully',
        });
        // get the response of the data created last👇
        const getLatestData = response.data.data.campaign.emails.slice(-1).pop();
        if (response.data.success) {
          setTimeout(() => {
            if (key === 'upload-code') {
              window.location.replace(
                `/campaign/${campaignId}/${getLatestData._id}/${getLatestData.typeOfMail}?key=${key}`
              );
            } else {
              window.location.replace(`/campaign/${campaignId}/${getLatestData._id}/${getLatestData.typeOfMail}`);
              // setIsLoading(false);
            }
          }, 4000);
        }
      } catch (error) {
        openNotification('ERROR', 'Something went wrong, please try again later.');
      }
    }
    // return;
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleSubjectInput = (e) => {
    let updatedList = breadCrumbs.map((data) => {
      if (data.index === 2) {
        return { ...data, name: e.target.value };
      }
      return data;
    });
    setSubjectOfMail(e.target.value);
    setBreadCrumbs(updatedList);
  };
  return (
    <>
      <div className="headerTab">
        <BreadCrumb crumbs={breadCrumbs} />
      </div>
      <div className="boomshakar">
        <div className="templateContaner p-4 sm:p-20 sm:pt-12 pt-12 relative mt-5 h-full w-full">
          <div className="grid2">
            <div className="mailList px-8 py-10">
              <h3 className="header-title bold">Campaign details</h3>
              <div className="my-5" style={{ maxWidth: '450px', width: '100%' }}>
                <Form layout="vertical" form={form} name="control-hooks">
                  <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
                    <Input placeholder="Email Subject" onChange={handleSubjectInput} style={{ height: '49px' }} />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <TemplateSelection
            setSelectedPlateObj={setSelectedPlateObj}
            selectedPlateObj={selectedPlateObj}
            setSelectedPlate={setSelectedPlate}
            selectedPlate={selectedPlate}
            submitTemplateSelected={submitTemplateSelected}
            submissionIsLoading={isLoading}
            submissionDisabled={submissionDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default SubjectOfMail;
