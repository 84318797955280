export const passwordRules = [
  {
    min: 6,
    message: 'Must be a minimum of 6 characters',
  },
  {
    pattern: new RegExp(/([A-Z])/),
    message: 'Must contain capital letters between (A-Z)',
  },
  {
    pattern: new RegExp(/([!%@#$&*])/),
    message: 'Must contain special characters (!%@#$&*)',
  },
  {
    pattern: new RegExp(/([0-9])/),
    message: 'Must contain numbers between (0-9)',
  },
  {
    pattern: new RegExp(/([a-z])/),
    message: 'Must contain lowecase characters between (a-z)',
  },
];
