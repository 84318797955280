import {
  CheckCircleFilled,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Alert,
  Form,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import 'rsuite/dist/styles/rsuite-default.css';
import ChoosePlanModal from '../components/ChoosePlanModal';
import DateFromNow from '../components/DateFromNow';
import '../styles/campaigne.scss';
import { createCampaign, deleteCampaign, fetchCampaigns, ToggleCampaign } from '../utils/service.js';
import { showToastMessage } from '../utils/Toast';

function Campaigns() {
  const [searchValue, setSearchValue] = useState('');
  const [cardResponse, setCardResponse] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [campaignListKept, setCampaignListKept] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [statusView, setStatusView] = useState('All');
  const [shareDiv, setShareDiv] = useState(0);
  const [billingModal, setBillingModal] = useState(false);
  const openBillingModal = () => setBillingModal(true);
  const closeBillingModal = () => setBillingModal(false);
  const [imVisible, setImVisible] = useState({ id: '' });
  const [imLoading, setImLoading] = useState({ id: '' });
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
  const { Search } = Input;
  const { Option } = Select;
  const webUrl = process.env.REACT_APP_WEB_URL;
  const { Paragraph } = Typography;
  function fetchAllCampaigns() {
    setImLoading({ id: 'loadingCampaigns' });
    fetchCampaigns(
      '',
      '',
      {
        cancelLoading: () => setImLoading({ id: '' }),
      },
      setCampaignList,
      setCampaignListKept
    );
  }

  useEffect(() => {
    fetchAllCampaigns();
  }, []);

  // useEffect(() => {}, [searchValue, dateValue]);
  const debouncedSearchVal = useDebounce(searchValue, 500);

  useEffect(() => {
    if (debouncedSearchVal) {
      fetchCampaigns(
        'search',
        debouncedSearchVal,
        {
          cancelLoading: () => setImLoading({ id: '' }),
        },
        setCampaignList
      );
    } else {
      setCampaignList(campaignListKept);
      setImLoading({ id: '' });
    }
  }, [debouncedSearchVal]);

  const searchCampaigns = (search) => {
    // changeListView('all');
    const seachDuty =
      // search === ''
      search.length === 0 || search === undefined
        ? () => {
            setCampaignList(campaignListKept);
            setImLoading({ id: '' });
          }
        : () => {
            setImLoading({ id: 'searchingCampaigns' });
            // if (campaignListKept.length > 0) {
            fetchCampaigns(
              'search',
              search,
              {
                cancelLoading: () => setImLoading({ id: '' }),
              },
              setCampaignList
            );
            // }
          };
    seachDuty();
  };

  const onCopy = (link) => {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(`${webUrl}/${link}`);
        showToastMessage({
          type: 'success',
          title: 'Copied',
          description: 'Campaign link copied to clipboard',
        });
      }
    } catch (e) {}
  };

  const toggle = (id, status) => {
    setImLoading({ id });

    ToggleCampaign({ campaignId: id })
      .then(
        ({
          data: {
            data: { message: message },
          },
        }) => {
          showToastMessage({
            type: !status ? 'success' : 'warning',
            title: !status ? 'Activated' : 'Deactivated',
            description: `Your campaign has been successfully ${!status ? 'activated' : 'deactivated'}`,
          });
          const newList = campaignListKept.map((data) => {
            if (data._id === id) {
              data.status = !data.status;
              return data;
            } else {
              return data;
            }
          });
          setCampaignList(newList);
          setCampaignListKept(newList);
          setImLoading({ id: '' });
        }
      )
      .catch((err) => {
        setImLoading({ id: '' });
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to process request',
        });
      });
  };

  const changeListView = async (val) => {
    let tempData;
    if (val === 'active') {
      setStatusView(val);
      tempData = campaignListKept.filter((each) => each.status === true);
      setCampaignList([...tempData]);
    } else if (val === 'inactive') {
      setStatusView(val);
      tempData = campaignListKept.filter((each) => each.status !== true);
      setCampaignList([...tempData]);
    } else {
      setStatusView(val);
      setDateValue('');
      setSearchValue('');
      setCampaignList(campaignListKept);
    }
  };

  const extractedDate = (datetime) => {
    const date = {
      year: '',
      month: '',
      day: '',
    };
    date.year = new Date(datetime).getFullYear();
    date.month = new Date(datetime).getMonth() + 1;
    date.day = new Date(datetime).getDate();
    const formattedDate = `${date.year}-${date.month}-${date.day}`;
    return formattedDate;
  };
  const filterByDate = (val) => {
    const date = extractedDate(val);
    if (date) {
      setSearchValue('');
      setDateValue({ date });
    } else {
      setCampaignList(campaignListKept);
    }
  };
  const routeToStripe = () => {
    closeBillingModal();
    window.open(cardResponse, '_blank');
  };

  const deleteCampaignFunc = (campaignId) => {
    // setImLoading({ id: campaignId });
    deleteCampaign(campaignId)
      .then(({ data }) => {
        if (data.success) {
          setImLoading({ id: '' });
          const newCampaignList = campaignList.filter((campaign) => campaign._id !== campaignId);
          setCampaignList(newCampaignList);
          setCampaignListKept(newCampaignList);
          showToastMessage({
            type: 'success',
            title: 'Campaign Deleted',
            description: data.data.message,
          });
        }
      })
      .catch((error) => {
        setImLoading({ id: '' });
        const errorObject = error.response.data;
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errorObject.message || 'Oops! An error occured',
        });
      });
  };

  const columns = [
    {
      title: 'Title',
      width: 150,
      render: ({ name, createdAt, _id: id }) => (
        <div>
          <Link to={`/campaign/${id}/`}>
            <div style={{ color: '1c1c1c1' }} className="text-lg font-medium hover:text-primaryDisabled capitalize">
              {name}
            </div>
          </Link>
          <p className="fade3 text-base">
            Created <DateFromNow date={new Date(createdAt)} />
          </p>
        </div>
      ),
    },
    {
      title: 'Email(s)',
      width: 150,
      dataIndex: 'emails',
    },
    {
      title: 'Subscriber(s)',
      width: 150,
      dataIndex: 'recepientsCount',
    },
    {
      title: 'Status',
      width: 150,
      // dataIndex: "status",
      render: ({ _id: id, status }) => (
        <div>
          <Switch loading={imLoading.id === id} checked={status} onChange={() => toggle(id, status)} />
        </div>
      ),
    },
    {
      title: 'Action',
      width: 150,
      render: ({ _id: id }) => (
        <div className="actionIcons">
          <div className="pointer primaryColorLight">
            <Popover
              content={
                <div className="flex">
                  <FacebookShareButton url={`${webUrl}/join/${id}`} title={'MailDrip'}>
                    <img className="mr-3" src="/assets/facebookIcon.svg"></img>
                  </FacebookShareButton>
                  <TwitterShareButton url={`${webUrl}/join/${id}`} title={'MailDrip'}>
                    <img className="mr-3" src="/assets/twitterIcon.svg"></img>
                  </TwitterShareButton>
                  <LinkedinShareButton url={`${webUrl}/join/${id}`} title={'MailDrip'}>
                    <img className="mr-3" src="/assets/linkedinIcon.svg"></img>
                  </LinkedinShareButton>
                  <Paragraph
                    style={{ margin: '0px' }}
                    copyable={{
                      icon: [
                        <img key="copy-icon" src="/assets/copyIcon.svg"></img>,
                        <CheckCircleFilled key="copied-icon" />,
                      ],
                      text: `${webUrl}/join/${id}`,
                      tooltips: ['Copy url', 'Url copied to clipboard'],
                    }}
                  ></Paragraph>
                </div>
              }
            >
              <ShareAltOutlined />
            </Popover>
          </div>
          <div className="pointer deleteIcon">
            <Tooltip placement="topLeft" color="red" title={'Delete Campaign? This action cannot be undone'}>
              {imLoading.id === id ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <DeleteOutlined
                  onClick={() => {
                    setImVisible({ id: 'deletingCampaignModal' });
                    setImLoading({ id });
                  }}
                />
              )}
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];
  // rowSelection object indicates the need for row selection

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const DeleteModal = () => {
    return (
      <Modal
        title="Delete Campaign"
        className="emmaccen"
        visible={imVisible.id === 'deletingCampaignModal'}
        onOk={() => {
          setImVisible({ id: '' });
          deleteCampaignFunc(imLoading.id);
        }}
        onCancel={() => {
          setImLoading({ id: '' });
          setImVisible({ id: '' });
        }}
      >
        <div className="emmaccen">
          <p>Are you sure you want to delete this Campaign?</p>
        </div>
      </Modal>
    );
  };
  const CampaignListUi = ({ campaignList }) => {
    return (
      <div className="emmaccen">
        <div className="antdTable">
          <Divider />

          <Table
            // rowSelection={{
            //   type: "checkbox",
            //   ...rowSelection,
            // }}
            // scroll={{ x: true }}
            scroll={{ y: '300px', x: true }}
            sticky={true}
            rowKey={'_id'}
            columns={columns}
            dataSource={campaignList}
            rowClassName="table-row"
          />
        </div>
      </div>
    );
  };

  const sortMenu = (
    <Menu>
      <Menu.Item style={{ padding: '10px' }}>
        <p onClick={() => changeListView('all')}>All</p>
      </Menu.Item>
      <Menu.Item>
        <p onClick={() => changeListView('active')}>Active</p>
      </Menu.Item>
      <Menu.Item>
        <p onClick={() => changeListView('inactive')}>Inactive</p>
      </Menu.Item>
    </Menu>
  );

  const CreateNewCampaign = (name, number, seconds) => {
    setImLoading({ id: 'creatingNewCampaign' });
    createCampaign({ interval: `${number * seconds}`, name })
      .then((result) => {
        setImLoading({ id: '' });
        setImVisible({ id: '' });
        showToastMessage({
          type: 'success',
          title: 'Campaign Created',
          description: 'Campaign created successfully',
        });
        //refresh campaign list
        fetchAllCampaigns();
      })
      .catch((e) => {
        setImLoading('');
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.message,
        });
      });
  };

  const CreateNewCampaignModel = () => {
    return (
      <Modal
        title="Create Campaign"
        visible={imVisible.id === 'createNewCampaign'}
        onCancel={() => setImVisible({ id: '' })}
        footer={null}
        className="emmaccen"
      >
        <div>
          <div>
            <Form
              name="basic"
              onFinish={({ name, number, seconds }) => CreateNewCampaign(name, number, seconds)}
              size="large"
              onFinishFailed={() => null}
            >
              <label className="mb-2 formLabel">Campaign title</label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please give your campaign a name',
                  },
                ]}
              >
                <Input placeholder="Give your campaign a name" />
              </Form.Item>
              <label className="formLabel">Campaign intervals</label>
              <div className="flex-space-btw mt-2">
                <Form.Item
                  name="number"
                  rules={[{ required: true, message: 'Please provide a value' }]}
                  style={{ width: '100%', marginRight: '10px' }}
                >
                  <Input
                    // onChange={this.onChange}
                    // onBlur={this.onBlur}
                    placeholder="Input a number"
                    type="number"
                    min={1}
                  />
                </Form.Item>
                <Form.Item
                  name="seconds"
                  rules={[{ required: true, message: 'Please provide a value' }]}
                  style={{ width: '100%' }}
                >
                  <Select
                    showSearch
                    placeholder="Select Interval"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value={60}>Minute(s)</Option>
                    <Option value={60 * 60}>Hour(s)</Option>
                    <Option value={24 * 60 * 60}>Day(s)</Option>
                    <Option value={7 * 24 * 60 * 60}>Week(s)</Option>
                    <Option value={30 * 24 * 60 * 60}>Month(s)</Option>
                    <Option value={365 * 24 * 60 * 60}>Year(s)</Option>
                  </Select>
                </Form.Item>
              </div>
              <Alert
                message="Campaign interval sets the time between emails in this campaign"
                type="info"
                showIcon
                closable
              />
              <div className="centered mt-10">
                <Button
                  size={'large'}
                  type="primary"
                  htmlType="submit"
                  // icon={<PlusOutlined />}
                  loading={imLoading?.id === 'creatingNewCampaign'}
                >
                  Create Campaign
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="h-full">
      <>
        {/* <DatePicker
            appearance="default"
            placeholder="Date Created"
            onChange={(e) => filterByDate(e)}
          /> */}
        <CreateNewCampaignModel />
        <DeleteModal />

        {imLoading.id === 'loadingCampaigns' ? (
          <div className="centered">
            <Spin indicator={<LoadingOutlined />} />
          </div>
        ) : (
          <div className="emmaccen">
            <div className="flex justify-end" id="campaignHeader">
              <div className="flex rightSection">
                <Input
                  placeholder="Search Campaign..."
                  allowClear
                  // enterButton="Search"
                  className="fade3"
                  prefix={imLoading.id === 'searchingCampaigns' ? <LoadingOutlined /> : <SearchOutlined />}
                  size="large"
                  onChange={({ target: { value } }) => {
                    // console.log(value);
                    // value === '' ? searchCampaigns('') : searchCampaigns(value);
                    setImLoading({ id: 'searchingCampaigns' });
                    setSearchValue(value);
                  }}
                  // onSearch={searchCampaigns}
                  // loading={imLoading.id === "searchingCampaigns"}
                />

                <Button
                  onClick={() => setImVisible({ id: 'createNewCampaign' })}
                  size="large"
                  className="btnPrimary text-sm justify-center flex items-center"
                >
                  Create New <PlusOutlined />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="flex campaignTableData">
          {/* <Dropdown overlay={sortMenu}>
            <a
              className="ant-dropdown-link sorter"
              onClick={(e) => e.preventDefault()}
            >
              <p className="border p-2 px-4">Sort: {statusView}</p>
            </a>
          </Dropdown> */}
          <div className="mr-5 my-5 sideMenu">
            {/* <Button
              type="dashed"
              onClick={() => setIsSortMenuOpen(!isSortMenuOpen)}
              className="mb-3 border"
            >
              <div className="centered">
                {isSortMenuOpen ? <RightOutlined /> : <LeftOutlined />}
              </div>
            </Button> */}
            <Menu
              onClick={({ key }) => changeListView(key)}
              defaultSelectedKeys={['all']}
              // defaultOpenKeys={["sub1"]}
              inlineCollapsed={isSortMenuOpen}
              mode="inline"
              className="border-none"
            >
              <h5 className="font-bold fade2 mb-3 md:text-xl lg:text-xl">Status</h5>
              <Menu.Item key="all">All</Menu.Item>
              <Menu.Item key="active">Active</Menu.Item>
              <Menu.Item key="inactive">Inactive</Menu.Item>
            </Menu>
          </div>
          <div className="w-4/5 mx-auto tableList">
            <CampaignListUi campaignList={campaignList} />
          </div>
        </div>
      </>
      <Modal
        title="Add Card"
        visible={billingModal}
        onOk={routeToStripe}
        onCancel={closeBillingModal}
        footer={[
          <button
            key="back"
            className="btn btn-outline-blue mr-2"
            onClick={() => {
              closeBillingModal();
              // toggleStatus(currentToggle, true);
            }}
          >
            Cancel
          </button>,
          <button key="submit" className="btn btn-blue" type="primary" onClick={routeToStripe}>
            Add Card
          </button>,
        ]}
      >
        <div className="text-center">
          <p className="mb-3">
            You are currently on a free plan with a limit of 100 mails.
            <br /> click "Add Card" to add your Card to your billing information. Don't worry, we won't charge you
            unless you select a plan.
          </p>
          <p>Click "cancel" to continue with your free plan</p>
        </div>
      </Modal>

      {/* Notification Modal Start */}
      {/* <ChoosePlanModal /> */}
      {/* Notification Modal ends */}
    </div>
  );
}

export default Campaigns;
