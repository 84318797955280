import * as Sentry from '@sentry/browser';
import 'grapesjs/dist/css/grapes.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './tailwind.css';

Sentry.init({
  dsn: process.env.SENTRY_INGEST_URL,
});

ReactDOM.render(<App />, document.getElementById('root'));
