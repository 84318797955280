export const isUserOnFreePlan = (user) => {
  if (user.isPaidUser == undefined) throw Error('Invalid Uaer object');
  // Switch to user.isPaidUser for sub status
  return !user.isPaidUser;
};
export const firstLetterCaps = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const apostropheLastLetter = (str) => {
  if (str.slice(-1) == 's') {
    return str.replace(/.$/, "s'");
  } else {
    return str.concat("'s");
  }
};
export const convertNumber = (str) => {
  if (str.slice(0) == '+') {
    return Number(str.slice(0));
  } else {
    return Number(str);
  }
};

export const trunacteString = (str = '', length) => {
  if (str.length <= length) return str;
  return str.substring(0, Number(length)).concat('...');
};
