import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { passwordRules } from '../../components/emmaccen/shared-data/passwordRule';
import '../../styles/resetPassword.scss';
import { resetPasswordRequest } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';
import { CarouselSlider } from './Login';
export const ResetPasswordPage = () => {
  const { hash } = useParams();
  const [imLoading, setImLoading] = useState({ id: '' });
  const history = useHistory();

  const createNewPassword = ({ password }) => {
    setImLoading({ id: 'creatingpassword' });
    resetPasswordRequest(hash, { password })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Password Updated Successfully',
          description: 'You can now login with your new password',
        });
        history.replace('/login');
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Request Failed',
          description: error?.response?.data?.error,
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer resetPasswordContainer">
        <div className="loginSections flex">
          <CarouselSlider />
          <div className="w-50 formArea">
            <div className="centered formContent">
              <div className="container2 flex flex-col justify-between">
                <div className="flex-jst-right mb-5">
                  <img alt="logo" src="/assets/smallLogo.svg"></img>
                </div>
                <div>
                  <div className="margin-t-b">
                    <h1 className="bold title mb-2">New Password</h1>
                    <p className="paragraph">Welcome back, you know the drill!</p>
                  </div>

                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={createNewPassword}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                  >
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please provide a new password',
                        },
                        ...passwordRules,
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="New password" />
                    </Form.Item>
                    <Form.Item
                      name="match-pass"
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your new password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="confirm new password" />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={imLoading.id === 'creatingpassword'}>
                        Create Password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
