import { LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Spin, Tabs, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Emails, Receipients, Settings } from '../components/emmaccen/campaignEmails';
import CampaignInformation from '../components/emmaccen/campaignEmails/CampaignInformation';
import { CreateNewEmailModal } from '../components/emmaccen/campaignEmails/CreateNewEmailModal.js';
import ShareAccessDrop from '../components/ShareAccessDrop';
import '../styles/campaigne.scss';
import {
  addRecepientToCampaign,
  deleteCampaignUser,
  deleteEmail,
  editInterval,
  editVariables,
  emailConfiguration,
  getCampaign,
  getVariables,
  sendToTrash,
  ToggleCampaign,
  getAllContactGroups,
  deleteVariable,
} from '../utils/service';
import { ReactComponent as ArrowDownIcon } from '../asset/Icons/arrow-down.svg';
import { showToastMessage } from '../utils/Toast';
import { containAzDchar } from 'utils/formRules';
import AddRecipientsToCampaign from 'components/emmaccen/modals/AddRecipientsToCampaign';
import FormsTab from 'components/emmaccen/campaignEmails/FormsTab/index';
import LoadingScreen from 'components/LoadingScreen';

export const CampaignEmailList = ({
  match: {
    params: { campaignId },
  },
}) => {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [data, setData] = useState({ emails: [], name: '', users: [], mail: [], draft: [], deleted: [] });
  // we keep this as the original copy of our data
  const [campaigns, setCampaigns] = useState({
    emails: [],
    name: '',
    users: [],
  });

  const [isDeleting, setIsDeleting] = useState({ id: '' });
  const [imLoading, setImLoading] = useState({ id: '' });
  const [campaignVariables, setCampaignVariables] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [campaignModalVisible, setCampaignModalVisible] = useState(false);
  const [turningCampaignOn, setTurningCampaignOn] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [modalVisible, setModalVisible] = useState({ id: false });
  const [contactGroups, setContactGroups] = useState([]);
  const [submitAddRecipients, setSubmitAddRecipients] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { TabPane } = Tabs;
  const { Option } = Select;
  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Overview',
      link: `/`,
      index: 0,
    },
  ]);

  const campaignInfoTab = `/campaign/${campaignId}`;
  const emailTab = `/campaign/${campaignId}/emails`;
  const recipientsTab = `/campaign/${campaignId}/recipients`;
  const formsTab = `/campaign/${campaignId}/forms`;
  const settingsTab = `/campaign/${campaignId}/settings`;

  const defaultToRequestedTab = () => {
    let defaultActiveKey;
    if (location.pathname === campaignInfoTab) {
      defaultActiveKey = 1;
    } else if (location.pathname === emailTab) {
      defaultActiveKey = 2;
    } else if (location.pathname === recipientsTab) {
      defaultActiveKey = 3;
    } else if (location.pathname === formsTab) {
      defaultActiveKey = 4;
    } else if (location.pathname === settingsTab) {
      defaultActiveKey = 5;
    }
    return String(defaultActiveKey);
  };
  const [tabKey, setTabKey] = useState(defaultToRequestedTab());

  const [tabKeys, _] = useState({
    defaultActiveKey: defaultToRequestedTab(),
    campaignInfoT: '1',
    emailT: '2',
    recipientsT: '3',
    formsT: '4',
    settingsT: '5',
  });

  const fetchGroups = () => {
    setImLoading({ id: 'fetchingAllGroups' });
    getAllContactGroups()
      .then((groups) => {
        setContactGroups(groups?.data.data.groups);
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  const handleDeleteEmail = (emailId) => {
    setModalVisible({ id: '' });
    isDeleting?.activeMail === 'deleted'
      ? deleteEmail({
          campaignId,
          emailId,
          run: [() => setImLoading({ id: '' }), () => setIsDeleting({ id: '' })],
        }).then(() => {
          setRefresh(refresh + 1);
          showToastMessage({
            type: 'success',
            title: 'Mail Deleted',
            description: 'Mail deleted successfully',
          });
        })
      : sendToTrash({
          campaignId,
          emailId,
          run: [() => setImLoading({ id: '' }), () => setIsDeleting({ id: '' })],
        }).then(() => {
          setRefresh(refresh + 1);
          showToastMessage({
            type: 'warning',
            title: 'Mail Trash',
            description: 'Mail moved to trash',
          });
        });
  };

  const loadVariables = () => {
    setImLoading({ id: 'loadingVariables' });
    getVariables(campaignId)
      .then(({ data: { data } }) => {
        setCampaignVariables(data);
        setImLoading('');
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.message,
        });
      });
  };
  useEffect(() => {
    loadVariables();
    fetchGroups();
  }, []);

  const searchCampaigns = (value) => {
    setImLoading('searchCampaign');
    value = value.toLowerCase().trim();
    let filteredData = [];
    tabKey == 2
      ? (() => {
          filteredData = campaigns.emails.filter(({ subject }) => subject.toLowerCase().includes(value));
          setData({ ...data, emails: filteredData });
          setImLoading({ id: '' });
        })()
      : (() => {
          filteredData = campaigns.users.filter(({ name, email }) => name.includes(value) || email.includes(value));
          setRecipients(filteredData);
          setImLoading({ id: '' });
        })();
  };
  const campaigneActions = () => {
    return (
      tabKey != '4' &&
      tabKey != '5' && (
        <div className="flex item-center">
          {tabKey != '1' && (
            <Input
              placeholder={tabKey == '2' || tabKey == '1' ? 'Search emails...' : 'Search receipients...'}
              allowClear
              size="large"
              className="fade3"
              onChange={(e) => searchCampaigns(e.target.value)}
              prefix={imLoading.id === 'searchCampaign' ? <LoadingOutlined /> : <SearchOutlined />}
            />
          )}
          <div className="ml-2">
            {tabKey == '1' ? (
              <div className="flex actionBtns">
                <Link to={`/join/${campaignId}`}>
                  <Button size={'large'} type="primary" className="flex items-center text-sm btnPrimary">
                    Edit Join Page
                  </Button>
                </Link>
                {/* <Dropdown overlay={<ShareAccessDrop />} placement="bottomRight">
                  <Button
                    size={'large'}
                    style={{
                      background: 'rgba(242, 242, 242, 0.3)',
                      color: '#1c1c1c',
                      border: '1px solid rgba(242, 242, 242, 0.3)',
                      borderRadius: '4px',
                    }}
                  >
                    Share access
                  </Button>
                </Dropdown> */}
              </div>
            ) : tabKey == '2' ? (
              <div className="flex actionBtns">
                <Button
                  onClick={() => setModalVisible({ id: 'createNewEmailModal' })}
                  size={'large'}
                  type="primary"
                  className="flex items-center text-sm btnPrimary"
                >
                  Create New <PlusOutlined />
                </Button>
              </div>
            ) : tabKey == '3' ? (
              <div className="flex actionBtns">
                <div className="mr-2">
                  <Button onClick={showModal} size={'large'} type="primary" className="btnPrimary">
                    Add Recipient +
                  </Button>
                </div>
                {/* <Link to={`/campaign/${campaignId}/import-recipient`}> */}
                <Button
                  size={'large'}
                  className="border btnGhost"
                  onClick={() => setModalVisible({ id: 'addNewRecipientToCampaign' })}
                >
                  Import
                </Button>
                {/* </Link> */}
              </div>
            ) : null}
          </div>
        </div>
      )
    );
  };

  const fetchPageData = (campaignId) => {
    console.log('running refresh!!');
    !refresh && setIsDataFetched(false);
    (refresh || !isDataFetched) &&
      getCampaign({ campaignId })
        .then((campaign) => {
          setData({ ...data, ...campaign });
          setCampaigns(campaign);
          setBreadCrumbs([
            ...breadCrumbs,
            {
              name: campaign.name,
              link: `#`,
              index: 1,
            },
          ]);
          setIsDataFetched(true);
          setRecipients(campaign?.users);
        })
        .catch(() =>
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'Unable to fetch data',
          })
        )
        .finally(() => {
          setImLoading({ id: '' });
        });
  };

  useEffect(() => {
    fetchPageData(campaignId);
  }, [refresh]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deleteUserFromCampaign = (campaignId, id) => {
    setImLoading({ id: id });
    deleteCampaignUser(campaignId, id)
      .then(({ data }) => {
        if (data.success) {
          setRecipients(data.data.users);
          showToastMessage({
            type: 'success',
            title: 'Receipient',
            description: 'Recipient deleted successfully',
          });
          setImLoading({ id: '' });
        }
      })
      .catch((error) => {
        const errorObject = error.response.data;
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errorObject.message || 'Oops! An error occured',
        });
        setImLoading({ id: '' });
      });
  };
  const onSubmit = (name, email, groupOfContacts, campaignId) => {
    setImLoading({ id: 'addReceipientEmailLoader' });
    if (!submitAddRecipients[0].value && !submitAddRecipients[1].value && !submitAddRecipients[2].value) {
      showToastMessage({
        type: 'warning',
        title: 'Warning',
        description: 'Sorry, you can`t submit empty fields',
      });
      setImLoading({ id: '' });
    } else if (submitAddRecipients[1].value && !submitAddRecipients[2].value) {
      showToastMessage({
        type: 'warning',
        title: 'Warning',
        description: 'Sorry, you can`t submit without an email address',
      });
      setImLoading({ id: '' });
    } else if (submitAddRecipients[2].value && !submitAddRecipients[1].value) {
      showToastMessage({
        type: 'warning',
        title: 'Warning',
        description: 'Sorry, you can`t submit without a name',
      });
      setImLoading({ id: '' });
    } else {
      addRecepientToCampaign({ name, email, groupOfContacts }, campaignId)
        .then(({ data }) => {
          // console.log({ data });
          handleCancel();
          setImLoading({ id: '' });
          setRefresh(data);
          fetchPageData(campaignId);
          if (data.data.campaignStatus == false) {
            setCampaignModalVisible(true);
          }
          showToastMessage({
            type: 'success',
            title: 'Recepient',
            description: 'Receipient added successfuly',
          });
        })
        .catch((e) => {
          setImLoading({ id: '' });
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: e.resonse?.data?.error || e.message || 'Unable to process your request',
          });
        });
    }
  };

  function handleInterval(number, seconds, campaignId) {
    setImLoading({ id: 'setIntervalBtn' });
    editInterval(campaignId, { interval: `${number * seconds}` })
      .then(() => {
        setImLoading('');
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: 'Campaign interval updated successfully',
        });
      })
      .catch((e) => {
        setImLoading('');
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.fieldsError?.interval?.msg || e.response?.data?.message,
        });
      });
  }

  const handleVariables = (name, value, campaignId) => {
    setImLoading({ id: 'addVariable' });
    editVariables({ name, value }, campaignId)
      .then((res) => {
        setImLoading('');
        setCampaignVariables(res.data.data);
        showToastMessage({
          type: 'success',
          title: 'Variable',
          description: 'Variable created successfully',
        });
      })
      .catch((e) => {
        setImLoading('');
        showToastMessage({
          type: 'error',
          title: "Couldn't Create Variable",
          description: e.response?.data?.message || e.response?.data?.error,
        });
      });
  };

  const handleDeleteVariable = (name) => {
    setImLoading({ id: 'addVariable' });
    deleteVariable(campaignId, name)
      .then((res) => {
        setImLoading('');
        setCampaignVariables(res.data.data);
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: 'Variable deleted successfully',
        });
      })
      .catch((e) => {
        setImLoading('');
        showToastMessage({
          type: 'error',
          title: "Couldn't Delete Variable",
          description: e.response?.data?.message || e.response?.data?.error,
        });
      });
  };

  const handleEmailConfiguration = (payload, campaignId) => {
    setImLoading({ id: 'configuringEmail' });
    emailConfiguration(payload, campaignId)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Campaign Configuration',
          description: 'Campaign configurations has been updated successfully',
        });
      })
      .catch((e) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: e.response?.data?.error || e.message || 'Unable to process your request',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };
  const CampaignModal = {
    title: 'Campaign Inactive',
    content: `Recipient added successfully. This campaign is currently inactive and mails wont be delivered to recipients. 
  Kindly turn on campaign if you want them to start receiving mails.
  `,
  };

  const DeleteModal = () => {
    return (
      <Modal
        title="Delete Email"
        className="emmaccen"
        visible={modalVisible.id === 'deletingEmailModal'}
        onOk={() => handleDeleteEmail(isDeleting.id)}
        onCancel={() => {
          setIsDeleting({ id: '' });
          setModalVisible({ id: '' });
        }}
      >
        <div className="emmaccen">
          <p>
            {isDeleting?.activeMail === 'deleted'
              ? 'This email will be deleted permanently. Proceed?'
              : 'This email will be moved to delete folder, proceed?'}
          </p>
        </div>
      </Modal>
    );
  };

  const turnOnCampaign = async () => {
    try {
      setTurningCampaignOn(true);
      await ToggleCampaign({ campaignId });
      setCampaignModalVisible(false);
      showToastMessage({
        type: 'success',
        title: 'Activated',
        description: `Your campaign has been successfully activated`,
      });
    } catch (error) {
      setTurningCampaignOn(false);
      setCampaignModalVisible(false);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to process request',
      });
    }
  };
  const onFieldsChange = (a, b) => {
    // console.log({ a });
    // console.log({ b });
    setSubmitAddRecipients(b);
    // const test = b.map(({ value }) => ({ value }));
    // const test2 = test.filter((ts) => {
    //   switch (ts.value) {
    //     case undefined:
    //     case []:
    //     case '':
    //       return true;

    //     default:
    //       return false;
    //   }
    // });
    // console.log({ test2 });
  };
  return (
    <div className="emmaccen">
      <div className="campaignDetails">
        <CreateNewEmailModal
          handleModal={setModalVisible}
          isModalVisible={modalVisible.id === 'createNewEmailModal'}
          id={campaignId}
        />
        <DeleteModal />
        <Modal
          className="emmaccen"
          visible={isModalVisible}
          // onOk={handleOk}
          // onCancel={handleCancel}
          footer={null}
          maskClosable={false}
          closable={false}
        >
          <div className="addNewRecipient">
            <h2 className="text-center title">Add New</h2>
            <Form
              name="basic"
              onFinish={({ name, email, groupOfContacts }) => onSubmit(name, email, groupOfContacts, campaignId)}
              onFinishFailed={() => null}
              onFieldsChange={onFieldsChange}
              autoComplete="off"
              size="large"
              layout="vertical"
            >
              <Form.Item name="groupOfContacts" label="Add from Group">
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select Group"
                  onChange={(e) => console.log(e)}
                  optionLabelProp="label"
                >
                  {contactGroups?.map((data) => {
                    return (
                      <Option key={data._id ?? data.title} value={data._id} label={data.title}>
                        <div className="demo-option-label-item">{data.title}</div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className="newRecipientsDir">
                <span>New recepient</span>
                <ArrowDownIcon />
              </div>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    // required: true,
                    message: 'Please, provide a name!',
                  },
                  ...containAzDchar,
                ]}
              >
                <Input placeholder="John Doe" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    // required: true,
                    message: 'Please, provide an email!',
                    type: 'email',
                  },
                ]}
              >
                <Input placeholder="johndoe@person.com" />
              </Form.Item>
              <div className="emmaccen">
                <div className="flex-space-btw actionBtns">
                  <Button size={'large'} type="" onClick={handleCancel}>
                    Back
                  </Button>
                  <Button
                    size={'large'}
                    type="primary"
                    htmlType="submit"
                    loading={imLoading?.id === 'addReceipientEmailLoader'}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
        <Modal
          title={CampaignModal.title}
          visible={campaignModalVisible}
          okText={'Turn On'}
          confirmLoading={turningCampaignOn}
          onOk={turnOnCampaign}
          onCancel={() => {
            setCampaignModalVisible(false);
          }}
        >
          <p>{CampaignModal.content}</p>
        </Modal>
        <AddRecipientsToCampaign
          imVisible={modalVisible.id === 'addNewRecipientToCampaign'}
          campaignId={campaignId}
          setImVisible={setModalVisible}
          setRefresh={setRefresh}
          imLoading={imLoading}
          setImLoading={setImLoading}
        />
        {/* <div className="headerTab" >
          <BreadCrumb
            crumbs={breadCrumbs}
            rightPan={
              <Dropdown overlay={<ShareAccessDrop />} placement="bottomRight">
                <Button
                  style={{
                    background: 'rgba(242, 242, 242, 0.3)',
                    color: '#1c1c1c',
                    border: '1px solid rgba(242, 242, 242, 0.3)',
                    borderRadius: '4px',
                  }}
                >
                  Share access
                </Button>
              </Dropdown>
            }
          />
        </div> */}
        <div className="layoutWrapper">
          {/* {tabKey == 1 && (
            <div className="flex-jst-right">
              <Button
                onClick={() => {
                  history.push({
                    pathname: `/join/${campaignId}`,
                    state: {
                      isEditing: true,
                    },
                  });
                }}
                size="large"
                className="btnPrimary"
              >
                Edit Join Page
              </Button>
            </div>
          )} */}
          <Tabs
            onTabClick={(key) => {
              if (key === tabKeys.campaignInfoT) {
                history.push(campaignInfoTab);
              } else if (key === tabKeys.emailT) {
                history.push(emailTab);
              } else if (key === tabKeys.recipientsT) {
                history.push(recipientsTab);
              } else if (key === tabKeys.formsT) {
                history.push(formsTab);
              } else if (key === tabKeys.settingsT) {
                history.push(settingsTab);
              }
              setTabKey(key);
            }}
            defaultActiveKey={tabKeys?.defaultActiveKey}
            tabBarExtraContent={campaigneActions(campaignId)}
          >
            <TabPane tab="Campaign Information" key="1">
              <div className="textCenter campaignInfoTab">
                {!isDataFetched && (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />} />
                )}
              </div>
              {isDataFetched && <CampaignInformation data={data} campaigns={campaigns} />}
            </TabPane>
            <TabPane tab="Emails" key="2">
              <div className="textCenter campaignInfoTab">
                {!isDataFetched && (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />} />
                )}
              </div>
              {isDataFetched && (
                <Emails
                  data={data}
                  setData={setData}
                  setModalVisible={setModalVisible}
                  setIsDeleting={setIsDeleting}
                  campaignId={campaignId}
                  isDeleting={isDeleting}
                  setRefresh={setRefresh}
                />
              )}
            </TabPane>
            <TabPane tab="Recipients" key="3">
              <Receipients
                recipients={recipients}
                imLoading={imLoading}
                campaignId={campaignId}
                deleteUserFromCampaign={deleteUserFromCampaign}
              />
            </TabPane>
            <TabPane tab="Forms" key="4">
              {!process.env.REACT_APP_DONSHOW ? (
                <LoadingScreen loaderTitle={'Coming soon...'} />
              ) : (
                <FormsTab campaignId={campaignId} data={data} />
              )}
            </TabPane>
            <TabPane tab="Settings" key="5">
              <Settings
                imLoading={imLoading}
                handleInterval={handleInterval}
                campaignId={campaignId}
                handleEmailConfiguration={handleEmailConfiguration}
                handleVariables={handleVariables}
                handleDeleteVariable={handleDeleteVariable}
                campaignVariables={campaignVariables}
                data={data}
              />
            </TabPane>
          </Tabs>
          <Divider />
        </div>
      </div>
    </div>
  );
};
export default CampaignEmailList;
