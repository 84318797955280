import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { CurrentCompViewState } from 'contexts/CurrentCompanyViewContext';
import 'styles/accountSettings.scss';
import Billings from '../Payment/Billings';
import TeamCollaboration from '../TeamCollaboration';
import AccountDetails from './AccountDetails';
import EmailConfig from './EmailConfig';
import Apikeys from './Apikeys';
import PayComponent from 'components/Payment/Pay';
import AccountSecurityTab from './AccountSecurity';

// TODO: Convert All Components' files into folders with their stylesheets with them in the same folder
function AccountSettings() {
  const location = useLocation();

  const history = useHistory();

  const { currentCompanyView } = CurrentCompViewState();
  const [data, setData] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [companyInViewUserStatus, setCompanyInViewUserStatus] = useState('admin');
  const plansAndBillingsPathname = '/settings/account/plans';
  const paymentPagePathname = '/payment/pay';
  const ApisPathname = '/settings/account/apikeys';
  const teamCollabPathname = '/settings/account/teams';
  const accountDetailsPathname = '/settings/account/details';
  const emailConfigPathname = '/settings/account/config';
  const accountSecurityPathname = '/settings/account/security';

  const defaultToRequestedTab = () => {
    let defaultActiveKey;

    if (location.pathname === paymentPagePathname) {
      defaultActiveKey = 3;
    } else if (location.pathname === accountDetailsPathname) {
      defaultActiveKey = 1;
    } else if (location.pathname === emailConfigPathname) {
      defaultActiveKey = 2;
    } else if (location.pathname === plansAndBillingsPathname) {
      defaultActiveKey = 3;
    } else if (location.pathname === ApisPathname) {
      defaultActiveKey = 4;
    } else if (location.pathname === teamCollabPathname) {
      defaultActiveKey = 5;
    } else if (location.pathname === accountSecurityPathname) {
      defaultActiveKey = 6;
    }
    return String(defaultActiveKey);
  };

  //   Read Only
  const [tabKeys, _] = useState({
    defaultActiveKey: defaultToRequestedTab(),
    accountDetails: '1',
    emailConfiguration: '2',
    plansAndBillings: '3',
    apis: '4',
    teamCollab: '5',
    accountSecurity: '6',
  });

  const { TabPane } = Tabs;

  useEffect(() => {
    const checkStatus = currentCompanyView?.members.filter((member) => member.email === data?.user.email);
    setCompanyInViewUserStatus(checkStatus[0]?.status);
  }, [currentCompanyView]);

  /***Redirect to default tab */
  if (location.pathname === '/settings' || location.pathname === '/settings/account')
    return <Redirect to="/settings/account/details" />;

  const PaymentOrAccountSettingsJSX = () => {
    if (location.pathname == paymentPagePathname) {
      return <PayComponent />;
    } else {
      return <AccountSettingsJSX />;
    }
  };
  const AccountSettingsJSX = () => {
    if (location.pathname === accountDetailsPathname) {
      return <AccountDetails />;
    } else if (location.pathname === emailConfigPathname) {
      return <EmailConfig />;
    } else if (location.pathname === plansAndBillingsPathname) {
      return <Billings />;
    } else if (location.pathname === ApisPathname) {
      return <Apikeys />;
    } else if (location.pathname === teamCollabPathname) {
      return <TeamCollaboration />;
    } else if (location.pathname === accountSecurityPathname) {
      return <AccountSecurityTab />;
    }
  };

  return (
    <div>
      <div className="emmaccen">
        <div className="accountSettings">
          <Tabs
            onTabClick={(key) => {
              if (key === tabKeys.accountDetails) {
                history.push(accountDetailsPathname);
              } else if (key === tabKeys.emailConfiguration) {
                history.push(emailConfigPathname);
              } else if (key === tabKeys.plansAndBillings) {
                history.push(plansAndBillingsPathname);
              } else if (key === tabKeys.apis) {
                history.push(ApisPathname);
              } else if (key === tabKeys.teamCollab) {
                history.push(teamCollabPathname);
              } else if (key === tabKeys.accountSecurity) {
                history.push(accountSecurityPathname);
              }
            }}
            defaultActiveKey={tabKeys?.defaultActiveKey}
            onChange={() => {}}
          >
            <TabPane tab="Account Details" key={tabKeys.accountDetails}>
              <PaymentOrAccountSettingsJSX />
            </TabPane>
            {companyInViewUserStatus === 'admin' && (
              <>
                <TabPane tab="Email Configuration" key={tabKeys.emailConfiguration}>
                  <PaymentOrAccountSettingsJSX />
                </TabPane>
                <TabPane tab="Plan & Billings" key={tabKeys.plansAndBillings}>
                  <div className="mt-5">
                    <PaymentOrAccountSettingsJSX />
                  </div>
                </TabPane>
              </>
            )}
            <TabPane tab="API's" key={tabKeys.apis}>
              <PaymentOrAccountSettingsJSX />
            </TabPane>
            <TabPane tab="Teams" key={tabKeys.teamCollab}>
              <PaymentOrAccountSettingsJSX />
            </TabPane>
            <TabPane tab="Account Security" key={tabKeys.accountSecurity}>
              {companyInViewUserStatus === 'admin' && <PaymentOrAccountSettingsJSX />}
            </TabPane>{' '}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
