import axios from 'axios';
import Cookies from 'js-cookie';
import { appHistory } from '../App';
import { showToastMessage } from '../utils/Toast';
import { errorMessage } from './getErrors';
const API = process.env.REACT_APP_API_URL;

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error && error.response && error.response.status === (401 || 403 ) )
//       // remove token so we can re-login
//       Cookie.remove("token");
//     // we do this so the navBar doesn't show profile details
//     localStorage.removeItem("userDetails");
//     // go back to login page
//     appHistory.replace("/login");
//     return Promise.reject(error);
//   }
// );

export function createEmail(
  force,
  campaignId,
  typeOfMail,
  subject,
  body,
  attachments,
  emailInterval,
  duration,
  number,
  designCode,
  imageURL
) {
  let payload;
  if (designCode) {
    payload = {
      force,
      typeOfMail,
      subject,
      body,
      emailInterval,
      duration,
      number,
      designCode,
      imageURL,
    };
  } else {
    payload = {
      force,
      typeOfMail,
      subject,
      body,
      attachments,
      emailInterval,
      duration,
      number,
    };
  }
  return axios.post(`/campaigns/${campaignId}/addmail`, payload);
}

export const createCampaignMail = async (
  { subject, body, typeOfMail, emailInterval, attachments, order },
  campaignId
) =>
  axios.post(`/campaigns/${campaignId}/addmail`, {
    subject,
    body,
    typeOfMail,
    emailInterval,
    attachments,
    order,
  });
export async function me() {
  return axios.get(`/users/me`).then((response) => {
    localStorage.setItem('userDetails', JSON.stringify(response.data.data));
    return response.data.data;
  });
}

export async function getCredentials() {
  return axios.get(`/users/credentials`);
}

export function uploadFiles(files) {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append(file.name, file);
  });

  return axios.post(`/campaigns/attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function uploadFile(file, id) {
  const formData = new FormData();
  formData.append('attachment', file);
  formData.append('ID', id);

  return axios.post(`/campaigns/upload-attachment`, formData, {
    headers: {
      id,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getCampaigns(search) {
  if (search.date) {
    const { date } = search;
    return new Promise((res, rej) => {
      axios
        .get(`/campaigns`, {
          params: {
            date,
          },
        })
        .then((response) => res(response.data.data.campaigns));
    });
  } else {
    return new Promise((res, rej) => {
      axios
        .get(`/campaigns`, {
          params: {
            search,
          },
        })
        .then((response) => res(response.data.data.campaigns));
    });
  }
}
export async function fetchCampaigns(action = '', payload, loading, ...setters) {
  if (action === 'search') {
    const {
      data: {
        data: { campaigns: campaigns },
      },
    } = await axios.get(`/campaigns?search=${payload}`);
    setters.forEach((setter) => {
      setter(campaigns);
    });
    loading.cancelLoading();
  } else {
    const {
      data: {
        data: { campaigns: campaigns_1 },
      },
    } = await axios.get(`/campaigns`);
    setters.forEach((setter_1) => {
      setter_1(campaigns_1);
    });
    loading.cancelLoading();
  }
}

export function getCampaign({ campaignId }) {
  return new Promise((res, rej) => {
    axios.get(`/campaigns/${campaignId}`).then((response) => res(response.data.data));
  });
}

export function deleteCampaign(campaignId) {
  return axios.delete(`/campaigns/${campaignId}`);
}

export function editEmail(
  campaignId,
  { body, subject, id, attachments, emailInterval, duration, number, designCode, img }
) {
  let payload;
  if (designCode) {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('designCode', designCode);
    formData.append('emailInterval', emailInterval);
    formData.append('duration', duration);
    formData.append('number', number);
    formData.append('image', img);

    payload = formData;
  } else {
    payload = {
      subject,
      body,
      attachments,
      emailInterval,
      duration,
      number,
    };
  }
  return axios.patch(`/campaigns/${campaignId}/${id}`, payload);
}

export function editEmails(campaignId, emailId, payload) {
  return axios.patch(`/campaigns/${campaignId}/${emailId}`, payload);
}
export function getSingleEmailById(campaignId, emailId) {
  return axios.get(`/campaigns/${campaignId}/${emailId}`);
}

export function getSingleCampaignInformation(campaignId) {
  return axios.get(`/campaigns/${campaignId}/get-data`);
}

export function reorderEmails(campaignId, emails) {
  return axios.post(`/campaigns/${campaignId}/reorder`, { emails });
}

// TODO - Delete attachement by ID
export function deleteAttachment(url) {
  return axios.delete(`/attachments?url=${url}`);
}

export function createCampaign(payload) {
  return axios.post(`/campaigns`, payload);
}

export function deleteCampaignUser(campaignId, userId) {
  return axios.delete(`/campaigns/${campaignId}/${userId}/user`);
}

export function editInterval(campaignId, payload) {
  return axios.post(`/campaigns/${campaignId}/interval`, payload);
}

export function addRecepientsToCampaign(recepients, campaignId) {
  return Promise.all(
    recepients.map(({ name, email }) => {
      return axios.post(`/campaigns/${campaignId}/user`, {
        name,
        email,
      });
    })
  );
}

export function addRecepientToCampaign(payload, campaignId) {
  return axios.post(`/campaigns/${campaignId}/user`, payload);
}

export function editVariables(variables, campaignId) {
  return axios.put(`/campaigns/${campaignId}/variables`, variables);
}
export function deleteVariable(campaignId, name) {
  return axios.delete(`/campaigns/${campaignId}/variables?name=${name}`);
}

export function getVariables(campaignId) {
  return axios.get(`/campaigns/${campaignId}/variables`);
}

export function setCampaignStatus(status, campaignId) {
  return axios.post(`/campaigns/${campaignId}/status`, { status });
}

export function EmailSettings(payload) {
  return axios.patch(`/users/email-settings`, {
    ...payload,
  });
}
export function emailConfiguration(payload, campaignId) {
  return axios.post(`campaigns/${campaignId}/email-configuration`, payload);
}
export function changePassword(payload) {
  return axios.patch(`/users/change-password`, {
    ...payload,
  });
}
export function sendFeedback(payload) {
  return axios.post(`feedbacks`, payload);
}
export function deactivateUser(payload) {
  return axios.patch(`/users/deactivate-account`, {
    ...payload,
  });
}
// contacts

export function createContactGroup(payload) {
  return axios.post('/contacts/groups', payload).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}

export function editContactGroup(groupId, payload) {
  return axios.patch('/contacts/groups/' + groupId, payload).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}
export function getGroupContacts(groupId) {
  return axios.get(`/contacts/group${groupId ? `?groupId=${groupId}` : ''}`).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}
export function editContact(contactId, contactData) {
  return axios.patch(`/contacts/${contactId}`, contactData).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}

export function modifyContactGroup(groupId, contactData) {
  return axios.post(`contacts/modify/${groupId}`, contactData).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}
export function addContactToCampaign({ campaignId, contactId }) {
  return axios
    .get(`/contacts/groups/${campaignId}`, {
      contacts: [contactId],
    })
    .catch((err) => {
      const errMsg = errorMessage(err);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: errMsg,
      });
    });
}
export function createContact(payload) {
  return axios.post('/contacts', payload);
}
export function addNewContactToGroup({ groupId, payload }) {
  return axios.post(`/contacts/?groupId=${groupId}`, payload).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}
export function getAllContactGroups() {
  return axios.get('/contacts/groups').catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}

export function accountSettings(payload) {
  return axios.patch(`/users/account-settings`, {
    ...payload,
  });
}
export function uploadProfilePicture(payload) {
  return axios.post(`/users/upload-photo`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export function getCustomerPortal() {
  return axios.get(`/payment/stripe/create-customer-portal-session`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export function ToggleCampaign(payload) {
  return axios.patch(`/campaigns/toggle-campaign`, payload);
}

export function importBulkRecipient(payload, campaignId) {
  return axios.post(`/campaigns/${campaignId}/add-multiple-users`, payload).catch((err) => {
    const errMsg = errorMessage(err);
    showToastMessage({
      type: 'error',
      title: 'Error',
      description: errMsg,
    });
  });
}

export function createTemplates(payload) {
  return axios.post(`/templates`, payload);
}

export function createLayouts(payload) {
  return axios.post(`/templates/layout`, payload);
}

export function getLayoutsRequest() {
  return axios.get(`/templates/layout/all`);
}

export function getTemplatesRequest() {
  return axios.get(`/templates`);
}

export function deleteTemplatesRequest(id) {
  return axios.delete(`/templates/${id}`);
}

export async function getUserTemplatesRequest(page, pageSize) {
  return axios.get(`/templates/user-template?page=${page}&pageSize=${pageSize}`);
}
export async function createUserTemplatesRequest(payload) {
  return axios.post(`/templates/user-template`, payload);
}
export async function editUserTemplatesRequest(payload) {
  return axios.patch(`/templates/user-template`, payload);
}
export function getRecipientDetailRequest(id, recipientEmail) {
  return axios.post(`/campaigns/${id}/recipient-emails`, { recipientEmail });
}

export function postPublishPageRequest(payload, campaignId) {
  return axios.put(`/campaigns/${campaignId}/join-campaign-page`, payload);
}

export function getPublishPageRequest(campaignId) {
  return axios.get(`/campaigns/${campaignId}/join-campaign-page`);
}

export function guestJoinCampaign(payload, campaignId) {
  return axios.post(`/campaigns/${campaignId}/join-as-guest`, payload);
}

export function getPlansRequest() {
  return axios.get(`/plans`);
}

export function createOrderRequest(payload) {
  return axios.post(`/payment/stripe/initialize`, payload);
}

export function saveCardRequest(id) {
  return axios.get(`/payment/stripe/save-card`);
}
export function resendEmailVerificationRequest({ email }) {
  return axios.post(`/users/resend-verification-link`, { email });
}
export function uploadMarkdownImage(file) {
  return axios.post(`/resources/upload`, file);
}
export function sendTestMail(testMail, campaignId) {
  return axios.post(`/campaigns/${campaignId}/send-test-mail`, testMail);
}
export const unsubscribeFromCampaign = (email, token, campaginId) => {
  return axios.post(`/recipients/unsubscribe?token=${token}}&email=${email}}&campaignId=${campaginId}`);
};

export function createCustomer() {
  return axios.get(`/payment/stripe/create-customer`);
}

export function createSubscription(body) {
  return axios.post(`/payment/stripe/subscription/create`, body);
}
export function createPaymentIntent(body) {
  return axios.post(`/payment/stripe/payment-intent/create`, body);
}

export function topupAccount(body) {
  return axios.post('/payment/stripe/topup', body);
}

export function validatePaymentIntent(body) {
  return axios.post(`/payment/stripe/payment-intent/validate`, body);
}

export function getUserCards() {
  return axios.get(`/payment/stripe/customer/cards`);
}

export function saveUserCard(body) {
  return axios.post(`/payment/stripe/customer/cards`, body);
}
export function setDefaultCard(paymentMethod) {
  return axios.patch(`/payment/stripe/customer/cards/`, { paymentMethod });
}

export function deleteUserCard(paymentMethodId) {
  return axios.delete(`/payment/stripe/customer/cards/${paymentMethodId}`);
}

export function getUserPastTransactions() {
  return axios.get(`/payment/transactions`);
}

export function updateSubscription(body) {
  return axios.post(`/payment/stripe/subscription/update`, body);
}

export function cancelSubscription() {
  return axios.patch(`/payment/stripe/subscription/cancel`);
}

export function reactivateSubscription() {
  return axios.patch(`/payment/stripe/subscription/reactivate`);
}

// Email Filters

export const getDraftEmails = ({ campaignId, limit = 15, page = 0, setImLoading }) => {
  return axios.get(`/campaigns/${campaignId}/draft-mails?limit=${limit}&page=${page}`).finally(() => {
    setImLoading({ id: '' });
  });
};
export const getActiveEmails = ({ campaignId, setImLoading }) => {
  return axios.get(`/campaigns/${campaignId}/active-mails`).finally(() => {
    setImLoading({ id: '' });
  });
};
export const getDeletedEmails = ({ campaignId, limit = 15, page = 0, setImLoading }) => {
  return axios.get(`/campaigns/${campaignId}/deleted-mails?limit=${limit}&page=${page}`).finally(() => {
    setImLoading({ id: '' });
  });
};

export const saveDraftEmail = ({ campaignId, mail, setImLoading }) => {
  return axios.post(`campaigns/${campaignId}/save-draft`, mail).finally(() => {
    setImLoading({ id: '' });
  });
};
export const sendToTrash = ({ campaignId, emailId, run }) => {
  return axios.patch(`campaigns/${campaignId}/${emailId}/send-mail-to-trash`).finally(() => {
    run.map((func) => func());
  });
};
export function deleteEmail({ campaignId, emailId, run }) {
  return axios
    .delete(`/campaigns/${campaignId}/${emailId}`)

    .finally(() => {
      run.map((func) => func());
    });
}

export const restoreMailAction = (restoreType, payload) =>
  restoreType === 'deleted' ? restoreMailToDraft(payload) : restoreMailToEditing(payload);

export const restoreMailToEditing = ({ campaignId, mailId, setImLoading }) => {
  return axios
    .get(`campaigns/${campaignId + '/' + mailId}/restore-mail-to-editing`)
    .then(() => {
      showToastMessage({
        type: 'success',
        title: 'Mail Restored',
        description: 'Mail restored successfully',
      });
    })
    .catch(() => {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to restore mail',
      });
    })
    .finally(() => {
      setImLoading({ id: '' });
    });
};
export const restoreMailToDraft = ({ campaignId, mailId, setImLoading }) => {
  return axios
    .patch(`campaigns/${campaignId + '/' + mailId}/restore-mail-to-draft`)
    .then(() => {
      showToastMessage({
        type: 'success',
        title: 'Mail Restored',
        description: 'Mail restored successfully',
      });
    })
    .catch(() => {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to restore mail',
      });
    })
    .finally(() => {
      setImLoading({ id: '' });
    });
};

export const getEmailAnalytics = ({ emailId, setImLoading }) => {
  return axios
    .get(`/analytics/email/${emailId}`)
    .catch(() => {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to get analytics',
        duration: 0,
      });
    })
    .finally(() => {
      setImLoading({ id: '' });
    });
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['x-access-token'] = token;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.baseURL = API + '';
  } else {
    delete axios.defaults.headers.common['x-access-token'];
  }
};

const clearUserData = (route = '/login', reroute = true) => {
  localStorage.removeItem('userDetails');
  Cookies.remove('token');
  reroute && appHistory.replace(route);
};

const isTokenExpired = (token) => {
  const authToken = token.split('.');
  const expiry = new Date(atob(authToken[1])).exp * 1000;
  const today = new Date();
  return today >= expiry;
};

export const handleRouteAuthStatus = ({ cancelLoading }, { showMessage }) => {
  if (Cookies.get('token')) {
    // we have a user token
    isTokenExpired(Cookies.get('token')) &&
      (() => {
        showMessage('Session Expired');
        clearUserData();
      })();
    cancelLoading();
  } else {
    //no previous login/activity
    showMessage('Please, login 😊');
    clearUserData();
    cancelLoading();
  }
};

export const handleBasicAuth = ({ cancelLoading }, { showMessage }) => {
  axios.defaults.baseURL = API;
  //   check if we have auths in the first place
  if (Cookies.get('token')) {
    // we have a user token
    const token = Cookies.get('token');
    isTokenExpired(token)
      ? (() => {
          showMessage('Session Expired');
          clearUserData();
        })()
      : setAuthToken(token);
    cancelLoading();
  } else {
    //no previous login/activity
    // showMessage("Please, login 😊");
    clearUserData('', false);
    cancelLoading();
  }
};
