import 'antd/dist/antd.css';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.css';
import AccountSettingsPage from './components/Account/index';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import JoinACampaign from './components/JoinACampaign';
import Layout from './components/Layout';
import { AllTemplates } from './pages/All-templates';
import * as Auth from './pages/auth';
import { VerifyUser } from './pages/auth/Verify-user';
import { BlankLayouts } from './pages/blank-layouts';
import CampaignEmails from './pages/Campaign-Emails';
import CampaignHome from './pages/Campaigns';
import { CardSuccessPage } from './pages/card-success-page';
import { DocumentEditor } from './pages/documentEditor/DocumentEditor';
import { Embed } from './pages/embed';
import { EnterMailDetails } from './pages/EnterMailDetails';
import ConfirmMailTemplate from './pages/grapeEditor/ConfirmMailTemplate';
import GrapeEditor from './pages/grapeEditor/Editor';
import SubjectOfMail from './pages/grapeEditor/SubjectOfMail';
import { HelpCenter } from './pages/HelpCenter';
import * as Pages from './pages';
import { Homepage } from './pages/Homepage.js';
import { ImportRecIpient } from './pages/Import-recipient';
import { PastTemplates } from './pages/past-templates';
import { PaymentSuccessPage } from './pages/payment-success-page';
import { Pricingpage } from './pages/Pricing.js';
import { RecipientDetails } from './pages/RecipientDetails';
import { handleBasicAuth } from './utils/service';
import { showToastMessage } from './utils/Toast';
import { FeedbackForm } from './components/emmaccen/sharedComponents/FeedbackFrom';
import CurrentCompanyViewContext from './contexts/CurrentCompanyViewContext';
import PaymentContext from './contexts/payment';
export const AppContext = React.createContext();

export const appHistory = createBrowserHistory();

const queryCache = new QueryCache();

const App = () => {
  const [loadingApplication, setLoadingApplication] = useState(true);
  useEffect(() => {
    handleBasicAuth(
      {
        cancelLoading: () => setLoadingApplication(false),
      },
      {
        showMessage: (message) =>
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: message,
          }),
      }
    );
  }, []);

  return (
    !loadingApplication && (
      <AppContext.Provider>
        <PaymentContext>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <Router history={appHistory}>
              <FeedbackForm />
              <Switch>
                <Route path="/signup" component={Auth.SignUpPage} />
                <Route path="/login" component={Auth.LoginPage} />
                <Route path="/verify" component={VerifyUser} />
                <Route path="/payment-success" component={PaymentSuccessPage} />
                <Route path="/card-success" component={CardSuccessPage} />
                <Route path="/join/:campaignId" component={JoinACampaign} />
                <Route path="/reset-password/:hash" component={Auth.ResetPasswordPage} />
                <Route path="/unsubscribe" component={Auth.Unsubscribe} />
                <Route path="/forgot-password" component={Auth.ForgotPasswordPage} />
                <ProtectedRoute
                  path="/campaign/:campaignId/:campaignMailId/web-builder"
                  component={GrapeEditor}
                  includeLayout={false}
                />
                <CurrentCompanyViewContext>
                  <Layout>
                    {/* <Route path="/home" component={Homepage} />
                    <Route path="/pricing" component={Pricingpage} />
    <Route path="/helpCenter" component={HelpCenter} /> */}
                    <Switch>
                      <ProtectedRoute path="/whats-new" component={Pages.WhatsNew} />
                      <ProtectedRoute path="/contacts" includeLayout={false} component={Pages.ContactPage} />
                      <ProtectedRoute
                        path="/campaign/:campaignId/text-editor"
                        component={DocumentEditor}
                        includeLayout={false}
                      />

                      <ProtectedRoute path="/payment/pay" component={Pages.PaymentPage} includeLayout={false} />

                      <ProtectedRoute
                        path="/campaign/:campaignId/mail-subject"
                        component={SubjectOfMail}
                        includeLayout={false}
                      />
                      <ProtectedRoute
                        path="/campaign/:campaignId/:campaignMailId/maildrip-editor"
                        component={GrapeEditor}
                      />
                      <ProtectedRoute
                        path="/campaign/:campaignId/:campaignMailId/mailtemplate-confirm"
                        component={ConfirmMailTemplate}
                        includeLayout={false}
                      />
                      <ProtectedRoute includeLayout={false} path="/settings" component={AccountSettingsPage} />
                      <ProtectedRoute path="/" exact component={() => <Redirect to="/campaigns" />} />
                      <ProtectedRoute path="/campaigns" exact component={CampaignHome} />
                      <ProtectedRoute
                        path="/campaign/:campaignId/recipient-details/:mail"
                        component={RecipientDetails}
                      />
                      <ProtectedRoute path="/campaign/:campaignId/import-recipient" component={ImportRecIpient} />
                      <ProtectedRoute path="/campaign/:campaignId/" component={CampaignEmails} includeLayout={false} />
                      <ProtectedRoute path="/campaign/:campaignId/past-emails" component={PastTemplates} />
                      <ProtectedRoute path="/campaign/:campaignId/layouts" component={BlankLayouts} />
                      <ProtectedRoute path="/campaign/:campaignId/templates" component={AllTemplates} />
                      <ProtectedRoute path="/campaign/:campaignId/enter-details" component={EnterMailDetails} />

                      <ProtectedRoute path="/embed" component={Embed} />
                    </Switch>
                  </Layout>
                </CurrentCompanyViewContext>
              </Switch>
            </Router>
          </ReactQueryCacheProvider>
        </PaymentContext>
      </AppContext.Provider>
    )
  );
};

export default App;
