import React, { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import './style.scss';
import { ReactComponent as AdvancedIcon } from 'asset/Icons/advanced.svg';
import { ReactComponent as PremiumIcon } from 'asset/Icons/premium.svg';
import { planInverval, trxType } from 'utils/constants';
import { paymentContext } from 'contexts/payment';
import { SET_PLAN, SET_PRICE, SET_TRX_TYPE } from 'contexts/payment/reducer/types';
import { thousandFormatter, thousandFormatterFloat } from 'utils/thousandFormatter';

export default function PlansSection() {
  const { state, dispatch } = useContext(paymentContext);

  const isOdd = (num) => {
    return num % 2;
  };

  const filterPlansByInterval = (interval) => {
    // Delete Free plan
    return state.plans.filter((p) => p.interval === interval && p.priceInNaira > 0).sort((a, b) => a.limit - b.limit);
  };

  const selectPlan = (plan) => {
    dispatch({ type: SET_PLAN, payload: { ...plan, payg: false } });
    dispatch({ type: SET_PRICE, payload: plan.priceInNaira });
    dispatch({ type: SET_TRX_TYPE, payload: trxType.subscription });
  };

  const [plans, setPlans] = useState(filterPlansByInterval(planInverval.monthly));
  const [planIntervalTab, setPlanIntervalTab] = useState(0);

  useEffect(() => {
    if (planIntervalTab) {
      setPlans(
        filterPlansByInterval(planInverval.yearly).map((p) => {
          p.display_price = p.priceInNaira / 12;
          return p;
        })
      );
    } else {
      setPlans(
        filterPlansByInterval(planInverval.monthly).map((p) => {
          // Set display price
          p.display_price = p.priceInNaira;
          return p;
        })
      );
    }
  }, [planIntervalTab]);

  // Select first plan by default
  useEffect(() => {
    if (plans[0]) {
      selectPlan(plans[0]);
    }
  }, [plans]);

  const PlanSelectionCapsule = ({ i, index }) => {
    const isChosenPlan = state.plan._id == i._id;
    return (
      <div className={`plan_box ${isChosenPlan ? 'selected' : ''}`} onClick={() => selectPlan(i)}>
        <div className="header flex-space-btw ">
          <div className="flex items-center">
            {!isOdd(index) ? <AdvancedIcon /> : <PremiumIcon />}
            <p className={`palnTitle ${!isOdd(index) ? 'palntitle__even' : 'palntitle__odd'}`}>{i.planType}</p>
          </div>
          <Checkbox checked={isChosenPlan} />
        </div>
        <div className="flex items-center gap-1">
          <h3 className="amount">{thousandFormatter(i.limit)} </h3>
          <p className="planDesc">Credits</p>
        </div>
        <div className="flex price">
          <h4>₦{thousandFormatterFloat(i.display_price)}</h4> / <span>mo</span>
        </div>
      </div>
    );
  };
  return (
    <div className="paymentDetailsSection">
      <div className="flex items-center">
        <div
          className={`capsule flex gap-4 ${!planIntervalTab ? 'selected' : ''}`}
          onClick={() => setPlanIntervalTab(0)}
        >
          <p>Pay Monthly</p>
          <Checkbox checked={!planIntervalTab} />
        </div>
        <div
          className={`capsule flex gap-4 ${planIntervalTab ? 'selected' : ''}`}
          onClick={() => setPlanIntervalTab(1)}
        >
          <p>
            Pay Yearly <span className="capsule_discount">(25%)</span>
          </p>
          <Checkbox checked={planIntervalTab} />
        </div>
      </div>

      <div className={`planSelectionContainer gridItem-2 ${Boolean(state.plan._id) ? 'selected' : ''}`}>
        {plans.map((p, index) => (
          <PlanSelectionCapsule index={index} i={p} key={p._id} />
        ))}
      </div>
    </div>
  );
}
