import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Collapse, Form, Input, Select, Table, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import emptyTableIcon from '../asset/images/profleimg1.svg';
import charisolLogo from '../asset/images/profleimg2.svg';
import defaultProfileImg from '../asset/images/profleimg3.svg';
import '../styles/teamCollab.scss';
import SingleTeamSection from './SingleTeamSection';
import Modal from './Modal';
import { me } from '../utils/service';
import LoadingScreen from './LoadingScreen';
import { CurrentCompViewState } from '../contexts/CurrentCompanyViewContext';
import { apostropheLastLetter } from '../utils/generics';

const TeamCollaboration = () => {
  const location = useLocation();
  const history = useHistory();
  const membersOfAccount = '/settings/account/teams';
  const teamsOfAccount = '/settings/account/teams?account-teams';
  const [userdata, setUserdata] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const defaultToRequestedTab = () => {
    let defaultActiveKey = 1;
    if (location.pathname + location.search === teamsOfAccount) {
      defaultActiveKey = 2;
    }
    return defaultActiveKey.toString();
  };

  const [tabKeys] = useState({
    defaultActiveKey: defaultToRequestedTab(),
    membersOfAccount: '1',
    teamsOfAccount: '2',
  });
  const [currentTabKey, setCurrentTabKey] = useState('1');
  const [modalActive, setModalActive] = useState(false);
  const [modalTabInView, setModalTabInView] = useState(null);
  const [companyInViewUserStatus, setCompanyInViewUserStatus] = useState('admin');
  const [addMemberForm] = Form.useForm();
  const [createTeamForm] = Form.useForm();
  const [failedPass, setFailedPass] = useState(false);

  const { TabPane } = Tabs;
  const { Option } = Select;
  const { Panel } = Collapse;
  const { currentCompanyView } = CurrentCompViewState();

  const getProfile = async () => {
    let data = await me();
    setUserdata(data);
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    const checkStatus = currentCompanyView?.members.filter((member) => member.email === userdata?.user.email);
    setCompanyInViewUserStatus(checkStatus[0].status);
  }, [currentCompanyView]);

  const closeModalState = () => {
    setModalActive(false);
  };

  const addMemberModalHandler = () => {
    if (currentTabKey === '1') {
      setModalTabInView('add-member');
    } else {
      setModalTabInView('create-team');
    }
    setModalActive(true);
  };

  const onFinish = async (values) => {
    console.log({ values });
    setModalActive(false);
    addMemberForm.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    setFailedPass(false);
    errorInfo.errorFields.map((val) => {
      console.log({ val });
      if (val.name[0] === 'email') {
        setFailedPass(true);
        return;
      }
    });
  };
  const columns = [
    {
      title: 'Img',
      dataIndex: 'img',
      key: 'img',
      width: 60,
      render: (text) => <img src={text} alt="" />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text === 'admin' ? (
          <div className="admin_team_select_option">
            <Select defaultValue={text} style={{ width: 133 }} bordered={false}>
              <Option value="admin">
                <div>Owner / Admin</div>
                <p>
                  Full access to the account and can edit teams, add or remove, modify access level for team members
                </p>
              </Option>
            </Select>
          </div>
        ) : (
          <Select defaultValue={text} style={{ width: 120 }} bordered={false}>
            <Option value="coAdmin">
              <div>Co-Admin</div>
              <p>Can only edit teams, add or remove, modify access level for team members</p>
            </Option>
            <Option value="member">
              <div>Member</div>
              <p>Can only edit, create and share emails & campaigns assigned to</p>
            </Option>
            <Option value="removeUser">
              <p className="removeUser_from_team">Remove from Organization</p>
            </Option>
          </Select>
        ),
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <a>Invite {record.name}</a>
    //       <a>Delete</a>
    //     </Space>
    //   ),
    // },
  ];

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i} value={i.toString(36) + i}>
        {i.toString(36) + i}
      </Option>
    );
  }

  const createTeamHandle = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className="boomshakar">
      {!process.env.REACT_APP_TEAMCOLLAB_FEATURE ? (
        <LoadingScreen loaderTitle={'Coming soon...'} />
      ) : companyInViewUserStatus === 'member' ? (
        <div className="company_view-member">
          <span>
            Your email <i>{userdata?.user.email}</i>&nbsp;&nbsp;is currently a team member in this workspace. If you
            need admin access in this workspace, contact{' '}
            <a href={`mailto:${currentCompanyView.email}`}>{currentCompanyView.email}</a>
          </span>
          <div>
            <span>
              Clicking this button will remove you from <b>{apostropheLastLetter(currentCompanyView.name)}</b> workspace
            </span>
            <button>Leave workspace</button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex">
            <Input placeholder="Search members" prefix={<SearchOutlined />} allowClear />
            <button onClick={addMemberModalHandler} className="add-member_team-btn">
              {currentTabKey === '1' ? <span>Add member +</span> : <span>Create a team</span>}
            </button>
          </div>
          <Tabs
            onTabClick={(key) => {
              if (key === tabKeys.teamsOfAccount) {
                history.push(teamsOfAccount);
              } else if (key === tabKeys.membersOfAccount) {
                history.push(membersOfAccount);
              }
              setCurrentTabKey(key);
            }}
            defaultActiveKey={tabKeys?.defaultActiveKey}
            onChange={() => null}
          >
            <TabPane tab={`Member (${currentCompanyView?.members.length})`} key={tabKeys.membersOfAccount}>
              <div>
                <Table columns={columns} dataSource={currentCompanyView?.members} />
              </div>
            </TabPane>
            <TabPane tab="Teams (3)" key={tabKeys.teamsOfAccount}>
              <SingleTeamSection />
            </TabPane>
          </Tabs>
          <Modal
            isActive={modalActive}
            onCancel={closeModalState}
            title={
              modalTabInView === 'create-team'
                ? 'Create Team'
                : `Invite team members to ${userdata?.user?.companyName || userdata?.user.fullname}`
            }
          >
            <div className="p-6">
              <Form form={addMemberForm} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                {modalTabInView === 'create-team' ? (
                  <Form.Item
                    label="Name"
                    name="name"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Name of team must be provided',
                      },
                    ]}
                  >
                    <Input placeholder="Create a team" type="text" />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Email"
                    name="email"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Email must be provided',
                      },
                    ]}
                  >
                    <Input placeholder="example@abc.com" type="email" />
                  </Form.Item>
                )}
                {modalTabInView === 'create-team' ? (
                  <Form.Item label="Select team members" name="teammembers">
                    <Select
                      mode="tags"
                      style={{ width: '100%', marginBottom: '20px' }}
                      placeholder="Select team members"
                      onChange={createTeamHandle}
                    >
                      {children}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item label="Note" name="note" requiredMark="optional">
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                  </Form.Item>
                )}
                <div className="flex justify-between">
                  <Button
                    onClick={() => setModalActive(false)}
                    className="mr-3 border"
                    style={{
                      background: 'transparent',
                      color: '#000',
                      border: '1px solid #E3E3E3',
                      borderRadius: '4px',
                      // padding: '8px 26px',
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: '#273df2',
                      color: '#fff',
                      borderRadius: '4px',
                      border: '1px solid #273df2',
                      // padding: '8px 26px',
                    }}
                  >
                    Done
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default TeamCollaboration;
