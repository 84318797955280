import { ExpandAltOutlined, PaperClipOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

export const EmailPreview = ({ preview }) => {
  const [isFullMode, setIsFullMode] = useState(false);
  const [appUser, setUserDetails] = useState({});

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('userDetails'))) {
      const { user } = JSON.parse(localStorage.getItem('userDetails'));
      setUserDetails(user);
    }
  }, []);
  return (
    <div className="emmaccen">
      <div className="emailPreviewContainer">
        {isFullMode && (
          <div
            className="fullPreviewModeContainer centered"
            onClick={(e) => {
              setIsFullMode(false);
            }}
          >
            <div className="previewFullMode">
              <h1 className="previewTitle">{preview.subject}</h1>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                dangerouslySetInnerHTML={{ __html: preview?.body }}
              ></div>
              <div className="flex files">
                {preview?.attachments?.map((attachment, index) => (
                  <div
                    title={attachment.filename}
                    onClick={() => window.open(attachment.src, '_blank')}
                    className="content"
                    key={index}
                  >
                    <PaperClipOutlined className="brownishColor" />
                    <h1>{attachment.filename}</h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="campaignePreview">
          <div className="flex justify-end">
            <ExpandAltOutlined className="pointer mb-1" onClick={() => setIsFullMode(true)} />
          </div>
          <div className="userProfile">
            <div className="flex">
              <div className="centered">
                <img src={appUser.picture ?? '/assets/avatar.svg'} alt="user" className="userProfile"></img>
              </div>
              <div>
                <p className="title">{appUser?.name}</p>
                <p className="fade3">{appUser?.email}</p>
              </div>
            </div>
          </div>
          <div className="margin-t-b">
            <h3 className="fade3 subject">Subject:</h3>
            <h2 className="bold title previewText overflow-hidden">{preview?.subject}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: preview?.body }}></div>
          <div className="margin-t-b">
            {preview?.attachments?.length > 0 ? (
              <div className="bold items-center flex">
                <PaperClipOutlined className="brownishColor" /> <p>{preview?.attachments?.length} Attachment(s)</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
