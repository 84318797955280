import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { DownOutlined, LoadingOutlined, PaperClipOutlined, WarningOutlined, EyeOutlined } from '@ant-design/icons';
import '../../../styles/modals.scss';
import { getSingleGroup, addNewContactToGroup } from '../../../utils/service';
import CSVReader from '../../CSVReader';
import { containAzDchar, maxDynamicChar, minDynamicChar } from 'utils/formRules';

export const CreateContactFromGroup = ({ imVisible, setImVisible, contactGroups, currentGroup, setRefresh }) => {
  const [imLoading, setImLoading] = useState({ id: '' });
  const [csvResult, setCsvResult] = useState([]);
  const [contactForm] = Form.useForm();
  const { Search } = Input;
  const { Option } = Select;

  const onSubmitContactDetail = (contact) => {
    // console.log(contact);
    setImLoading({ id: 'creatingContact' });
    console.log({ contact: [{ ...contact }, ...csvResult] });
    let submitWithCSV = false;

    switch (true) {
      case contact.name === undefined:
      case contact.email === undefined:
      case contact.phone === undefined:
      case contact.name === '':
      case contact.email === '':
      case contact.phone === '':
        submitWithCSV = true;

        break;

      default:
        submitWithCSV = false;
        break;
    }

    if (submitWithCSV) {
      addNewContactToGroup({
        groupId: currentGroup._id,
        payload: { contacts: [...csvResult] },
      })
        .then((contact) => {
          // console.log(contact.data.data.contacts[0], contact.data.data.contacts[0]._id);
          // console.log({ asas: contact });
          setRefresh(contact.data.data.contacts[0]?._id);
        })
        .finally(() => {
          setImLoading({ id: '' });
          setImVisible({ id: '' });
        });
    } else {
      addNewContactToGroup({
        groupId: currentGroup._id,
        payload: { contacts: [{ ...contact }, ...csvResult] },
      })
        .then((contact) => {
          // console.log(contact.data.data.contacts[0], contact.data.data.contacts[0]._id);
          setRefresh(contact.data.data.contacts[0]._id);
        })
        .finally(() => {
          setImLoading({ id: '' });
          setImVisible({ id: '' });
        });
    }
  };
  useEffect(() => {
    contactForm.resetFields();
  }, [imVisible]);

  return (
    <Modal
      // title="Delete Email"
      className="emmaccen"
      visible={imVisible.id === 'addNewContactGroup'}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
    >
      <div className="addNewContactModal">
        <h2 className="text-center title">Add new</h2>
        <Form
          form={contactForm}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          initialValues={{
            groups: [currentGroup._id],
          }}
          layout={'vertical'}
        >
          {/* <label>Name</label> */}
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                // required: true, message: 'Please, provide a name!'
              },
              ...containAzDchar,
            ]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>

          {/* <label>Email address</label> */}
          <Form.Item
            label="Email address"
            name="email"
            rules={[
              {
                // required: true,
                // message: 'Please, provide an email!',
                // type: 'email',
              },
            ]}
          >
            <Input type="email" placeholder="johndoe@person.com" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                // required: true,
                // message: 'Please, provide a valid phone number!',
                // type: 'number',
              },
              ...minDynamicChar(7),
              ...maxDynamicChar(15),
            ]}
          >
            <Input type="number" placeholder="080xxx123xx" />
          </Form.Item>
          {/* <label>Add Group</label> */}
          {/* <Form.Item name="groups" label="Add Group">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select Group"
              // defaultValue={[currentGroup._id]}
              onChange={(e) => console.log(e)}
              optionLabelProp="label"
            >
              {contactGroups.map((data) => {
                return (
                  <Option key={data._id ?? data.title} value={data._id} label={data.title}>
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item> */}
          <CSVReader setCSVResult={setCsvResult} />
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={imLoading?.id === 'creatingContact'}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
