import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import '../../../styles/modals.scss';
import { createContactGroup } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';

export const CreateContactGroup = ({ imVisible, setImVisible, setRefresh }) => {
  const [imLoading, setImLoading] = useState({ id: '' });
  const [createContactGroupForm] = Form.useForm();

  const onSubmit = ({ title, description = '' }) => {
    setImLoading({ id: 'creatingNewContactGroup' });
    console.log({ title, description });
    createContactGroup({ title, description })
      .then((data) => {
        console.log(data.data);
        setRefresh(data.data);
        showToastMessage({
          type: 'success',
          title: 'Contact Group',
          description: 'New contact group created',
        });
        createContactGroupForm.resetFields();
      })
      .finally(() => {
        setImVisible({ id: '' });
        setImLoading({ id: '' });
      });
  };

  return (
    <Modal
      wrapClassName="emmaccen"
      visible={imVisible.id === 'createContactGroup'}
      onCancel={() => {
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
    >
      <div className="createContactGroupModal">
        <h2 className="text-center title">Create Group</h2>
        <Form
          form={createContactGroupForm}
          onFinish={(data) => onSubmit(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
        >
          <label>Title</label>
          <Form.Item name="title" rules={[{ required: true, message: 'Please, provide a title!' }]}>
            <Input placeholder="Group Title..." />
          </Form.Item>
          <label>Description</label>
          <Form.Item name="description">
            <Input placeholder="Group description (Optional)..." />
          </Form.Item>
          <p className="hint">You can add recipients to your group later</p>
          <div className="emmaccen">
            <div className="flex-space-btw pad30">
              <Button className="border" size={'large'} type="" onClick={() => setImVisible({ id: '' })}>
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                loading={imLoading?.id === 'creatingNewContactGroup'}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
