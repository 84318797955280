import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../../styles/unsubscribePage.scss';
import { unsubscribeFromCampaign } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
export const Unsubscribe = () => {
  const { token, email, campaignId } = useParams();
  useEffect(() => {
    console.log(token, email, campaignId);
    unsubscribeFromCampaign(token, email, campaignId)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Unsubscribed',
          description: `You've been successfully unsubscribed from this campaign`,
        });
      })
      .catch(() => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Unable to unsubscribe, please try again later',
        });
      })
      .finally(() => {});
  }, []);

  return (
    <div className="emmaccen">
      <div className="unsubscribePageContainer">
        <div className="centered cardContainer">
          <div className="card">
            <h3 className="usePurple textCenter text-xl bold">Unsubscribe</h3>
            <div className="centered m-5 mb-10">
              <img src="/assets/unsubscribe.svg" alt="unsubscribe" />
            </div>
            <p className="paragraph">
              You have successfully unsubscribed. You will no longer recieve emails from this campaign.
            </p>
            <p className="paragraph">
              Unsubscribed by mistake?
              <Link to="#" className="purple ml-2 bold usePurple">
                Subscribe back
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
