import { Modal } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export const CreateNewEmailModal = ({ id, handleModal, isModalVisible }) => {
  return (
    <div>
      <Modal
        title="Choose Email Builder"
        visible={isModalVisible}
        onOk={() => handleModal({ id: '' })}
        onCancel={() => handleModal({ id: '' })}
        footer={null}
      >
        <div className="emmaccen">
          <div className="createNewEmailModal">
            {/* <div className="card mb-5 p-5">
              <Link to={`/campaign/${id}/layouts`}>
                <h3>WEB BUILDER</h3>
                <p className="paragraph">
                  Easily create responsive emails from a layout or template and add your own content
                </p>
              </Link>
            </div> */}
            <div className="card mb-5 p-5">
              <Link to={`/campaign/${id}/mail-subject`}>
                {/* <Link to={`/campaign/${id}/maildrip-editor`}> */}
                <h3>WEB BUILDER</h3>
                <p className="paragraph">
                  Easily create responsive emails from a layout or template and add your own content
                </p>
              </Link>
            </div>
            <div className="card mb-5">
              <Link to={`/campaign/${id}/text-editor`}>
                <h3>RICH TEXT EDITOR</h3>
                <p className="paragraph">
                  Create rich text emails. Includes tables, images, different formatting options and more
                </p>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
