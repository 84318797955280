import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import TransactionLoading from 'asset/Icons/TransactionLoading/TransactionLoading';
import { paymentContext } from 'contexts/payment';

import './style.scss';
import { authorizeTrx } from 'utils/services/paystack';
import {
  SET_DEFAULT_STATE,
  SET_DISPLAY_TEXT,
  SET_MAKING_TRANSACTION,
  SET_TRX_STATUS,
} from 'contexts/payment/reducer/types';
import { failedTrx, PaystackCheckout, VerifyTransaction } from '../CheckoutCard';
import { useHistory } from 'react-router-dom';
import { isTrue } from 'utils/constants';
export default function TransactionPin() {
  const history = useHistory();
  const {
    state: { making_transaction, ongoing_trx_ref },
    dispatch,
  } = useContext(paymentContext);
  const [pin, setPin] = useState('');

  useEffect(() => {
    (async () => {
      if (pin.length === 4) {
        try {
          dispatch({ type: SET_MAKING_TRANSACTION, payload: true });

          const trx = await authorizeTrx({
            pin: pin,
            reference: ongoing_trx_ref,
          });
          console.log('pin_trx', trx.data.data);

          const { transaction } = trx.data.data;

          if (transaction.status === 'success') {
            await VerifyTransaction(ongoing_trx_ref);
            // Delay redirect for 2 seconds so that trx will reflect
            setTimeout(() => {
              dispatch({ type: SET_DEFAULT_STATE });
              history.push('/settings/account/plans');
            }, 2000);
          } else {
            dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
            dispatch({ type: SET_DISPLAY_TEXT, payload: transaction.display_text });
            dispatch({ type: SET_TRX_STATUS, payload: transaction.status });
          }
        } catch (error) {
          console.log({ error });
          dispatch({ type: SET_DEFAULT_STATE });
          failedTrx(error?.data.message);
        }
      }
    })();
  }, [pin]);

  return (
    <PaystackCheckout className={'pinbox_outer'}>
      <div className="pinbox">
        <h2 className="pinbox__header">Please enter your 4-digit card pin to authorize this payment</h2>

        <div className="pin_inputs_wrapper">
          {making_transaction ? (
            <TransactionLoading />
          ) : (
            <OtpInput
              value={pin}
              onChange={(otp) => setPin(otp)}
              numInputs={4}
              isInputNum={true}
              isInputSecure={true}
              inputStyle={{
                borderBottom: '3px solid #E0E2E9',
                fontSize: '32px',
                fontWeight: 600,
                marginRight: '5px',
                width: '40px',
                color: 'black',
              }}
              focusStyle={{
                borderBottom: '3px solid rgba(39, 61, 242, 0.37)',
              }}
            />
          )}
        </div>

        <button className="pinbox_cancel_button" onClick={() => dispatch({ type: SET_DEFAULT_STATE })}>
          Cancel
        </button>
      </div>
    </PaystackCheckout>
  );
}
