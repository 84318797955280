import { ArrowLeftOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as EmptyStateImage } from '../asset/images/empty.svg';
import DateFromNow from '../components/DateFromNow';
import { getRecipientDetailRequest } from '../utils/service';
export const RecipientDetails = () => {
  const { mail, campaignId } = useParams();
  useEffect(() => {
    getRecipientDetails();
  }, []);
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getRecipientDetails = () => {
    setIsLoading(true);
    getRecipientDetailRequest(campaignId, mail)
      .then((response) => {
        setIsLoading(false);
        setDetails(response.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="emmaccen">
        <Link to={`/campaign/${campaignId}/recipients`}>
          <div className="flex align-items-center">
            <ArrowLeftOutlined />
            <span className="ml-2">Back</span>
          </div>
        </Link>
      </div>

      {details.length > 0 ? (
        <>
          <div className="received-mails-wrapper">
            <p className="mb-5">
              Number of mails recieved: <span className="number text-grey">{details.length}</span>
            </p>
            {details.map((each) => (
              <div className="received-mails-card" key={each._id}>
                <div className="subject">{each.subject}</div>
                {each.typeOfMail ? (
                  <>
                    {each.typeOfMail == 'web-builder' ? (
                      <>
                        <div className="body">Web builder template...</div>
                      </>
                    ) : (
                      <div className="body" dangerouslySetInnerHTML={{ __html: each.body }}></div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="body"></div>
                  </>
                )}
                <div className="date">
                  <DateFromNow date={new Date(each.deliveryTime)} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {!isLoading ? (
            <>
              <div className="centerLoadingPage">
                <EmptyStateImage />
              </div>
            </>
          ) : (
            <div className="centerLoadingPage">
              <Spin />
            </div>
          )}
        </>
      )}
    </div>
  );
};
