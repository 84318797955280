import React from 'react';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

export const BreadCrumb = ({ crumbs = [], rightPan, boomContain }) => {
  const sortByCrumbIndex = (a, b) => {
    return a.index - b.index;
  };
  crumbs.sort((a, b) => sortByCrumbIndex(a, b));
  return (
    <div
      className={`${boomContain ? 'boomBreadCrumbContainer' : 'breadCrumbContainer'} ${
        rightPan && 'flex justify-between'
      }`}
    >
      <div className="flex items-center">
        {crumbs.map(({ name, link }) => (
          <Link title={name} key={name} to={link} className="crumb">
            {name.length > 10 ? name.slice(0, 10).concat('...') : name} <RightOutlined />
          </Link>
        ))}
      </div>
      {rightPan}
    </div>
  );
};
