import { Modal } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

export const CardSuccessPage = () => {
  const [successModal, setSuccessModal] = useState(true);
  const closeSuccessModal = () => setSuccessModal(false);
  const history = useHistory();
  const routeToCampaign = () => {
    closeSuccessModal();
    history.push('/campaigns');
  };
  return (
    <div>
      <Modal
        title="Card Success"
        visible={successModal}
        onOk={routeToCampaign}
        onCancel={routeToCampaign}
        footer={[
          <button key="submit" className="btn btn-blue" type="primary" onClick={routeToCampaign}>
            Go To Campaigns
          </button>,
        ]}
      >
        <div className="text-center">
          <p className="text-green flex justify-content-center" style={{ fontSize: '86px' }}>
            <span class="iconify" data-icon="bi:patch-check-fill"></span>
          </p>
          <p className="font18">Success!!!</p>
          <p className="mb-3 font16">
            Your card have been added to your billing information. <br /> Don't worry, you will not be charged unless
            you subscribe to a plan 😊.
          </p>
        </div>
      </Modal>
    </div>
  );
};
