import React, { Children, useContext, useEffect, useState } from 'react';
import Chevron from 'asset/Icons/Chevron';
import { ReactComponent as Lock } from 'asset/Icons/lock.svg';
import { paymentContext } from 'contexts/payment';
import Card from '../Card';
import Form from '../Form';
import TransactionOTP from '../OTP';
import TransactionPin from '../PIN';
import './style.scss';
import PayButton from '../PayButton';
import { SET_CARDS, SET_DEFAULT_STATE, SET_MAKING_TRANSACTION } from 'contexts/payment/reducer/types';
import { createPaygTrx, createSubTrx, createTopupTrx, getPaystackCards, verifyTrx } from 'utils/services/paystack';
import { showToastMessage } from 'utils/Toast';
import { authorizationStep, trxType } from 'utils/constants';
import TransactionPhonenumber from '../PhoneNumber';
import TransactionBirthday from '../Birthday';
import { thousandFormatterFloat } from 'utils/thousandFormatter';
import { useHistory } from 'react-router-dom';

export default function CheckoutCard() {
  return <TransactionComponents />;
}

const TransactionComponents = () => {
  const {
    state: { ongoing_trx_status },
  } = useContext(paymentContext);

  if (ongoing_trx_status == authorizationStep.none) {
    return <PaymentForm />;
  } else if (ongoing_trx_status == authorizationStep.pin) {
    return <TransactionPin />;
  } else if (ongoing_trx_status == authorizationStep.otp) {
    return <TransactionOTP />;
  } else if (ongoing_trx_status == authorizationStep.phone_number) {
    return <TransactionPhonenumber />;
  } else if (ongoing_trx_status == authorizationStep.birthday) {
    return <TransactionBirthday />;
  }
};

const PaymentForm = () => {
  const [cardsAccordion, openCardsAccordion] = useState(false);
  const [formAccordion, openFormAccordion] = useState(true);

  const { state } = useContext(paymentContext);

  const toggleAccordions = () => {
    openCardsAccordion(!cardsAccordion);
    openFormAccordion(!formAccordion);
  };

  return (
    <PaystackCheckout>
      <div>
        <h2 className="payment_form__header">
          You pay: <span>₦{thousandFormatterFloat(state.price)}</span>
        </h2>

        <div className="accordion" onClick={() => toggleAccordions()}>
          <h6>Saved cards</h6>
          <Chevron className={cardsAccordion ? 'chevronFaceUp' : 'chevronFaceDown'} />
        </div>
        {cardsAccordion && <CardsSection toggleAccordions={toggleAccordions} />}

        <div className="accordion new_card__accordion" onClick={() => toggleAccordions()}>
          <h6>New Card</h6>
          <Chevron className={formAccordion ? 'chevronFaceUp' : 'chevronFaceDown'} />
        </div>
        {formAccordion && <Form toggleAccordions={toggleAccordions} />}
      </div>
    </PaystackCheckout>
  );
};

export const PaystackCheckout = ({ children, className = '' }) => {
  return (
    <div className={'payment_form ' + className}>
      {children}
      <div className="paystack_signature">
        <p>Secured by Paystack </p>
        <Lock />
      </div>
    </div>
  );
};

export const CardsSection = ({ toggleAccordions }) => {
  // const [makingTransaction, setMakingTransaction] = useState(true);
  const { state, dispatch } = useContext(paymentContext);
  const history = useHistory();

  const cards = state.cards;
  useEffect(() => {
    (async () => {
      if (!cards.length) {
        const {
          data: { data: cards },
        } = await getPaystackCards();
        dispatch({ type: SET_CARDS, payload: cards });
      }
    })();
  }, []);
  return (
    <div className="saved_cards">
      {!cards.length ? (
        <div className="empty">
          <p>No card saved yet.</p>
          <p> All saved cards will appear here</p>
        </div>
      ) : (
        <>
          {cards.map((card) => {
            return <Card key={card._id} card={card} />;
          })}

          <PayButton
            loading={state.making_transaction}
            onClick={async () => {
              if (+state.price < 100) {
                return showToastMessage({
                  type: 'error',
                  title: 'Invalid Transaction Amount',
                  description: 'Transaction amount is too small. Minimum amount allowed is NGN100',
                });
              }
              try {
                dispatch({ type: SET_MAKING_TRANSACTION, payload: true });

                let trx;
                if (state.transaction_type === trxType.payg) {
                  // PAYG Transaction
                  const payload = {
                    quantity: state.quantity,
                    authCode: state.card.authorization,
                  };
                  trx = await createPaygTrx(payload);
                  console.log('card_payg_trx', trx.data.data);
                } else if (state.transaction_type === trxType.subscription) {
                  const payload = {
                    planId: state.plan.paystackPlanId,
                    authCode: state.card.authorization,
                  };
                  trx = await createSubTrx(payload);
                  console.log({ card_sub_trx: trx.data.data });
                } else if (state.transaction_type === trxType.topup) {
                  const payload = {
                    quantity: state.plan.limit,
                    amount: state.plan.price,
                    authCode: state.card.authorization,
                  };
                  trx = await createTopupTrx(payload);
                  console.log({ card_topup_trx: trx.data.data });
                }
                const { transaction } = trx.data.data;
                await VerifyTransaction(transaction.reference);
                // Delay redirect for 2 seconds so that trx will reflect
                setTimeout(() => {
                  dispatch({ type: SET_DEFAULT_STATE });
                  history.push('/settings/account/plans');
                }, 2000);
              } catch (error) {
                console.log({ error });
                dispatch({ type: SET_DEFAULT_STATE });
                toggleAccordions();
                // TODO: Remove error and leave error.message
                failedTrx(error?.message || error);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export const failedTrx = (message) => {
  showToastMessage({
    type: 'error',
    title: 'Transaction failed',
    description: message,
  });
};

export const VerifyTransaction = async (txref, saveCard = false) => {
  const verifyTrxRes = await verifyTrx(txref, saveCard);
  const isSuccessful = verifyTrxRes.data.data.transaction.status === 'success';
  const isPending = verifyTrxRes.data.data.transaction.status === 'pending';
  const hasFailed = verifyTrxRes.data.data.transaction.status === 'failed';

  if (isSuccessful) {
    showToastMessage({
      type: 'success',
      title: 'Payment Successful',
      description: verifyTrxRes.data.data.message || 'Your payment was successful.',
    });
  } else if (isPending) {
    console.log({ isPending: txref });
    // A very rare occurence according to Paystack but nonetheless needs to be accounted for
    showToastMessage({
      type: 'warning',
      title: 'Verifying Payment',
      description: "Please do not refresh this page. We're processing your payment. This may take up to a minute",
      duration: 15,
    });
    // Re-verify after 10-30 sconds as stated in paystack docs
    setTimeout(async () => await VerifyTransaction(txref, saveCard), 15 * 1000);
  } else if (hasFailed) {
    showToastMessage({
      type: 'error',
      title: 'Payment Unsuccessful',
      description: verifyTrxRes.data.data.message,
    });
  }
};
