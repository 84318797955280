import axios from 'axios';
import Cookie from 'js-cookie';
import { appHistory } from '../../App';
import { me, setAuthToken } from 'utils/service';
import { required, showToastMessage } from 'utils/Toast';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    /** Redirect to login if a 401 status code is returned*/
    if (error?.response?.status === 401) appHistory.replace('/login');
    return Promise.reject(error);
  }
);

export const login = (data) => {
  return axios.post('/users/login', data);
};

export const signUp = (data) => {
  return axios.post('/users/register', data);
};

export const requestReactivation = (email) => {
  return axios.post('/users/reactivate-account', { email });
};

export const emailVerification = (data) => {
  return axios.post('/users/verify', { verificationCode: data });
};

export const forgotPasswordRequest = (data) => {
  return axios.post('/users/forget-password', data);
};
export const resetPasswordRequest = (hash, data) => {
  return axios.post(`/users/reset-password?hash=${hash}`, data);
};

/*************************************
 * HELPER FUNCTIONS
 *
 *************************************/

export const reactivateAccount = async (email = required('token'), history = required('token')) => {
  const res = await requestReactivation(email);
  const { token } = res.data.data;
  showToastMessage({
    type: 'success',
    title: 'Welcome Back🎉🎉',
    description: "We're glad to have you back. Let's pick up from where we stopped!",
  });
  await logUserIn(token, history);
};

export const logUserIn = async (token = required('token'), history = required('history')) => {
  //  Log user in successfully
  Cookie.set('token', token, { expires: 1 });
  setAuthToken(token);
  me()
    .then((result) => {
      history.replace('/campaigns');
    })
    .catch((err) => {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Unable to get user details',
      });
    });
};
