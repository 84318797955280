import classNames from 'classnames';
import Cookies from 'js-cookie';
import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

function Navbar({ links }) {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { campaignId } = useParams();
  return (
    <div className="bg-white rounded-md overflow-scroll  shadow flex justify-content-between align-items-center flex-wrap">
      <div className="flex">
        {links.map((link) => (
          <>
            <Link
              to={link.link}
              className={classNames('px-5 py-3 flex whitespace-no-wrap', {
                'font-bold border-b-2 border-primary': `${pathname}${search}`.indexOf(link.link) !== -1,
              })}
            >
              {link.name}
            </Link>
          </>
        ))}
      </div>
      {campaignId && (
        <div className="p-3">
          <button
            className="btn btn-sm btn-blue"
            onClick={() => {
              history.push({
                pathname: `/join/${campaignId}`,
                state: {
                  isEditing: true,
                },
              });
            }}
          >
            Edit Join page
          </button>
        </div>
      )}
    </div>
  );
}

export default Navbar;
