import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import '../../../styles/modals.scss';
import { editContact, fetchCampaigns, addContactToCampaign } from '../../../utils/service';
import { showToastMessage } from '../../../utils/Toast';

export const EditContact = ({ imVisible, setImVisible, actionableContactData, setRefresh, contactGroups }) => {
  const [imLoading, setImLoading] = useState({ id: '' });
  const [campaginList, setCampaignList] = useState([]);
  const [contactForm] = Form.useForm();
  const { Search } = Input;
  const { Option } = Select;

  // console.log(contactGroups);
  const fetchAllCampaigns = () => {
    setImLoading({ id: 'loadingCampaigns' });
    fetchCampaigns(
      '',
      '',
      {
        cancelLoading: () => setImLoading({ id: '' }),
      },
      setCampaignList
    );
  };

  const onSubmitContactDetail = ({ name, groups = [], campaignId = '' }) => {
    setImLoading({ id: 'editingContact' });
    editContact(actionableContactData._id, { name, groups, campaignId })
      .then((contact) => {
        // console.log(contact);
        setRefresh(contact.data);
        showToastMessage({
          type: 'success',
          title: 'Contact',
          description: 'Contact details updated!',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
        setImVisible({ id: '' });
      });
  };
  useEffect(() => {
    contactForm.resetFields();
  }, [imVisible]);
  return (
    <Modal
      // title="Delete Email"
      className="emmaccen"
      visible={imVisible.id === 'editContact'}
      // onOk={() => handleContactAddition()}
      onCancel={() => {
        contactForm.resetFields();
        setImVisible({ id: '' });
      }}
      footer={null}
      maskClosable={false}
      closable={false}
    >
      <div className="addNewContactModal">
        <h2 className="text-center title">Edit Contact</h2>
        <Form
          form={contactForm}
          initialValues={{ ...actionableContactData, groups: actionableContactData?.groups?.map((c) => c._id) }}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
        >
          <label>Name</label>
          <Form.Item
            // label="Name"
            name="name"
            rules={[{ required: true, message: 'Please, provide a name!' }]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>

          <label>Email address</label>
          <Form.Item
            // label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please, provide an email!',
                type: 'email',
              },
            ]}
          >
            <Input type="email" placeholder="johndoe@person.com" />
          </Form.Item>
          <label>Add to campaign</label>
          <Form.Item
            // style={{ width: '100%' }}
            name="campaignId"
          >
            <Select
              onFocus={() => fetchAllCampaigns()}
              loading={imLoading.id === 'loadingCampaigns'}
              showSearch
              placeholder="Select campaign"
              optionFilterProp="children"
              // onChange={onChange}
              // onSearch={onSearch}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {campaginList.map((camp, i) => (
                <Option key={i} value={camp._id}>
                  {camp.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="groups"
            rules={[
              {
                required: true,
                message: "Contact group can't be empty!",
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select Group"
              onChange={(e) => console.log(e)}
              optionLabelProp="label"
            >
              {contactGroups?.map((data) => {
                return (
                  <Option key={data._id ?? data.title} value={data._id} label={data.title}>
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type=""
                onClick={() => setImVisible({ id: '' })}
                // icon={<PlusOutlined />}
              >
                Back
              </Button>
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                // icon={<PlusOutlined />}
                loading={imLoading?.id === 'editingContact'}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
