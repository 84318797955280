export const onFileDropHandler = (ev, files, setFiles, setIsUploading) => {
  let allFiles = [];
  const storeFiles = (loadedFiles) => {
    setFiles([...files, ...loadedFiles]);
  };

  ev.preventDefault();
  if (ev.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === 'file') {
        let file = ev.dataTransfer.items[i].getAsFile();
        // console.log("... file[" + i + "].name = " + file.name);
        allFiles.push({
          file,
          id: String(Date.now() + Math.random() * 1000),
          src: `${URL.createObjectURL(file)}`,
          status: 'WAITING',
          filename: file.name,
        });
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      const file = ev.dataTransfer.files[i];
      allFiles.push({
        file,
        id: String(Date.now() + Math.random() * 1000),
        src: `${URL.createObjectURL(file)}`,
        status: 'WAITING',
        filename: file.name,
      });
    }
  }
  storeFiles(allFiles);
  setIsUploading(true);
};
