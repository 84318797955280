import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import DownloadIcon from '../asset/Icons/DownloadIcon';
import './style.scss';
import { isUserOnFreePlan } from 'utils/generics';
import { cancelSubscription, getUserPastTransactions, me, reactivateSubscription } from 'utils/service';
import LoadingScreen from '../../LoadingScreen';
import TopupModal from '../TopupModal/index';
import TransactionsTable from '../TransactionsTable';
import { thousandFormatterFloat } from 'utils/thousandFormatter';

export default function Billings() {
  const [userData, setUserData] = useState(null);

  const userPlanName = userData?.userPlan?.planType?.toUpperCase() || 'FREE';
  const [isFreePlan, setIsFreePlan] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openTopupModal, setOpenTopupModal] = useState(false);

  useEffect(() => {
    (async function () {
      setLoading(true);
      const response = await getUserPastTransactions();
      setTransactions(response.data.data);
      const userData = await me();
      setUserData(userData.user);
      setIsFreePlan(isUserOnFreePlan(userData.user));
      setLoading(false);
    })();
  }, []);

  const props = { openModal: openTopupModal, setOpenModal: setOpenTopupModal, setUserData, setTransactions };
  return (
    <>
      {loading ? (
        <LoadingScreen loaderTitle={'Please wait while we perform our magic'} />
      ) : (
        <div className="plansContainer">
          <div className="topupUserPlanWrapper">
            <div className="topupWrapper">
              <h6>Available Unit</h6>

              <div className="availableUnits">
                <p>
                  {userData?.wallet.subBalance || 0}
                  <span className="totalUnits">/{userData?.wallet.subTotalSlots || 0} units</span>
                </p>
              </div>
              <div className="topupCtaWrapper">
                <div className="paygBalance">
                  <h6>PAYG Balance</h6>

                  <p>
                    {userData?.wallet.paygBalance || 0}
                    <span className="totalUnits">/{userData?.wallet.paygTotalSlots || 0} units</span>
                  </p>
                </div>
                {!isFreePlan && <button onClick={() => setOpenTopupModal(true)}>Top-Up</button>}
              </div>
            </div>

            <div className="planWrapper">
              <div className="priceCtaWrapper">
                <div className={`planType ${isFreePlan ? 'free_plan_background' : 'paid_plan_background'}`}>
                  {userPlanName}
                </div>
                <div className="priceWrapper">
                  <div className="priceTag">
                    {/* <span className="currency">$</span> */}
                    <span className="currency">₦</span>
                    <p>{thousandFormatterFloat(userData?.userPlan.priceInNaira)}</p>
                  </div>
                  <div className="cycle">/month</div>
                </div>
              </div>
              <div className="nextPaymentUpdateCtaWrapper">
                <div className="nextPayment">
                  <h6 className="">Next Payment</h6>
                  {/* Use a more specific check that'll be error free */}
                  <p className="expiryDate">
                    on {isFreePlan ? '-- -- ----' : moment(userData?.payment?.expiresIn).format('MMMM DD, YYYY')}
                  </p>
                </div>
                <Link to={'/payment/pay'}>
                  <button>Update</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="billingHistoryTable">
            <h5>Billing history</h5>

            <TransactionsTable loading={loading} transactions={transactions} />
          </div>

          <div className="psa">
            <p>NOTE: We only show up to 1 year of billing history</p>
          </div>

          <TopupModal props={props} />
        </div>
      )}
    </>
  );
}
