import React from 'react';

export default function Chevron({ className }) {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ transition: 'all .1s linear' }}
    >
      <path d="M13 7L7 1L1 7" stroke="#25282B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
