import React from 'react';
import './style.scss';
import CircularSpinner from '../../loaders/CircularSpinner';

export default function PayButton({ loading, onClick, text }) {
  return (
    <div className="">
      <button disabled={loading} onClick={onClick} className="payment__button">
        {loading ? <CircularSpinner className="spinner right-0 mr-5" /> : text || 'Continue'}
      </button>
    </div>
  );
}
