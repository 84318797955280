import { MenuOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(true);

  let navWidthHandler = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth > 680) {
      document.getElementById('mobileNav').style.display = 'flex';
    } else if (windowWidth <= 680) {
      document.getElementById('mobileNav').style.display = 'none';
    }
    return windowWidth;
  };

  useEffect(() => {
    window.addEventListener('resize', navWidthHandler);
    return () => window.removeEventListener('resize', navWidthHandler);
  }, []);
  useEffect(() => {
    const width = window.innerWidth;
    width <= 680 && isOpen
      ? (document.getElementById('mobileNav').style.display = 'none')
      : (document.getElementById('mobileNav').style.display = 'flex');
  }, [isOpen]);
  return (
    <nav className="navBar">
      <div className="content container1 flex-space-btw">
        <div className="mobileController">
          <Link to={{ pathname: 'https://maildrip.io/' }} target="_blank" className="flex">
            <img className="logo" src="/assets/logo.svg" alt="logo"></img>
            <div className="centered">
              <span className="title ml-1 font18">MailDrip.</span>
            </div>
          </Link>
          <div onClick={() => setIsOpen(!isOpen)} className="centered pointer">
            <MenuOutlined />
          </div>
        </div>

        <div id="mobileNav" className="flex-space-btw navLinks">
          {/* <Link to={"/"}>About MailDrip</Link> */}
          <Link to={{ pathname: 'https://maildrip.io/pricing/' }} target="_blank">
            Pricing
          </Link>
          <Link to={{ pathname: 'https://maildrip.io/help/' }} target="_blank">
            How it works
          </Link>
          <Link to={'/login'}>Login</Link>
          <Link to={'/signup'}>
            <button className="btnPrimary">Join Free</button>
          </Link>
        </div>
      </div>
    </nav>
  );
};
