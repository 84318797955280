import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { handleRouteAuthStatus } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';

export const ProtectedRoute = ({ component: Component, path, includeLayout = true, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const { path } = useLocation();
  useEffect(() => {
    handleRouteAuthStatus(
      { cancelLoading: () => setIsLoading(false) },
      {
        showMessage: (description) =>
          showToastMessage({
            type: 'warning',
            title: 'Session',
            description: 'Session Expired',
          }),
      }
    );
  }, []);

  // frequent design changes has caused so much changes to includeLayout logic and now... It's pretty much useless
  // well... pretty much
  return (
    !isLoading && (
      <div className={includeLayout ? `p-5 relative h-full w-full` : ''}>
        <Route
          {...rest}
          render={(props) => {
            return <Component {...rest} {...props} />;
          }}
        />
      </div>
    )
  );
};

export default ProtectedRoute;
