import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';

import { ReactComponent as CheckCircleFilled } from 'asset/Icons/checked.svg';
import Chevron from 'asset/Icons/Chevron';
import MasterCard from 'asset/Icons/MasterCard';
import Visa from 'asset/Icons/Visa';
import './style.scss';
import { getUserCards, getUserPastTransactions, me, topupAccount } from 'utils/service';
import { showToastMessage } from 'utils/Toast';
import Pay from '../../Buttons/PaymentCTA';
import LoadingScreen from '../../LoadingScreen';
import { paymentContext } from 'contexts/payment';
import { CardsSection } from '../CheckoutCard';
import Form from '../Form';
import { SET_PLAN, SET_PRICE, SET_TRX_TYPE } from 'contexts/payment/reducer/types';
import { emailCreditInNaira, trxType } from 'utils/constants';
import { thousandFormatter, thousandFormatterFloat } from 'utils/thousandFormatter';

export default function TopupModal({ props }) {
  const { openModal, setOpenModal, setUserData, setTransactions } = props;
  const [plans, setPlans] = useState([]);
  const [userCards, setUserCards] = useState([]);
  const [fetchingCards, setFetchingCards] = useState(true);
  const [cardsAccordion, openCardsAccordion] = useState(false);
  const [formAccordion, openFormAccordion] = useState(true);

  const toggleAccordions = () => {
    openCardsAccordion(!cardsAccordion);
    openFormAccordion(!formAccordion);
  };

  // TODO: These plans should come from APIs
  const Topups = [
    {
      _id: '1',
      price: 700 * emailCreditInNaira,
      limit: 700,
    },
    {
      _id: '2',
      price: 2000 * emailCreditInNaira,
      limit: 2000,
    },
    {
      _id: '3',
      price: 4500 * emailCreditInNaira,
      limit: 4500,
    },
  ];
  const { state, dispatch } = useContext(paymentContext);
  useEffect(() => {
    (async function () {
      const cardsResponse = await getUserCards();
      setFetchingCards(false);
      const cards = cardsResponse.data.data;
      setUserCards(cards);
      setPlans(Topups);
    })();
  }, []);

  return (
    <>
      <Modal
        style={{ borderRadius: '10px' }}
        width="900px"
        className="topUpWrapper"
        title={''}
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        closable={true}
        centered={true}
      >
        <div className="body">
          <div className="packageWrapper">
            <h5>Top-up Credit</h5>

            <p className="sp">Select a package</p>

            {!plans ? (
              <LoadingScreen
                loaderTitle={'Fetching topup packages. If loading takes longer than 1 minute, kindly refresh this page'}
              />
            ) : (
              plans.map((p) => {
                const isChosenPlan = state.plan._id == p._id;
                return (
                  <div
                    key={p._id}
                    className={`topup ${isChosenPlan && 'selected_plan__border'}`}
                    onClick={() => {
                      dispatch({ type: SET_PLAN, payload: p });
                      dispatch({ type: SET_PRICE, payload: p.price });
                      dispatch({ type: SET_TRX_TYPE, payload: trxType.topup });
                    }}
                  >
                    <div className="slotsWrapper">
                      <p className="slots">{p.limit}</p>
                      <p className="credit">email credits</p>
                    </div>
                    <div className="topupPrice">
                      <p>₦{thousandFormatterFloat(p.price)}</p>
                      <CheckCircleFilled />
                    </div>
                  </div>
                );
              })
            )}

            <div className="microcopy">
              <p>
                <span>*</span>All top-up packages are valid for the existing subscription period
              </p>
            </div>
          </div>

          <div className="formWrapper">
            <h2>You pay: ₦{thousandFormatterFloat(state.price)}</h2>

            <div className="accordion" onClick={() => toggleAccordions()}>
              <h6>Saved cards</h6>
              <Chevron className={cardsAccordion ? 'chevronFaceUp' : 'chevronFaceDown'} />
            </div>
            {cardsAccordion && <CardsSection />}

            <div className="accordion" onClick={() => toggleAccordions()}>
              <h6>New Card</h6>
              <Chevron className={formAccordion ? 'chevronFaceUp' : 'chevronFaceDown'} />
            </div>
            {formAccordion && <Form />}
          </div>
        </div>
      </Modal>
    </>
  );
}
