import React, { createContext, useReducer } from 'react';
import { authorizationStep, trxType } from 'utils/constants';
import { reducer } from './reducer';

export const paymentContext = createContext();

// export const paymentInitialState = {
//   plan: { _id: null, payg: false },
//   price: '0.00',
//   loading: false,
//   plans: [],
//   quantity: 0,
//   card: {},
//   card_data: {},
//   authorization_code: '',
//   cards: [],
//   making_transaction: false,
//   authorization_step: authorizationStep.none,
//   ongoing_trx_ref: '',
//   ongoing_trx_status: '',
//   disply_text: '',
// };

export default function PaymentContext({ children }) {
  const initialState = {
    plan: { _id: null, payg: false },
    price: '0.00',
    loading: false,
    plans: [],
    quantity: 0,
    card: {},
    card_data: {},
    authorization_code: '',
    cards: [],
    making_transaction: false,
    authorization_step: authorizationStep.none,
    ongoing_trx_ref: '',
    ongoing_trx_status: '',
    display_text: '',
    transaction_type: trxType.none,
    save_card: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <paymentContext.Provider value={{ state: state, dispatch: dispatch }}>{children}</paymentContext.Provider>;
}
