import { paymentContext } from 'contexts/payment';
import {
  SET_DEFAULT_STATE,
  SET_MAKING_TRANSACTION,
  SET_SAVE_CARD,
  SET_TRX_REF,
  SET_TRX_STATUS,
} from 'contexts/payment/reducer/types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { trxType } from 'utils/constants';
import { createPaygTrx, createSubTrx, createTopupTrx, verifyTrx } from 'utils/services/paystack';
import { showToastMessage } from 'utils/Toast';
import { failedTrx, VerifyTransaction } from '../CheckoutCard';
import PayButton from '../PayButton';
import './style.scss';

export default function Form({ toggleAccordions }) {
  const { state, dispatch } = useContext(paymentContext);

  const history = useHistory();

  const isSub = state.transaction_type === trxType.subscription;

  // Default
  const [card, setCard] = useState({
    card_name: '',
    card_number: '',
    expiry_date: '',
    card_cvv: '',
  });

  // TEST CARDS
  // No validation Card
  // const [card, setCard] = useState({
  //   card_name: 'John Doe',
  //   card_number: '4084 0840 8408 4081',
  //   expiry_date: '01/24',
  //   card_cvv: '408',
  // });

  // PIN;
  // const [card, setCard] = useState({
  //   card_name: 'John Doe',
  //   card_number: '5078 5078 5078 5078 12',
  //   expiry_date: '01/24',
  //   card_cvv: '081',
  // });

  // PIN + OTP;
  // const [card, setCard] = useState({
  //   card_name: 'John Doe',
  //   card_number: '5060 6666 6666 6666 666',
  //   expiry_date: '01/24',
  //   card_cvv: '123',
  // });

  // PIN + Phone + OTP;
  // const [card, setCard] = useState({
  //   card_name: 'John Doe',
  //   card_number: '5078 5078 5078 5078 04',
  //   expiry_date: '01/24',
  //   card_cvv: '884',
  // });

  // Declined Card
  // const [card, setCard] = useState({
  //   card_name: 'John Doe',
  //   card_number: '4084 0800 0000 5408',
  //   expiry_date: '01/24',
  //   card_cvv: '001',
  // });

  // 500 Error Card
  // const [card, setCard] = useState({
  //   card_name: 'John Doe',
  //   card_number: '5060 6600 0000 0064',
  //   expiry_date: '01/24',
  //   card_cvv: '606',
  // });

  const [error, setError] = useState({
    card_name: false,
    card_number: false,
    expiry_date: false,
    card_cvv: false,
  });

  useEffect(() => {
    if (isSub) dispatch({ type: SET_SAVE_CARD, payload: true });
  }, [isSub]);

  // console.log({ input_errors: error });

  const checkForErrors = () => {
    const errorVar = {};
    for (const [key, value] of Object.entries(error)) {
      // IF there's an error
      // Or the input has no value
      if (value === true || !card[key].length) {
        errorVar.el = key;
        errorVar.value = true;
        break;
      }
    }
    return errorVar;
  };
  // getErrors();

  // Form Input validation
  useEffect(() => {
    // Card name
    // if input was edited but is not valid
    if (card.card_name.length && card.card_name.length < 3) {
      setError((p) => ({ ...p, card_name: true }));
    } else {
      setError((p) => ({ ...p, card_name: false }));
    }

    // Card name
    // if input was edited but is not valid
    if (card.card_number.length && card.card_number.length < 9) {
      setError((p) => ({ ...p, card_number: true }));
    } else {
      setError((p) => ({ ...p, card_number: false }));
    }

    // Expiry Date
    if (card.expiry_date.length === 5) {
      const curr_year = +new Date().getFullYear().toString().substring(2);
      const curr_month = new Date().getMonth() + 1;
      const exp_month = +card.expiry_date.substring(0, 2);
      const exp_year = +card.expiry_date.substring(3);

      // console.log(curr_month, exp_month, curr_year, exp_year);
      // console.log(curr_month, exp_month, curr_month > exp_month);
      if (curr_year > exp_year) {
        // Do anything with error
        console.log({ Error: 'Date provided is in the past' });
        setError((p) => ({ ...p, expiry_date: true }));
      } else if (curr_month > exp_month && curr_year === exp_year) {
        // Do anything with error
        console.log({ Error: 'Year is valid but month provided is in the past' });
        setError((p) => ({ ...p, expiry_date: true }));
      } else {
        setError((p) => ({ ...p, expiry_date: false }));
      }
    }

    // CVV
    // if cvv was entered but is not complete
    if (card.card_cvv.length > 0 && card.card_cvv.length < 3) {
      setError((p) => ({ ...p, card_cvv: true }));
    } else {
      setError((p) => ({ ...p, card_cvv: false }));
    }
  }, [card]);

  return (
    <div className="checkoutFormContainer">
      <div className="inputWrapper">
        <input
          className={error.card_name ? 'input__error' : ''}
          type="text"
          placeholder="John Doe"
          value={card.card_name}
          name="card_name"
          onChange={({ target: { value, name } }) => {
            // get rid of unnecessary spaces
            let val = value.replace(RegExp(/\x20+/g), ' ');
            // get rid of unnecessary characters
            val = val.replace(RegExp(/[^A-Za-z_\x20]/g), '');
            setCard((prev) => ({ ...prev, [name]: val }));
          }}
        />
        <small className={`message ${error.card_name && 'message__error'}`}>Invalid card name </small>
        <br />
        <input
          className={error.card_number ? 'input__error' : ''}
          type="text"
          placeholder="4242 4242 4242 4242"
          name="card_number"
          value={card.card_number}
          // onChange={({ target: {  name } }) => {
          onChange={({ target }) => {
            const value = target.value.replace(RegExp(/[^0-9]/g), '');
            const matchesArr = value.match(RegExp(/(\d{4})/g));
            let formattedVal = value;
            if (matchesArr?.length) {
              matchesArr.map((e) => {
                // console.log(e, e.length, 'space:', formattedVal.match(e + ' '));

                // If there's no existing space
                if (!formattedVal.match(e + ' ')) {
                  formattedVal = formattedVal.replaceAll(e, e + ' ');
                }
              });
            }
            setCard((prev) => ({ ...prev, [target.name]: formattedVal.trim() }));
          }}
        />
        <small className={`message ${error.card_number && 'message__error'}`}>Invalid card number </small>

        <div className="cvvWrapper">
          <div>
            <input
              className={error.expiry_date ? 'input__error' : ''}
              type="text"
              placeholder="12/24"
              name="expiry_date"
              value={card.expiry_date}
              onKeyDown={({ key }) => {
                let value = card.expiry_date;
                if (key === 'Backspace') {
                  value = value.slice(0, value.length - 1);
                } else {
                  // Stop registering inputs
                  if (value.length === 5) return;

                  if (key.match(/[0-9]/g)?.length) {
                    value = value + key;

                    // Make sure you can't input months that doesn't exist
                    // Recreating Stripe expiry input feature 😁
                    if (parseInt(value[0]) > 1) value = value.replace(value[0], '0' + value[0]);
                    const first_two = value.substring(0, 2);
                    if (parseInt(first_two) > 12) value = value.replace(first_two, '0' + first_two);

                    // Add trailing slash
                    if (value.length >= 2 && value.indexOf('/') == -1) {
                      const strarr = value.split('');
                      strarr.splice(2, 0, '/');
                      value = strarr.join('');
                    }
                  }
                }
                setCard((prev) => ({ ...prev, expiry_date: value }));
              }}
              onChange={() => {}}
            />
            <small className={`message ${error.expiry_date && 'message__error'}`}>Invalid card expiry date</small>
          </div>

          <div>
            <input
              type="text"
              className={error.card_cvv ? 'input__error' : ''}
              placeholder="012"
              maxLength={3}
              name="card_cvv"
              value={card.card_cvv}
              onChange={({ target: { value, name } }) => {
                setCard((prev) => ({ ...prev, [name]: value.replace(RegExp(/[^0-9]/g), '') }));
              }}
            />
            <small className={`message ${error.card_cvv && 'message__error'}`}>Invalid CVV</small>
          </div>
        </div>
      </div>
      <div className="checkboxWrapper">
        <input
          type="checkbox"
          name="save-card"
          id="save-card"
          checked={state.save_card}
          disabled={isSub}
          onChange={(e) => {
            e.persist();
            if (!isSub) {
              dispatch({ type: SET_SAVE_CARD, payload: e.target.checked });
            }
          }}
        />
        <span>{isSub ? 'Cards used for subscriptions are saved for future use' : 'Save card for future use'}</span>
      </div>

      <PayButton
        loading={state.making_transaction}
        onClick={async () => {
          const { el, value } = checkForErrors();
          if (value) {
            //  TODO: Convert to ref as it's not great to work with DOM els directly in react
            const inputEl = document.querySelector(`input[name=${el}]`);
            setError((p) => ({ ...p, [el]: value }));
            inputEl.focus();
            return;
          }

          if (+state.price < 100) {
            return showToastMessage({
              type: 'error',
              title: 'Invalid Transaction Amount',
              description: 'Transaction amount is too small. Minimum amount allowed is NGN100',
            });
          }

          // console.log('state.plan:', state.plan);
          dispatch({ type: SET_MAKING_TRANSACTION, payload: true });

          // Get rid of all withe spaces
          const card_number = card.card_number.replaceAll(' ', '');
          card.card_expiry_month = card.expiry_date.substring(0, 2);
          card.card_expiry_year = card.expiry_date.substring(3, 5);

          try {
            let trx;

            if (state.transaction_type === trxType.payg) {
              const payload = {
                quantity: state.quantity,
                card: { ...card, card_number: card_number },
              };
              trx = await createPaygTrx(payload);
              console.log('rawcard_trx', trx.data.data);
            } else if (state.transaction_type === trxType.subscription) {
              // Trx is subscription
              const payload = {
                planId: state.plan.paystackPlanId,
                card: { ...card, card_number: card_number },
              };
              trx = await createSubTrx(payload);
              console.log({ rawcard_sub_trx: trx.data.data });
            } else if (state.transaction_type === trxType.topup) {
              // Trx is topup
              const payload = {
                quantity: state.plan.limit,
                amount: state.plan.price,
                card: { ...card, card_number: card_number },
              };
              trx = await createTopupTrx(payload);
              console.log({ rawcard_topup_trx: trx.data.data });
            }

            const { transaction } = trx.data.data;

            dispatch({ type: SET_TRX_REF, payload: transaction.reference });

            if (transaction.status === 'success') {
              await VerifyTransaction(transaction.reference, state.save_card);

              // Delay redirect for 2 seconds so that trx will reflect
              setTimeout(() => {
                dispatch({ type: SET_DEFAULT_STATE });
                history.push('/settings/account/plans');
              }, 2000);
            } else {
              dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
              dispatch({ type: SET_TRX_STATUS, payload: transaction.status });
            }
          } catch (error) {
            console.log({ error });
            dispatch({ type: SET_DEFAULT_STATE });
            toggleAccordions();
            failedTrx(error?.message);
          }
        }}
      />
    </div>
  );
}
