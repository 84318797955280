export const freeplan = 'FREE';

export const planInverval = {
  monthly: 'monthly',
  yearly: 'yearly',
};

// export const authorizationStep = {
//   none: 0,
//   pin: 1,
//   otp: 2,
// };

export const authorizationStep = {
  none: '',
  pin: 'send_pin',
  otp: 'send_otp',
  phone_number: 'send_phone',
  birthday: 'send_birthday',
};

export const trxType = {
  none: 0,
  payg: 1,
  subscription: 2,
  topup: 3,
};

// This is just for testing some components/actions that depends on Boolean
export const isTrue = Boolean(1);

// Edge price for Dollar to naira is 420NGN
export const emailCreditInNaira = 0.42;

export const paygCreditInNaira = 0.5;
