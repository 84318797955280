import React, { useEffect, useState } from 'react';
import { Button, Collapse, Form, Input, Select, Table } from 'antd';
import emptyTableIcon from '../asset/images/profleimg1.svg';
import charisolLogo from '../asset/images/profleimg2.svg';
import defaultProfileImg from '../asset/images/profleimg3.svg';
import Modal from './Modal';

const SingleTeamSection = () => {
  const [modalActive, setModalActive] = useState(false);
  const [failedPass, setFailedPass] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [teamToEdit, setTeamToEdit] = useState(null);
  const [activeCollapseableSection, setActiveCollapseableSection] = useState(['1']);
  const [collapseKey, setCollapseKey] = useState('');
  const [teamToEditDefault, setTeamToEditDefault] = useState(null);
  const userdata = JSON.parse(localStorage.getItem('userDetails'));
  const { Panel } = Collapse;
  const { Option } = Select;
  const [addMemberToTeamForm] = Form.useForm();

  useEffect(() => {
    setTeamToEditDefault(convertToArray(teamToEdit?.members));
  }, [teamToEdit]);
  // const test;
  const mockedTeamofTeams = [
    {
      id: '1001',
      title: 'Marketing',
      members: [
        {
          id: '1',
          name: 'Charisol LTD',
          img: charisolLogo,
          email: 'info@charisol.io',
          status: 'admin',
        },
        {
          id: '2',
          name: 'Uduak Jackson',
          img: emptyTableIcon,
          email: 'uduak.jackson@charisol.io',
          status: 'member',
        },
        {
          id: '3',
          name: 'Samuel Jackson',
          img: defaultProfileImg,
          email: 'samuel.oshin@charisol.io',
          status: 'member',
        },
        {
          id: '4',
          name: 'Lovejoy Okoye',
          img: emptyTableIcon,
          email: 'info@charisol.io',
          status: 'admin',
        },
      ],
    },
    {
      id: '1002',
      title: 'Recruiting',
      members: [
        {
          id: '5',
          name: 'Uche Ogudu',
          img: emptyTableIcon,
          email: 'uduak.jackson@charisol.io',
          status: 'member',
        },
        {
          id: '6',
          name: 'Ray M',
          img: defaultProfileImg,
          email: 'samuel.oshin@charisol.io',
          status: 'member',
        },
        {
          id: '7',
          name: 'Yewande Akerele',
          img: emptyTableIcon,
          email: 'info@charisol.io',
          status: 'coAdmin',
        },
      ],
    },
    {
      id: '1003',
      title: 'Engineering',
      members: [
        {
          id: '8',
          name: 'Olayinka Omosehin',
          img: defaultProfileImg,
          email: 'uduak.jackson@charisol.io',
          status: 'member',
        },
        {
          id: '9',
          name: 'Femi Sosanya',
          img: userdata?.user.picture,
          email: 'samuel.oshin@charisol.io',
          status: 'coAdmin',
        },
        {
          id: '10',
          name: 'Goke Ancla',
          img: defaultProfileImg,
          email: 'uduak.jackson@charisol.io',
          status: 'member',
        },
      ],
    },
  ];
  const columns = [
    {
      title: 'Img',
      dataIndex: 'img',
      key: 'img1',
      width: 60,
      render: (text) => <img src={text} alt="" />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name1',
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email1',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Remove',
      dataIndex: 'id',
      key: 'id1',
      render: (text, record) => (
        <Button className="font-medium" onClick={() => memberToRemoveHandle(record)}>
          Remove
        </Button>
      ),
    },
  ];
  const dataOfMembers = [
    {
      key: '1',
      name: 'Charisol LTD',
      img: charisolLogo,
      email: 'info@charisol.io',
      status: 'admin',
    },
    {
      key: '2',
      name: 'Uduak Jackson',
      img: emptyTableIcon,
      email: 'uduak.jackson@charisol.io',
      status: 'member',
    },
    {
      key: '3',
      name: 'Samuel Jackson',
      img: defaultProfileImg,
      email: 'samuel.oshin@charisol.io',
      status: 'member',
    },
    {
      key: '4',
      name: 'Lovejoy Okoye',
      img: emptyTableIcon,
      email: 'info@charisol.io',
      status: 'admin',
    },
    {
      key: '5',
      name: 'Uche Ogudu',
      img: emptyTableIcon,
      email: 'uduak.jackson@charisol.io',
      status: 'member',
    },
    {
      key: '6',
      name: 'Ray M',
      img: defaultProfileImg,
      email: 'samuel.oshin@charisol.io',
      status: 'member',
    },
    {
      key: '7',
      name: 'Yewande Akerele',
      img: emptyTableIcon,
      email: 'info@charisol.io',
      status: 'coAdmin',
    },
    {
      key: '8',
      name: 'Olayinka Omosehin',
      img: defaultProfileImg,
      email: 'uduak.jackson@charisol.io',
      status: 'member',
    },
    {
      key: '9',
      name: 'Femi Sosanya',
      img: userdata?.user.picture,
      email: 'samuel.oshin@charisol.io',
      status: 'coAdmin',
    },
    {
      key: '10',
      name: 'Goke Ancla',
      img: defaultProfileImg,
      email: 'uduak.jackson@charisol.io',
      status: 'member',
    },
    {
      key: '11',
      name: 'Mayowa Adegunwa',
      img: emptyTableIcon,
      email: 'samuel.oshin@charisol.io',
      status: 'member',
    },
  ];
  const collapseCallback = (key) => {
    setCollapseKey(key?.at(-1));
    setActiveCollapseableSection((prev) => key?.at(-1));
  };

  const closeModalState = () => {
    setModalActive(false);
  };

  const teamToEditHandle = (data, indx) => {
    let newIndx = indx;
    console.log({ newIndx: newIndx.toString() });
    setTeamToEdit(data);
    setActiveCollapseableSection([newIndx.toString()]);
    setModalActive(true);
  };

  const memberToRemoveHandle = (data) => {
    setMemberToRemove(data);
  };

  const onFinish = async (values) => {
    console.log({ values });
    setModalActive(false);
    addMemberToTeamForm.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    // setFailedPass(false);
    errorInfo.errorFields.map((val) => {
      console.log({ val });
      if (val.name[0] === 'teamName') {
        // setFailedPass(true);
        return true;
      }
    });
  };

  const editTeamHandle = (value) => {
    console.log(`selected ${value}`);
  };
  console.log({ tata: collapseKey });
  console.log({ tata2: activeCollapseableSection });
  // console.log({ tata2: Object.keys(teamToEdit?.members?.name) });

  const convertToArray = (iniTialArray) => {
    const test = iniTialArray?.map((data) => data.name);
    return test;
  };
  return (
    <div>
      <Collapse
        activeKey={activeCollapseableSection}
        // collapsible="header"
        accordion={true}
        // defaultActiveKey={['1']}
        onChange={collapseCallback}
      >
        {mockedTeamofTeams.map((data1, dataIndex) => (
          <Panel
            header={
              <div className="flex items-center  text-base ">
                <span className="mx-4 font-medium">{data1.title}</span>
                <span className="mx-4 team-member-length">
                  {data1?.members.length <= 1 ? `${data1?.members.length} member` : `${data1?.members.length} members`}
                </span>
                <Button className="mx-4" onClick={() => teamToEditHandle(data1, dataIndex)}>
                  Add member +
                </Button>
              </div>
            }
            key={++dataIndex}
          >
            <Table rowKey="name" columns={columns} dataSource={data1.members} />
          </Panel>
        ))}
      </Collapse>
      <Modal isActive={modalActive} onCancel={closeModalState} title="Add Member">
        <div className="p-6">
          <Form
            form={addMemberToTeamForm}
            layout="vertical"
            initialValues={{
              teammembers: teamToEditDefault,
              teamName: teamToEdit?.title,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Name"
              name="teamName"
              required
              rules={[
                {
                  required: true,
                  message: 'Team name must be provided',
                },
              ]}
            >
              <Input placeholder="Team" type="text" />
            </Form.Item>
            <Form.Item label="Select team members" name="teammembers">
              <Select
                mode="tags"
                style={{ width: '100%', marginBottom: '20px' }}
                placeholder="Select team members"
                // onChange={editTeamHandle}
                // defaultValue={teamToEditDefault}
              >
                {dataOfMembers?.map((member, i) => (
                  <Option key={i} value={member.name}>
                    {member.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="flex justify-between">
              <Button
                onClick={() => setModalActive(false)}
                className="mr-3 border"
                style={{
                  background: 'transparent',
                  color: '#000',
                  border: '1px solid #E3E3E3',
                  borderRadius: '4px',
                }}
              >
                Back
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  background: '#273df2',
                  color: '#fff',
                  borderRadius: '4px',
                  border: '1px solid #273df2',
                }}
              >
                Done
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default SingleTeamSection;
