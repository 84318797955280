import React, { useEffect, useState } from 'react';
import DateFromNow from '../../../components/DateFromNow';
import { Button, Divider, Form, Input, Modal, Select, Spin, Table, Tabs, Tooltip, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
const Receipients = ({ recipients, imLoading, campaignId, deleteUserFromCampaign }) => {
  const history = useHistory();

  const columns = [
    {
      title: 'Namez',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Received',
      dataIndex: 'numberOfEmailRecieved',
      width: 150,
    },
    {
      title: 'Source',
      dataIndex: 'recipientOrigin',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      width: 150,
      render: (createdAt) => (
        <p className="paragraph">
          Added <DateFromNow date={new Date(createdAt)} />
        </p>
      ),
    },
    {
      title: 'Actions',
      render: ({ email, _id }) => (
        <div className="flex receipientActionBtns">
          <Button onClick={() => history.push(`/campaign/${campaignId}/recipient-details/${email}`)}>View</Button>
          <Button
            onClick={() => {
              deleteUserFromCampaign(campaignId, _id);
            }}
            danger
            loading={imLoading.id === _id}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(
      //   `selectedRowKeys: ${selectedRowKeys}`,
      //   "selectedRows: ",
      //   selectedRows
      // );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className="antdTable">
      <Table
        // rowSelection={{
        //   type: "checkbox",
        //   ...rowSelection,
        // }}
        // scroll={{ x: true }}
        loading={imLoading.id === 'addingRecipient'}
        scroll={{ y: '300px', x: true }}
        sticky={true}
        rowKey={'_id'}
        columns={columns}
        dataSource={recipients}
        rowClassName="table-row"
      />
    </div>
  );
};

export default Receipients;
