import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import { AuthNav } from '../components/emmaccen/sharedComponents/AuthNav';
import { NavBar } from '../components/emmaccen/sharedComponents/NavBar';
// import Sidebar from './emmaccen/sharedComponents/Sidebar';
import Sidebar from './emmaccen/sharedComponents/Sidebar';

import { getWidth } from './emmaccen/shared-functions/getSideBarWidth';
import WhatsNewModalPop from './WhatsNewModalPop';
const styles = {
  active: true,
  width: '70px',
  activeWidth: '200px',
  hide: true,
  topNavOpen: true,
  /**
   * @param {string} val
   */
  set w(val) {
    this.val = val;
  },
  get getWidth() {
    return this.active ? this.activeWidth : this.width;
  },
};

function Layout({ children }, props) {
  const { pathname } = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [activeSideBar, setActiveSideBar] = useState(styles);
  const [isModalActive, setIsModalActive] = useState(false);

  let navWidthHandler = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth > 600) {
      setActiveSideBar({ ...activeSideBar, topNavOpen: true });
      windowWidth <= 700 && windowWidth > 600 && setActiveSideBar({ ...activeSideBar, active: false, hide: false });
    }
    if (windowWidth >= 700) {
      setActiveSideBar({ ...activeSideBar, active: true, hide: false });
    } else if (windowWidth <= 600) {
      setActiveSideBar({ ...activeSideBar, topNavOpen: false, active: false, hide: true });
    }
    return windowWidth;
  };

  useEffect(() => {
    window.addEventListener('resize', navWidthHandler);
    return () => window.removeEventListener('resize', navWidthHandler);
  }, []);

  useEffect(() => {
    navWidthHandler();
  }, []);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage?.getItem('userDetails'));
    if (userDetails) {
      setIsLoggedIn(true);
      setUserDetails(userDetails?.user);
    }

    const firstTimeLogCheck = Cookie.get('firstTimeLog');

    if (firstTimeLogCheck === undefined && isLoggedIn) {
      setIsModalActive(true);
      Cookie.set('firstTimeLog', 'true');
    } else if (firstTimeLogCheck == 'true' && isLoggedIn) {
      setIsModalActive(true);
    } else {
      setIsModalActive(false);
    }
  }, [isLoggedIn, pathname]);

  return (
    <div className="relative h-full w-full">
      {isLoggedIn && <Sidebar activeSideBar={activeSideBar} setActiveSideBar={setActiveSideBar} />}
      <div className="emmaccen">
        {isLoggedIn ? (
          <AuthNav data={userDetails} activeSideBar={activeSideBar} setActiveSideBar={setActiveSideBar} />
        ) : (
          <NavBar />
        )}
        {/* body */}
        <div
          style={{ width: `calc(100% - ${getWidth(activeSideBar)})`, top: `${activeSideBar.hide ? '50px' : '70px'}` }}
          className="pageContent"
        >
          {children}
        </div>

        <WhatsNewModalPop modalIsActuve={isModalActive} setModalIsActive={setIsModalActive} />
      </div>
    </div>
  );
}

export default Layout;

export const Card = ({ children }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow">{children}</div>
);
