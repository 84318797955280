import { Spin } from 'antd';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import imgPlaceHolder from '../asset/images/imagPlaceHolder.png';
import defaultLogo from '../deliver.png';
import { getPublishPageRequest, guestJoinCampaign, postPublishPageRequest } from '../utils/service';
import { showToastMessage } from '../utils/Toast';

function JoinACampaign({
  match: {
    params: { campaignId },
  },
}) {
  const location = useLocation();
  const isEditing = location.state?.isEditing;
  const [alttext, setAlttext] = useState('');
  const [publishing, setPublishing] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [logo, setLogo] = useState('');
  const [editMode, setEditMode] = useState('');
  const [btnText, setBtnText] = useState('');
  const [btnColor, setBtnColor] = useState('');
  const [tagline, setTagline] = useState('');
  const [catchphrase, setCatchphrase] = useState('');
  const [sectionbackground, setSectionbackground] = useState('');
  const fileReader = new FileReader();
  const initialValues = { email: '', lastName: '', firstName: '' };
  const history = useHistory();
  const validationSchema = yup.object({
    email: yup.string().required('Give us your email address').email(),
    firstName: yup.string().required('Give us your first name'),
    lastName: yup.string().required('Give us your last name'),
  });

  const onDrop = useCallback((acceptedFiles) => {
    fileReader.readAsDataURL(acceptedFiles[0]);
    fileReader.onloadend = () => {
      setLogo(fileReader.result);
    };
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.png, .jpg, .jpeg',
    onDrop,
    maxFiles: '1',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    getJoinPageValues();
  }, []);

  const getJoinPageValues = () => {
    setPageLoading(true);
    getPublishPageRequest(campaignId)
      .then((e) => {
        if (e.data.data.catchphrase) {
          setBtnColor(e.data.data.btnColor);
          setBtnText(e.data.data.btnText);
          setCatchphrase(e.data.data.catchphrase);
          setLogo(e.data.data.imageURL);
          setSectionbackground(e.data.data.sectionbackground);
          setTagline(e.data.data.tagline);
          setAlttext(e.data.data.alttext);
        }
        setPageLoading(false);
      })
      .catch((error) => {
        setPageLoading(false);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Sorry, an error occured',
        });
      });
  };

  const handleSubmit = ({ email, firstName, lastName }, { setSubmitting, setFieldError, resetForm }) => {
    setSubmitting(true);
    const payload = {
      email,
      name: `${firstName} ${lastName}`,
    };
    guestJoinCampaign(payload, campaignId)
      .then((e) => {
        resetForm({});
        setSubmitting(false);
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: 'Your email has been added, you can unsubscribe at any time',
        });
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.response?.data?.message === 'Email already exist in campaign')
          setFieldError('email', 'Email already exist in campaign');
        else
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: 'An error occured',
          });
      });
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name == 'tagline') {
      setTagline(value);
    }
    if (name === 'btnText') {
      setBtnText(value);
    }
    if (name === 'catchphrase') {
      setCatchphrase(value);
    }
    if (name === 'alttext') {
      setAlttext(value);
    }
    if (name === 'sectionbackground') {
      setSectionbackground(value);
    }
    if (name === 'btnColor') {
      setBtnColor(value);
    }
  };
  const publishPage = () => {
    const payload = {
      btnText,
      alttext,
      sectionbackground,
      tagline,
      catchphrase,
      btnColor,
      logo: acceptedFiles[0],
    };
    const formData = new FormData();
    Object.entries(payload).forEach((element) => {
      formData.append(element[0], element[1]);
    });
    setPublishing(true);
    postPublishPageRequest(formData, campaignId)
      .then((e) => {
        showToastMessage({
          type: 'success',
          title: 'Published',
          description: 'Your page has been published',
        });

        setPublishing(false);
      })
      .catch((error) => {
        setPublishing(false);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occured',
        });
      });
  };
  if (!pageLoading && isEditing) {
    return (
      <>
        <div className="admin-join-page-wrapper">
          <div className="left">
            <div className="inner">
              <div
                className="flex mr-2 font-bold click align-items-center"
                onClick={() => {
                  history.push('/campaigns');
                }}
              >
                <span class="iconify" data-icon="ic:baseline-arrow-back"></span>
                <span className="ml-1">Back to Campaigns</span>
              </div>
              <p className="mb-5">Create engaging campaigns with your audience</p>
              <div
                className="edit-join-page-wrapper"
                style={{
                  backgroundColor: sectionbackground ? sectionbackground : 'white',
                }}
              >
                <div className="inner">
                  <img
                    src={logo ? logo : defaultLogo}
                    className="m-auto mb-3 click"
                    onClick={() => {
                      setEditMode('logo');
                    }}
                    alt={alttext}
                  />
                  <h2
                    className="mb-3 leading-tight capitalize font-weight-bold click"
                    onClick={() => {
                      setEditMode('button');
                    }}
                  >
                    {tagline ? tagline : 'Join Campaign'}
                  </h2>
                  <p
                    className="mb-8 click"
                    onClick={() => {
                      setEditMode('button');
                    }}
                  >
                    {catchphrase ? catchphrase : 'Some description or catch phrase goes in here'}
                  </p>
                  <div>
                    <input
                      placeholder="First name"
                      className={
                        'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2'
                      }
                    />
                    <input
                      placeholder="Last name"
                      className={
                        'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2'
                      }
                    />

                    <input
                      placeholder="Email"
                      className={
                        'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2'
                      }
                    />
                    <button
                      className="w-full p-3 mt-2 font-bold text-white rounded-md font bold disabled:bg-gray-600"
                      style={{ backgroundColor: btnColor ? btnColor : 'black' }}
                      onClick={() => {
                        setEditMode('button');
                      }}
                    >
                      {btnText ? btnText : 'Join'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="inner">
              <div className="mb-5 lg:text-right">
                {editMode ? (
                  <button className="btn btn-blue" disabled={publishing} onClick={publishPage}>
                    {publishing ? (
                      <>
                        <Spin />
                        Publishing...
                      </>
                    ) : (
                      'Publish'
                    )}
                  </button>
                ) : (
                  <button className="btn"></button>
                )}
              </div>
              <div>
                <div className="p-3 bg-white border-radius">
                  <div className="mb-2 font-weight-bold">Settings</div>
                  {editMode === 'logo' ? (
                    <div>
                      <div {...getRootProps({ className: 'dropzone' })} className="ip-box">
                        <div>
                          <input {...getInputProps()} />
                          <div className="import-label">
                            <div>
                              <img src={imgPlaceHolder} alt="" />
                              <h4>Drag & drop or click here</h4>
                              <span>to upload hero image (max: 2mb)</span>
                              <br />
                              <span className="text-green">Preferable file size: 60px X 60px</span>
                            </div>
                          </div>
                          <aside>
                            <h4 className="mb-1">File</h4>
                            <ul className="text-blue">{files}</ul>
                          </aside>
                        </div>
                      </div>
                      <input
                        type="text"
                        className={
                          'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
                        }
                        placeholder="Image alt text"
                        value={alttext}
                        name="alttext"
                        onChange={handleTextChange}
                      />
                    </div>
                  ) : editMode === 'button' ? (
                    <div className="py-4">
                      <input
                        type="text"
                        className={
                          'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-3'
                        }
                        placeholder="Enter Tag Line"
                        value={tagline}
                        name="tagline"
                        onChange={handleTextChange}
                      />
                      <input
                        type="text"
                        className={
                          'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-3'
                        }
                        placeholder="Enter catch phrase"
                        value={catchphrase}
                        name="catchphrase"
                        onChange={handleTextChange}
                      />
                      <input
                        type="text"
                        className={
                          'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-3'
                        }
                        placeholder="Enter button text"
                        value={btnText}
                        name="btnText"
                        onChange={handleTextChange}
                      />
                      <div>
                        <label className="block font-bold">Select button color</label>
                        <input type="color" value={btnColor} name="btnColor" onChange={handleTextChange} />
                      </div>
                      <div>
                        <label className="block font-bold">Select background color</label>
                        <input
                          type="color"
                          value={sectionbackground}
                          name="sectionbackground"
                          onChange={handleTextChange}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="ip-box">
                        <div className="empty-click">
                          <h4>Customize what you want your users to see.</h4>
                          <span>click an item to edit</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (!pageLoading) {
    return (
      <div
        className="flex items-center justify-center w-full min-h-screen sm:px-5"
        style={{
          backgroundColor: sectionbackground ? sectionbackground : 'white',
        }}
      >
        <div className="flex flex-col items-center justify-center w-full p-10 rounded-md lg:max-w-lg sm:max-w-sm md:max-w-md">
          <div className="flex flex-col items-center justify-center mb-4 text-center">
            <img src={logo ? logo : defaultLogo} alt="" className="h-24"></img>
            <h1 className="text-4xl font-extrabold leading-tight">{tagline ? tagline : 'Join Campaign'}</h1>
            <p className="text-sm text-gray-700">
              {catchphrase ? catchphrase : 'Some description or catch phrase goes in here'}
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validateOnMount
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values: { firstName, lastName, email },
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldError,
              errors,
              touched,
              isValid,
            }) => (
              <form className="w-full max-w-lg mt-5" onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 ">
                  <div className="w-full px-3">
                    <input
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={firstName}
                      placeholder="First name"
                      className={
                        'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2'
                      }
                    />
                    <input
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={lastName}
                      placeholder="Last name"
                      className={
                        'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-2'
                      }
                    />
                    <input
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={email}
                      placeholder="Email"
                      type="email"
                      className={
                        'appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white'
                      }
                    />
                    {errors.email && touched.email && (
                      <div className="text-xs leading-loose text-orange-600 feedback-fade-in">{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="mt-2">
                  <button
                    disabled={isSubmitting || !isValid}
                    className="w-full p-3 font-bold text-white rounded-md font bold disabled:bg-gray-600"
                    style={{ backgroundColor: btnColor ? btnColor : 'black' }}
                  >
                    {btnText ? (
                      <>{(isSubmitting && `${btnText}...`) || btnText}</>
                    ) : (
                      <>{(isSubmitting && 'Joining...') || 'Join'}</>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>

          <div className="mt-3 text-sm hover:underline">
            <a className="text-blue-600" href="/">
              Powered by MailDrip
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="centerLoadingPage">
        <Spin />
      </div>
    );
  }
}

export default JoinACampaign;
