export default (editor, opts = {}) => {
  const bm = editor.BlockManager;

  bm.add('socialgroup', {
    label: 'Social Group',
    content: `
    <div data-gjs-type="groupsocial" class="groupsocial" mode="horizontal">
    <div class="div_group" style="margin:0 auto; display:flex; padding:5px; pointer-events: none;" data-gjs-hoverable="false" data-gjs-highlightable="false">
    
    <div data-gjs-type="elemntsocial" class="elemntsocial" style="margin:5px; background: rgb(59, 89, 152) none repeat scroll 0% 0%; border-radius: 12px; width: 24px; pointer-events: all;">
    <a href="#" target="_blank" style="pointer-events: none;">
      <img src="https://www.mailjet.com/images/theme/v1/icons/ico-social/facebook.png" style="border-radius: 12px; display: block; pointer-events: none;" width="24" height="24">
    </a>
    </div>
    <div data-gjs-type="elemntsocial" class="elemntsocial" style="margin:5px; background: rgb(220, 78, 65) none repeat scroll 0% 0%; border-radius: 12px; width: 24px; pointer-events: all;">
    <a href="#" target="_blank" style="pointer-events: none;">
      <img src="https://www.mailjet.com/images/theme/v1/icons/ico-social/google-plus.png" style="border-radius: 12px; display: block; pointer-events: none;" width="24" height="24">
    </a>
    </div>
    <div data-gjs-type="elemntsocial" class="elemntsocial" style="margin:5px; background: rgb(85, 172, 238) none repeat scroll 0% 0%; border-radius: 12px; width: 24px; pointer-events: all;">
    <a href="#" target="_blank" style="pointer-events: none;">
      <img src="https://www.mailjet.com/images/theme/v1/icons/ico-social/twitter.png" style="border-radius: 12px; display: block; pointer-events: none;" width="24" height="24">
    </a>
    </div>
    </div>
    </div>`,
    attributes: { class: 'fa fa-share-alt' },
    category: 'Basic',
  });

  bm.add('socialelement', {
    label: 'Social Element',
    content: `<div data-gjs-type="elemntsocial" class="elemntsocial" style="margin:5px; background: rgb(59, 89, 152) none repeat scroll 0% 0%; border-radius: 12px; width: 24px; pointer-events: all;">
    <a href="#" target="_blank" style="pointer-events: none;">
      <img src="https://www.mailjet.com/images/theme/v1/icons/ico-social/facebook.png" style="border-radius: 12px; display: block; pointer-events: none;" width="24" height="24">
    </a>
    </div>`,
    attributes: { class: 'fa fa-share-alt' },
    category: 'Basic',
  });
};
