import React, { useContext, useState } from 'react';
import TransactionLoading from 'asset/Icons/TransactionLoading/TransactionLoading';
import { paymentContext } from 'contexts/payment';
import './style.scss';
import Pay from 'components/Buttons/PaymentCTA';
import { SET_DEFAULT_STATE, SET_MAKING_TRANSACTION, SET_TRX_STATUS } from 'contexts/payment/reducer/types';
import { authorizeTrx } from 'utils/services/paystack';
import { showToastMessage } from 'utils/Toast';
import { failedTrx, PaystackCheckout, VerifyTransaction } from '../CheckoutCard';
import { authorizationStep } from 'utils/constants';
import { useHistory } from 'react-router-dom';

export default function TransactionOTP() {
  const history = useHistory();

  const {
    state: { making_transaction, ongoing_trx_ref, display_text, save_card },
    dispatch,
  } = useContext(paymentContext);
  const [otp, setOtp] = useState('');
  return (
    <PaystackCheckout className={'otpbox_outer'}>
      <div className="otpbox">
        <h2 className="otpbox__header">{display_text || 'Kindly enter an OTP (None will be sent to your phone)'}</h2>

        <div className="otp otp__wrapper">
          {making_transaction ? (
            <TransactionLoading />
          ) : (
            <div className="otp__inner_wrapper">
              <input
                className="otp__input"
                type="text"
                value={otp}
                onChange={({ target: { value } }) => setOtp(value)}
              />
              <Pay
                loading={making_transaction}
                onClick={async () => {
                  try {
                    dispatch({ type: SET_MAKING_TRANSACTION, payload: true });

                    const trx = await authorizeTrx({
                      otp: otp,
                      reference: ongoing_trx_ref,
                    });
                    console.log('otp_trx', trx.data.data);
                    const { transaction } = trx.data.data;

                    if (transaction.status === 'success') {
                      await VerifyTransaction(ongoing_trx_ref, save_card);
                      // Delay redirect for 2 seconds so that trx will reflect
                      setTimeout(() => {
                        dispatch({ type: SET_DEFAULT_STATE });
                        history.push('/settings/account/plans');
                      }, 2000);
                    } else {
                      dispatch({ type: SET_MAKING_TRANSACTION, payload: false });
                      dispatch({ type: SET_TRX_STATUS, payload: transaction.status });
                    }
                  } catch (error) {
                    console.log({ error });
                    dispatch({ type: SET_DEFAULT_STATE });
                    failedTrx(error?.data.message);
                  }
                }}
                text={'Authorize'}
              />
            </div>
          )}
        </div>

        <button className="otpbox_cancel_button" onClick={() => dispatch({ type: SET_DEFAULT_STATE })}>
          Cancel
        </button>
      </div>
    </PaystackCheckout>
  );
}
