import React, { useState, useEffect, useRef } from 'react';
import {
  LoadingOutlined,
  SearchOutlined,
  ShareAltOutlined,
  UploadOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Popconfirm, Space, Table, Tooltip, Typography, notification } from 'antd';
import { usePapaParse } from 'react-papaparse';
import { ExportJsonCsv } from 'react-export-json-csv';
import '../styles/contactPage.scss';
import {
  AddNewContact,
  CreateContactFromGroup,
  CreateContactGroup,
  EditContact,
  AddToGroupOrCampaign,
} from '../components/emmaccen/modals';
import { getAllContactGroups, getGroupContacts, editContactGroup } from '../utils/service';
import { showToastMessage } from '../utils/Toast';
import DateFromNow from '../components/DateFromNow';
import searchData from '../utils/searchData';
import useDebounce from '../hooks/useDebounce';

const ContactPage = () => {
  const groupDescription = useRef();
  const [imLoading, setImLoading] = useState({ id: '' });
  const [imVisible, setImVisible] = useState({ id: '' });
  const [contactGroups, setContactGroups] = useState([]);
  // const [batchLoading, setBatchLoading] = useState([]);
  const [currentGroup, setCurrentGroup] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableSearchData, setTableSearchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [refresh, setRefresh] = useState([]);
  const [actionableContactData, setActionableContactData] = useState({});
  const [multiselectionData, setMultiselectionData] = useState([]);

  // const queueLoaderDone = (id) => setBatchLoading(batchLoading.filter((inQueue) => inQueue !== id));

  const { Search } = Input;
  const { Option } = Select;
  const { Paragraph } = Typography;
  const { jsonToCSV } = usePapaParse();
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  //To export contacts, convert it to csv pkg readable format before download
  const clientCSVTableData = tableData?.map(({ name, email, phone }) => ({ name, email, phone: parseFloat(phone) }));

  const csvExpHeaders = [
    {
      key: 'name',
      name: 'Name',
    },
    {
      key: 'email',
      name: 'Email',
    },
    {
      key: 'phone',
      name: 'Phone',
    },
  ];

  // this takes out "All contacts" group from groups
  const filterdGroup = (contactGroups) => contactGroups.filter((id) => !!id._id);
  const fetchGroups = () => {
    setMultiselectionData([]);
    setImLoading({ id: 'fetchingAllGroups' });
    getAllContactGroups()
      .then((groups) => {
        setContactGroups(groups?.data.data.groups);
        setCurrentGroup(groups?.data.data.groups[0]);
        // groupDescription.current = groups?.data.data.groups[0].description;
        console.log({ group: groups.data.data });
        // console.log({ groups });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  const getContact = () => {
    setMultiselectionData([]);
    setImLoading({ id: 'contactTableLoading' });
    getGroupContacts(currentGroup?._id || '')
      .then((contacts) => {
        setTableData(contacts?.data.data?.group?.contacts);
        setTableSearchData(contacts?.data.data?.group?.contacts);
        console.log({ contact: contacts?.data.data });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };
  useEffect(() => {
    fetchGroups();
  }, [refresh]);

  useEffect(() => {
    getContact();
  }, [currentGroup]);

  useEffect(() => {
    multiselectionData.length > 0
      ? notification.open({
          description: '',
          message: <ModifygroupText />,
          key: 'hello',
          duration: null,
          // onClose:() => {}
          placement: 'bottom',
          className: 'modify-group_pop',
          closeIcon: <ModifygroupBtn />,
        })
      : notification.close('hello');
  }, [multiselectionData.length]);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setImLoading({ id: 'contactTableLoading' });
        searchData(debouncedSearchTerm, tableData, setTableSearchData, ['name', 'email'], setImLoading);
      } else {
        setImLoading({ id: '' });
        searchData('', tableData, setTableSearchData, ['name', 'email'], setImLoading);
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const searchContact = (e) => {
    setSearchTerm(e.target.value);
  };

  const ModifygroupBtn = () => (
    <Button type="primary" onClick={() => setImVisible({ id: 'addToGroupOrCampaign' })} className="modify-group_btn">
      Add to <UsergroupAddOutlined />
    </Button>
  );
  const ModifygroupText = () => (
    <div className="modify-group_text ">{multiselectionData.length} recipient(s) selected</div>
  );
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      render: (createdAt) => {
        // console.log({ createdAt });
        return (
          <p className="paragraph">
            Added <DateFromNow date={new Date(createdAt)} />
          </p>
        );
      },
    },
    {
      render: (contactData) => {
        return (
          <div className="flex tableActionBtns">
            {/* {!currentGroup._id ? (
              <>
                <Button className="">View</Button>
                <Popconfirm title="Are you sure you want to delete this contact?" okText="Delete" cancelText="Cancel">
                  <Button className="ghostBtn">Delete</Button>
                </Popconfirm>
              </>
            ) : (
              <Button
                onClick={() => {
                  setActionableContactData(contactData);
                  setImVisible({ id: 'editContact' });
                }}
                className="allcontact-edit_btn"
              >
                Edit
              </Button>
            )} */}
            {!currentGroup._id && (
              <Button
                onClick={() => {
                  setActionableContactData(contactData);
                  setImVisible({ id: 'editContact' });
                }}
                className="allcontact-edit_btn"
              >
                Edit
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  // rowSelection object indicates the need for row selection as per UI design

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setMultiselectionData(selectedRowKeys);
      setImVisible({ id: 'multiselect' });
    },
    getCheckboxProps: (record) => ({
      // disabled: !record.isActive,
      // Column configuration not to be checked
      name: record._id,
    }),
  };

  const GroupList = (group) => {
    const { title, totalContacts, _id: id } = group;
    return (
      <li
        style={{ backgroundColor: `${currentGroup._id === id ? '#FBFBFB' : ''}` }}
        key={id ?? title}
        onClick={() => setCurrentGroup(group)}
        className="pointer padLR"
      >
        <div className="listMenuContent" title={title}>
          <p className="elipsis">{title}</p>
          <span className="faded">{totalContacts}</span>
        </div>
        <div className="mobileMenuItem">
          <Tooltip trigger={['hover']} title={title} placement="right">
            <span className="linkName centered">{title.split(' ').map((name) => name[0])}</span>
          </Tooltip>
        </div>
      </li>
    );
  };
  return (
    <div className="contactPageContainer">
      <AddNewContact imVisible={imVisible} setImVisible={setImVisible} setRefresh={setRefresh} />
      <CreateContactFromGroup
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={filterdGroup(contactGroups)}
        currentGroup={currentGroup}
        setRefresh={setRefresh}
      />
      <CreateContactGroup imVisible={imVisible} setImVisible={setImVisible} setRefresh={setRefresh} />
      <EditContact
        imVisible={imVisible}
        setImVisible={setImVisible}
        actionableContactData={actionableContactData}
        setRefresh={setRefresh}
        contactGroups={filterdGroup(contactGroups)}
      />
      <AddToGroupOrCampaign
        imVisible={imVisible}
        setImVisible={setImVisible}
        contactGroups={contactGroups}
        multiselectionData={multiselectionData}
        currentGroup={currentGroup}
        setRefresh={setRefresh}
      />

      <div className="sidebar active">
        <div className="addGroup flex-space-btw padLR padT">
          <p>Group</p>
          <img
            onClick={() => setImVisible({ id: 'createContactGroup' })}
            src="/assets/addContactIcon.svg"
            alt="add contact icon"
            className="pointer"
          ></img>
        </div>
        <div className="centered">
          <img
            onClick={() => setImVisible({ id: 'createContactGroup' })}
            src="/assets/addContactIcon.svg"
            alt="add contact icon"
            className="pointer addContact"
          ></img>
        </div>
        <div className="contactListMenu">
          {imLoading.id === 'fetchingAllGroups' && <LoadingOutlined />}
          <ul>{contactGroups.map(GroupList)}</ul>
        </div>
      </div>
      <div className="contentContainer">
        <div className="heading">
          <h3>{currentGroup.title}</h3>
          {currentGroup._id && (
            <Paragraph
              className="faded contentEditable py-1"
              editable={{
                icon: <EditOutlined />,
                // tooltip: 'Click to edit text & press enter when done',
                tooltip: 'Click to edit text',
                onChange: (value) => {
                  setCurrentGroup({ ...currentGroup, description: value });
                  editContactGroup(currentGroup._id, { ...currentGroup, description: value });
                },
                triggerType: ['icon', 'text'],
                enterIcon: null,
                maxLength: 150,
                autoSize: {
                  maxRows: 5,
                },
                // onEnd: () => {

                // },
              }}
            >
              {currentGroup.description}
            </Paragraph>
          )}
        </div>
        <div className="actionTab">
          <div className="flex-space-btw">
            <div>
              <Input
                placeholder="Search contact..."
                allowClear
                // enterButton="Search"
                className="fade3 searchInput"
                prefix={imLoading.id === 'searchingCampaigns' ? <LoadingOutlined /> : <SearchOutlined />}
                size="large"
                onChange={(e) => searchContact(e)}
              />
            </div>
            <div className="actions">
              <Space>
                <Button
                  onClick={() => setImVisible({ id: 'addNewContactGroup' })}
                  type="primary"
                  className="primaryBtn"
                >
                  Add new <UsergroupAddOutlined />
                </Button>
                {/* <Upload> */}
                {/* <Button className="ghostBtn">
                  Upload <UploadOutlined />
                </Button> */}
                {/* </Upload> */}
                {/* <Popconfirm
                  title="Are you sure you want to export this contact?"
                  okText="Export"
                  onConfirm={handleExportCSV}
                  cancelText="Cancel"
                >
                  <Button className="ghostBtn">
                    Export <ShareAltOutlined />
                  </Button>
                </Popconfirm> */}
                {/* <CSVDownloader
                  type={Type.Button}
                  filename={`${currentGroup.title}_contacts`}
                  bom={true}
                  config={{
                    delimiter: ',',
                  }}
                  data={contactsToExport.current}
                >
                  <Button className="ghostBtn">
                    Export <ShareAltOutlined />
                  </Button>
                </CSVDownloader> */}
                <ExportJsonCsv
                  fileTitle={`${currentGroup.title}_contacts`}
                  className="ghostBtn"
                  headers={csvExpHeaders}
                  items={clientCSVTableData}
                >
                  Export <ShareAltOutlined />
                </ExportJsonCsv>
                {/* {multiselectionData.length > 0 && (
                  <Button
                    onClick={() => setImVisible({ id: 'addToGroupOrCampaign' })}
                    type="ghost"
                    className="ghostBtn"
                  >
                    Modify Group
                  </Button>
                )} */}
              </Space>
            </div>
          </div>
        </div>
        <div className="contactTableContainer antdTable">
          <Table
            loading={imLoading.id === 'contactTableLoading'}
            rowSelection={
              // currentGroup._id && {
              {
                selectedRowKeys: multiselectionData,
                type: 'checkbox',
                ...rowSelection,
              }
            }
            scroll={{ y: '300px', x: true }}
            sticky={true}
            rowKey={'_id'}
            rowClassName=""
            columns={columns}
            // dataSource={tableData}
            dataSource={tableSearchData}
          />
        </div>
      </div>
    </div>
  );
};
export default ContactPage;
