import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as EmptyStateImage } from '../asset/images/empty.svg';
import Navbar from '../components/Navbar';
import { getUserTemplatesRequest } from '../utils/service';

export const PastTemplates = ({
  match: {
    params: { campaignId },
  },
}) => {
  useEffect(() => {
    getPastTemplates(1, 10);
  }, []);
  const history = useHistory();
  const [templateLists, setTemplateLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const getPastTemplates = (page, pageSize) => {
    setLoading(true);
    getUserTemplatesRequest(page, pageSize)
      .then((response) => {
        console.log(response.data.data.templates);
        setLoading(false);
        setTemplateLists(response.data.data.templates);
        setPageInfo(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const loadTemplate = (data) => {
    localStorage.setItem('templateJson', data.designCode);
    history.push(`/campaign/${campaignId}/web-builder`);
  };

  return (
    <section>
      <div className="my-4">
        <Link to={`/campaign/${campaignId}/emails`} className="my-5 click flex align-items-center w-fit-content">
          <span className="mr-2 text-grey">
            <span className="iconify" data-icon="ant-design:arrow-left-outlined" data-inline="false"></span>
          </span>
          <span className="font24">Create New Email</span>
        </Link>
      </div>
      <Navbar
        links={[
          {
            name: 'Blank Layout',
            link: `/campaign/${campaignId}/layouts`,
          },
          { name: 'Templates', link: `/campaign/${campaignId}/templates` },
          { name: 'Past Emails', link: `/campaign/${campaignId}/past-emails` },
        ]}
      />
      <div style={{ minHeight: '50vh' }}>
        {loading ? (
          <div className="text-center pt-10">
            <Spin />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-5 gap-6 py-10">
              {templateLists?.map((each) => (
                <div>
                  {/* <p className="font-bold">{each.layoutType}</p> */}
                  <div
                    key={each._id}
                    className="click w-100"
                    style={{
                      height: '280px',
                      background: `url(${each.imgLocation})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                    onClick={() => {
                      loadTemplate(each);
                    }}
                  ></div>
                </div>
              ))}
            </div>
            {templateLists.length < 1 && (
              <>
                <div className="flex justify-center items-center flex-col mt-20">
                  <EmptyStateImage />
                </div>
              </>
            )}
          </>
        )}
      </div>
      {pageInfo.totalItems > 10 && (
        <div className="flex justify-center">
          <Pagination
            activePage={pageInfo.currentPage}
            itemsCountPerPage={10}
            totalItemsCount={pageInfo.totalItems}
            pageRangeDisplayed={4}
            itemClass="page-item"
            linkClass="page-link"
            onChange={(pageNumber) => {
              getPastTemplates(pageNumber, 10);
            }}
          />
        </div>
      )}
    </section>
  );
};
