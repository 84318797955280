import React, { useState } from 'react';

import { useCSVReader, lightenDarkenColor, formatFileSize, usePapaParse } from 'react-papaparse';
// import sample from '/assets/maildrip-recipient-sample.csv';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    // alignItems: 'center',
    // border: `2px dashed ${GREY}`,
    // borderRadius: 20,
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100%',
    // justifyContent: 'center',
    // padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: '100%',
    margin: '0 auto',
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

export default function CSVReader({ setCSVResult }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  return (
    <>
      <CSVReader
        onFileLoad={(e) => {
          console({ e });
        }}
        // onFileError={ ()=>{}}
        onUploadAccepted={(results) => {
          console.log('-------------------------');
          const [keys, ...values] = results.data;
          const convertResults = values.map((array) =>
            array.reduce((a, v, i) => {
              // console.log({ ts: keys[i].toLowerCase() });
              return { ...a, [keys[i].toLowerCase()]: v };
            }, {})
          );
          setCSVResult(convertResults.splice(0, convertResults.length - 1));
          // console.log({ ds: convertResults.splice(0, convertResults.length - 1) });
          console.log('-------------------------');
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
          <>
            <div
              {...getRootProps()}
              className="csvreader-container block h-32 w-full my-5 cursor-pointer fileUpload"
              style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                <div className="centered h-full aboutto_upload">
                  <p className="details block">
                    Attach files by dragging & dropping or <span className="primaryColor">selecting them</span>.
                  </p>
                  <p className="details block">Only .csv file type is supported.</p>
                </div>
              )}
            </div>
          </>
        )}
      </CSVReader>
      <div className="getstarted_samplesheet">
        <h5 className="mt-5 mb-4">Need help getting started?</h5>
        <div className="flex justify-between items-center flex-wrap sheet-link">
          <a href="#!" className="mr-4">
            View our import guide
          </a>
          <a
            // type="button"
            // onClick={() => window.open()}
            href="/assets/maildrip-recipient-sample.csv"
            download="Maildrip Contact Sample.csv"
          >
            Download sample spreadsheet
          </a>
        </div>
      </div>
    </>
  );
}
