import { Input } from 'antd';
import React, { useState } from 'react';

const { Search } = Input;

export const Subscribe = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="spacer subscribe">
      <div className="blueBg container2">
        <div className="header">
          <h1 className="bold">Start moving subscribers into your sales funnel with less effort</h1>
        </div>
        <div className="margin-t-b">
          <Search
            placeholder="Enter your email address"
            allowClear
            enterButton="Start Free"
            size="large"
            onSearch={() => setLoading(!loading)}
            loading={loading}
          />
        </div>
        <p>Test drive for 1 month FREE at no cost. No payment or credit card required. Export your list anytime</p>
      </div>
    </div>
  );
};
