import React from 'react';

const FormsTabContent = ({ img, title, desc, rightComp }) => (
  <div className="contentContain">
    <div className="left_disp">
      <img src={img} alt="" />
      <div>
        <span>{title}</span>
        <p>{desc}</p>
      </div>
    </div>
    <div className="right_disp">{rightComp}</div>
  </div>
);

const FormsTab = ({ data, campaignId }) => {
  return (
    <div className="formsTab">
      <FormsTabContent
        img="/assets/illustrate-landingpage.svg"
        title="Landing Page"
        desc="Build a landing page with signup form and seemlessly collect data to your campaign"
        rightComp={'rightComp'}
      />
      <FormsTabContent
        img="/assets/illustrate-embeddedform.svg"
        title="Embedded Form"
        desc="Build signup forms and generate HTML code to embed in your site"
        rightComp={'rightComp'}
      />
      <FormsTabContent
        img="/assets/illustrate-integrations.svg"
        title="Integrations"
        desc="Collect information from various applications using our integrations"
        rightComp={''}
      />
    </div>
  );
};

export default FormsTab;
