import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import emptyTableIcon from '../asset/images/profleimg1.svg';
import charisolLogo from '../asset/images/profleimg2.svg';
import defaultProfileImg from '../asset/images/profleimg3.svg';
import { me } from '../utils/service';

const CurrentCompView = createContext();

const CurrentCompanyViewContext = ({ children }) => {
  const { pathname } = useLocation();
  const fetchUserDetails = JSON.parse(localStorage?.getItem('userDetails'));
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(fetchUserDetails?.user);

  const getProfile = async () => {
    let data = await me();
    setUserDetails(data);
    setIsLoggedIn(true);
  };

  useEffect(() => {
    getProfile();
  }, [isLoggedIn, pathname]);

  const [currentCompanyView, setCurrentCompanyView] = useState({
    id: '1001',
    name: userDetails?.name,
    email: userDetails?.email,
    img: userDetails?.picture || '/assets/avatar.svg',
    members: [
      {
        id: '1',
        name: userDetails?.companyName || userDetails?.fullname,
        email: userDetails?.email,
        img: userDetails?.picture || '/assets/avatar.svg',
        status: 'admin',
      },
    ],
    teamsCreated: [
      {
        id: '10004',
        title: 'Technology',
        members: [
          {
            id: '1',
            name: 'Olayinka Omosehin',
            img: defaultProfileImg,
            email: 'uduak.jackson@charisol.io',
          },
          {
            id: '2',
            name: userDetails?.companyName || userDetails?.fullname,
            img: userDetails?.picture || '/assets/avatar.svg',
            email: userDetails?.email,
          },
          {
            id: '3',
            name: 'Goke Ancla',
            img: defaultProfileImg,
            email: 'uduak.jackson@charisol.io',
          },
        ],
      },
      {
        id: '10005',
        title: 'Depository',
        members: [
          {
            id: '4',
            name: 'Uche Ogudu',
            img: emptyTableIcon,
            email: 'uduak.jackson@charisol.io',
          },
          {
            id: '5',
            name: 'Ray M',
            img: defaultProfileImg,
            email: 'samuel.oshin@charisol.io',
          },
          {
            id: '6',
            name: 'Yewande Akerele',
            img: emptyTableIcon,
            email: 'info@charisol.io',
          },
        ],
      },
    ],
  });

  return (
    <CurrentCompView.Provider value={{ currentCompanyView, setCurrentCompanyView }}>
      {children}
    </CurrentCompView.Provider>
  );
};

export default CurrentCompanyViewContext;

export const CurrentCompViewState = () => {
  return useContext(CurrentCompView);
};
