import { Button, Carousel, Checkbox, Divider, Form, Input } from 'antd';
import Cookie from 'js-cookie';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'styles/loginPage.scss';
import { me, resendEmailVerificationRequest, setAuthToken } from 'utils/service';
import { login, logUserIn, reactivateAccount, requestReactivation } from 'utils/services/authService';
import { showToastMessage } from 'utils/Toast';
import GoogleAuthButton from './GoogleAuthButton';
import { errorMessage } from 'utils/getErrors';

export const CarouselSlider = () => {
  return (
    <div className="w-50 carouselArea">
      <Carousel autoplay>
        <div className="carousel">
          <div className="centered ">
            <div className="carouselContent textCenter">
              <img src="/assets/loginCamp1.png" className="campagineImg" alt="campagne"></img>
              <h1>Schedule drip emails to deliver at the right time</h1>
              <p>Set pre-written emails to send email series daily, weekly, or monthly.</p>
            </div>
          </div>
        </div>
        <div className="carousel">
          <div className="centered ">
            <div className="carouselContent textCenter">
              <img src="/assets/loginCamp2.png" className="campagineImg" alt="campagne"></img>
              <h1>Monitor the send, open and click rates</h1>
              <p>Keep track of all your campaigns on Maildrip conveniently without breaking a sweat.</p>
            </div>
          </div>
        </div>
        <div className="carousel">
          <div className="centered ">
            <div className="carouselContent textCenter">
              <img src="/assets/loginCamp3.png" className="campagineImg" alt="campagne"></img>
              <h1>Segments to your campaigns</h1>
              <p>Set pre-written emails to send email series daily, weekly, or monthly.</p>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onFinish = (values) => {
    setLoading(true);
    login(values)
      .then(async ({ data: { success, verified, token } }) => {
        setLoading(false);
        if (success && verified) {
          await logUserIn(token, history);
        } else if (success && !verified) {
          resendEmailVerificationRequest(values);
          history.replace('/verify', {
            email: values.email,
            resendVerificationMail: true,
          });
        }
      })
      .catch((error) => {
        (async () => {
          const err = error?.response?.data;
          console.log({ err });
          if (err.error_code === 'account_deactivated') {
            try {
              await reactivateAccount(err.email, history);
            } catch (error) {
              console.log({ error });
            }
          } else {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: err?.message || err?.error,
            });
          }
        })();

        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer">
        <div className="loginSections flex">
          <CarouselSlider />
          <div className="w-50 formArea">
            <div className="centered formContent">
              <div className="container2">
                <Link to={{ pathname: 'https://maildrip.io/' }} target="_blank" className="flex-jst-right mb-5">
                  <img src="/assets/smallLogo.svg" alt="company logo"></img>
                </Link>
                <div className="margin-t-b">
                  <h1 className="bold title mb-2">Login</h1>
                  <p className="paragraph">Welcome back, you know the drill!</p>
                </div>
                <div className="centered margin-t-b">
                  <GoogleAuthButton>Sign in with Google</GoogleAuthButton>
                </div>
                <div className="margin-t-b">
                  <Divider plain>Or</Divider>
                </div>
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                        type: 'email',
                      },
                    ]}
                  >
                    <Input placeholder="email" />
                  </Form.Item>

                  <Form.Item
                    // label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password placeholder="password" />
                  </Form.Item>
                  <Link to="/forgot-password" className="text-primary text-sm hover:text-primary  hover:underline">
                    Forgot my password
                  </Link>

                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Log In
                    </Button>
                  </Form.Item>
                </Form>
                {/* <Link to="/"> */}
                <p>
                  Don't have an account?
                  <Link to="/signup" className="primaryColorLight ml-2">
                    Sign Up
                  </Link>
                </p>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
