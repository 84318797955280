import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/forgotPassword.scss';
import { forgotPasswordRequest } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';
import { CarouselSlider } from './Login';
export const ForgotPasswordPage = () => {
  const [imLoading, setImLoading] = useState({ id: '' });

  const resetPassword = (email) => {
    setImLoading({ id: 'sendingPasswordResetLink' });
    forgotPasswordRequest(email)
      .then((res) => {
        console.log({ res });
        showToastMessage({
          type: 'success',
          title: 'Request Successful',
          description: res.data.data.message,
        });
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Request Failed',
          description: error?.response?.data?.error,
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer forgotPasswordContainer">
        <div className="loginSections flex">
          <CarouselSlider />
          <div className="w-50 formArea">
            <div className="centered formContent">
              <div className="container2 flex flex-col justify-between">
                <div className="flex-jst-right mb-5">
                  <Link to="/">
                    <img alt="logo" src="/assets/smallLogo.svg"></img>
                  </Link>
                </div>
                <div>
                  <div className="margin-t-b">
                    <h1 className="bold title mb-2">Reset Password</h1>
                    <p className="paragraph">Welcome back, you know the drill!</p>
                  </div>

                  <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={resetPassword}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input a valid email!',
                          type: 'email',
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Please input your registered email address" />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={imLoading.id === 'sendingPasswordResetLink'}>
                        Reset
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
