import React, { useContext, useState } from 'react';
import { paymentContext } from 'contexts/payment';
import { SET_PLAN, SET_PRICE, SET_QUANTITY, SET_TRX_TYPE } from 'contexts/payment/reducer/types';
import './style.scss';
import { emailCreditInNaira, paygCreditInNaira, trxType } from 'utils/constants';

export default function PAYG() {
  const [price, setPrice] = useState(0);
  const [qv, setQv] = useState('');

  const {
    state: { plan, quantity },
    dispatch,
  } = useContext(paymentContext);

  return (
    <div className="payGoContainer">
      <div className="flex-space-btw desc">
        <p>PAYG (Pay-As-You-Go)</p>
      </div>
      <div
        className={`mt-3 payInputSection ${Boolean(plan.payg) ? 'selected_card__border' : ''}`}
        onClick={() => {
          dispatch({ type: SET_PLAN, payload: { _id: null, payg: true } });
          dispatch({ type: SET_PRICE, payload: quantity * paygCreditInNaira });
          dispatch({ type: SET_TRX_TYPE, payload: trxType.payg });
        }}
      >
        <p className="__microcopy">How many email credit do you want to buy?</p>

        <div className="flex-space-btw">
          <div className="__desc ">
            <p>Enter quantity</p>
            <input
              className="mt-2 PayGoPaymentInput"
              type="text"
              placeholder="0"
              // To be safe 😁
              maxLength={5}
              value={qv}
              onChange={({ target: { value } }) => {
                const val = value.replace(RegExp(/[^0-9]/g), '');
                setQv(val);
                dispatch({ type: SET_QUANTITY, payload: val });
                dispatch({ type: SET_PRICE, payload: val * paygCreditInNaira });
                setPrice(val * paygCreditInNaira);
              }}
            />
          </div>

          <div className="payGoPriceDisplay centered">
            <h3>₦{price.toFixed(2)}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
