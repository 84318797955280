import React from 'react';
import './style.scss';
export default function TransactionLoading() {
  return (
    <div className="trx_loading">
      <svg className="spin" width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M26.9517 15.9998H31.3327M15.9993 0.666504V5.04746V0.666504ZM26.9517 5.04746L23.666 8.33317L26.9517 5.04746ZM5.04697 5.04746L8.33268 8.33317L5.04697 5.04746ZM15.9993 31.3332V26.9522V31.3332ZM26.9517 26.9522L23.666 23.6665L26.9517 26.9522ZM5.04697 26.9522L8.33268 23.6665L5.04697 26.9522ZM0.666016 15.9998H5.04697H0.666016Z"
          stroke="#273DF2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
