import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory, useLocation } from 'react-router-dom';
import '../../styles/unverifiedPage.scss';
import { resendEmailVerificationRequest } from '../../utils/service';
import { emailVerification } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';
import { CarouselSlider } from './Login';
export const VerifyUser = () => {
  const [imLoading, setImLoading] = useState({ id: '' });

  const [otp, setOtp] = useState('');

  const history = useHistory();
  const location = useLocation();
  const { email, resendVerificationMail } = location?.state;

  useEffect(() => {
    if (resendVerificationMail) {
      requestVerificationMail(email);
    }
  }, []);

  const handleSubmit = () => {
    setImLoading({ id: 'verifyingAccount' });
    emailVerification(otp)
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Account',
          description: 'Account verified',
        });
        history.push('/login');
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response?.data?.error || 'Unable to process your',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };
  const requestVerificationMail = () => {
    setImLoading({ id: 'resendingVerificationLink' });
    resendEmailVerificationRequest({
      email,
    })
      .then(() => {
        showToastMessage({
          type: 'success',
          title: 'Verification',
          description: 'Verification code sent 📬',
        });
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: error.response?.data?.error || error.message || 'Unable to process your request',
        });
      })
      .finally(() => {
        setImLoading({ id: '' });
      });
  };

  return (
    <div className="emmaccen">
      <div className="loginPageContainer">
        <div className="loginSections flex">
          <CarouselSlider />
          <div className="verifySectionContainer centered">
            <div className="verifySectionInnerContainer">
              <h3>Verify Your Account</h3>
              <div className="textWrapper">
                <p>
                  We sent a mail to <span className="primaryColorLight">{email}</span>. Copy the code and paste it here
                  to verify your account.{' '}
                  <span className="descriptionTextColor">
                    {' '}
                    (Didn't get it? Check your spam folder incase it landed there)
                  </span>
                </p>
              </div>
              <div className="centered pt-10 pb-16">
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={5}
                  inputStyle={{
                    borderBottom: '3px solid #E0E2E9',
                    fontSize: '32px',
                    fontWeight: 600,
                    marginRight: '5px',
                    width: '40px',
                    color: 'black',
                  }}
                  focusStyle={{
                    borderBottom: '3px solid rgba(39, 61, 242, 0.37)',
                  }}
                />
              </div>

              <div className="flex flex-wrap justify-between pb-5 resendCodeContainer">
                <p>
                  It may take a minute to receive your code. Haven't recieved it?
                  <span onClick={requestVerificationMail} className="pointer primaryColorLight pl-3">
                    {imLoading.id === 'resendingVerificationLink' ? 'Sending...' : 'Send a new code.'}
                  </span>
                </p>
                <div className="mt-3 flex justify-end w-100">
                  <Button
                    loading={imLoading.id === 'verifyingAccount'}
                    disabled={otp.length < 5}
                    type="primary"
                    size="large"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
