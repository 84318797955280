import { CheckOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';

const { Search } = Input;

export const Subscribers = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="spacer subscribers">
      <div className="container2">
        <div className="pad">
          <div className="grid2">
            <div>
              <div className="header">
                <h1 className="usePurple bold margin-t-b">Nurture subscribers into customers with ease</h1>
                <p className="margin-t-b">
                  You save time by automating frequent emails so you can focus on productive tasks
                </p>
                <div className="margin-t-b">
                  <div className="checkList">
                    <div className="flex">
                      <div className="centered">
                        <CheckOutlined />
                      </div>
                      <p>Clean and simple interface that is easy to use</p>
                    </div>
                    <div className="flex">
                      <div className="centered">
                        <CheckOutlined />
                      </div>
                      <p>Start a campaign quickly and easily - no setup required</p>
                    </div>
                    <div className="flex">
                      <div className="centered">
                        <CheckOutlined />
                      </div>
                      <p>Get messages in your GSuit email</p>
                    </div>
                    <div className="flex">
                      <div className="centered">
                        <CheckOutlined />
                      </div>
                      <p>Schedule multiple emails in one campaign</p>
                    </div>
                    <div className="flex">
                      <div className="centered">
                        <CheckOutlined />
                      </div>
                      <p>Segment subscribers within your list</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="centered">
              <div>
                <div className="margin-t-b">
                  <Search
                    placeholder="Enter your email address"
                    allowClear
                    enterButton="Start Free"
                    size="large"
                    onSearch={() => setLoading(!loading)}
                    loading={loading}
                  />
                </div>
                <p>
                  Test drive for 1 month FREE at no cost. No payment or credit card required. Export your list anytime
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
