import { notification } from 'antd';
import axios from 'axios';
import Cookie from 'js-cookie';
import juice from 'juice';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { BreadCrumb } from '../../components/emmaccen/sharedComponents/BreadCrumb';
import { EditorActionsTab } from '../../components/emmaccen/sharedComponents/EditorActionsTab';
import { errorMessage } from '../../utils/getErrors';
import { getSingleEmailById, saveDraftEmail, uploadFile } from '../../utils/service';
import { showToastMessage } from '../../utils/Toast';
import { grapesInit } from './config/grapesInit';
import './editor.scss';

export const API_HOST = process.env.REACT_APP_API_URL;

const Editor = ({ location }) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imLoading, setImLoading] = useState({ id: '' });
  const mailId = location?.state?.mailId;
  const [wantSave, setWantSave] = useState(false);
  const [editorState, setEditorState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mail, setMail] = useState({
    subject: '',
    body: '',
    // css: "",
    number: null,
    seconds: null,
    typeOfMail: 'maildrip-editor',
    emailInterval: '',
    attachments: [],
    order: 0,
  });
  const token = Cookie.get('token');
  const { campaignId, campaignMailId } = useParams();
  const history = useHistory();

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: `< Back`,
      link: `/campaign/${campaignId}/emails`,
      index: 0,
    },
  ]);

  useEffect(() => {
    (async function () {
      const response = await axios.get(`/campaigns/${campaignId}`, {
        'x-access-token': token,
      });
      const campaign = response.data.data;
      const pages = campaign.emails;
      const [fetchedMail] = pages.filter((f) => {
        if (f._id === campaignMailId) {
          return f;
        }
        return false;
      });
      setFiles(
        fetchedMail?.attachments?.map(({ src, filename }, index) => {
          return { src, filename, status: 'UPLOADED', id: index };
        })
      );
      if (fetchedMail?.content) {
        const inlineCode = juice.inlineContent(fetchedMail?.content['gjs-html'], fetchedMail?.content['gjs-css']);
        setMail((prevData) => {
          return { ...prevData, ...fetchedMail, id: fetchedMail?._id, body: inlineCode };
        });
      } else {
        setMail((prevData) => {
          return { ...prevData, ...fetchedMail, id: fetchedMail?._id };
        });
      }
    })();
  }, []);
  async function getAllAssets() {
    try {
      const response = await axios.get(`/campaigns/wb/${campaignId}/${campaignMailId}/assets`);
      return response.data;
    } catch (error) {
      // setAssets(error.message);
    }
  }

  useEffect(() => {
    let assets = [];
    (async function () {
      assets = await getAllAssets();

      const parserCss = (css, editor) => {
        const result = [];
        // ... parse the CSS string
        result.push({
          selectors: `
              @font-face {
                font-family: 'Inter', sans-serif;
                src: url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
              }
              `,
          style: { color: 'red' },
        });
        // ...
        return result; // Result should be ALWAYS an array
      };

      const edit = grapesInit(assets, campaignId, campaignMailId, token);
      setEditorState(edit);
      const head = edit.Canvas.getDocument().head;
      head.insertAdjacentHTML(
        'beforeend',
        `<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">`
      );

      let I18nManager = edit.I18n;
      I18nManager.addMessages({
        en: {
          domComponents: {
            // overriding the default Table, Table Cell
            names: {
              table: 'Components',
              cell: 'Elements',
            },
          },
          selectorManager: {
            // overriding the default Classes, Selected
            label: 'Selector',
            selected: 'Element Inheriting',
          },
        },
      });

      edit.on('load', function () {
        const prop = edit.StyleManager.getProperty('typography', 'font-family');
        // prop.set('options', [{ value: "'Inter', sans-serif", name: 'Inter' }]);
        var list = [];
        prop.set('list', list);
        prop.addOption({ id: 'Inter', label: 'Inter' });
        prop.set('list', list);
        prop.set('default', 'Inter');
        prop.set('defaults', 'Inter');

        // edit.StyleManager.render();

        //open import template modAL when a query === 'upload-code'
        const modal = edit.Modal;
        const query = new URLSearchParams(location.search);
        const uploadQuery = query.get('key');
        if (uploadQuery === 'upload-code') {
          const importClick = document.querySelector('.gjs-pn-btn.fa.fa-download');
          importClick.click();
          edit.on('modal', (a, b, c) => {
            if (a.open && a.title.textContent === 'Import template') {
              modal.setTitle('<div>Import your Template [inline-html/css]</div>');
            }
          });
        }
      });
      // const catProps = edit.StyleManager;

      //Edit default input text to none
      // edit.on('change:editing', (em, editing) => {
      //   if (editing) {
      //     const cmp = edit.getSelected();
      //     const el = cmp && cmp.getEl();
      //     if (el && el.innerText === 'Insert your text here') el.innerText = '';
      //   }
      // });
      edit.on('storage:load', function (e) {
        console.log('Loaded ', e);
      });
      edit.on('storage:store', function (e) {
        console.log('Stored ', e);
      });

      edit.on('asset:remove', async (e) => {
        await axios.delete(`/campaigns/wb/${campaignId}/${campaignMailId}/assets?key=${e?.attributes?.setid}`);
      });
    })();
  }, []);
  useEffect(() => {
    mailId &&
      getSingleEmailById(campaignId, mailId)
        .then(({ data: { data } }) => {
          setFiles(
            data?.email?.attachments.map((data, index) => {
              return data;
            })
          );
          setMail({ ...mail, ...data.email });
        })
        .catch((error) => {
          openNotification('ERROR', 'Something went wrong, please try again later.');
        });
  }, []);

  useEffect(() => {
    isUploading &&
      files.forEach((file) => {
        file.status !== 'UPLOADED' &&
          uploadFile(file.file, file.id)
            .then(
              ({
                data: {
                  data: { id, location, filename, status },
                },
              }) => {
                updateFileState(id, status, location, filename);
              }
            )
            .catch((err) => {
              updateFileState(file.id, 'ERROR', file.src, file.filename);
            })
            .finally(() => {
              setIsUploading(false);
            });
      });
  }, [isUploading]);

  const updateFileState = (id, status, location, filename) => {
    setFiles((previousFiles) => {
      return previousFiles.map((file) => (file.id === id ? { ...file, status, src: location, filename } : file));
    });
  };

  const openNotification = (message, description) => {
    notification.info({
      message,
      description,
    });
  };

  const saveDraft = () => {
    setImLoading({ id: 'draftingEmail' });
    editorState.store((res) => {});
    editorState.on('storage:store', async function (e) {
      const inlineCode = juice.inlineContent(e.html, e.css);
      const attachments = files?.filter((file) => file.status !== 'ERROR').map((file) => file);

      setMail((prevData) => {
        return {
          ...prevData,
          body: inlineCode,
          attachments,
        };
      });
      await axios.patch(`/campaigns/${campaignId}/${campaignMailId}`, {
        ...mail,
        body: inlineCode,
        attachments,
      });
      console.log(mail);
      saveDraftEmail({ campaignId, mail, setImLoading })
        .then((mail) => {
          console.log(mail);
          showToastMessage({
            type: 'success',
            title: 'Draft Email',
            description: 'Your mail has been saved as draft',
          });
          history.replace(`/campaign/${campaignId}/emails`);
        })
        .catch((error) => {
          const errMsg = errorMessage(error);
          console.log(mail);
          console.log(errMsg);
          showToastMessage({
            type: 'error',
            title: 'Error',
            description: errMsg,
          });
        });
    });
  };
  const emailPageUpdateFully = useCallback(() => {
    setWantSave(true);
    setIsLoading(true);
    setImLoading({ id: 'emailAction' });
    editorState.store((res) => {});
    editorState.on('storage:store', async function (e) {
      const inlineCode = juice.inlineContent(e.html, e.css);
      const attachments = files?.filter((file) => file.status !== 'ERROR').map((file) => file);

      setMail((prevData) => {
        return {
          ...prevData,
          body: inlineCode,
        };
      });
      await axios.patch(`/campaigns/${campaignId}/${campaignMailId}`, {
        ...mail,
        body: inlineCode,
        attachments,
      });
      showToastMessage({
        type: 'success',
        title: 'Saved',
        description: 'Mail template saved successfully',
      });
      window.location.replace(`/campaign/${campaignId}/${mail?._id}/mailtemplate-confirm`);
      setImLoading({ id: '' });
    });
  }, [editorState, mail, files]);

  return (
    <>
      <div className="emmaccen">
        <div className="pt-8 grapesForMobileWidth">
          <div className="flex flex-space-btw align-items-center mb-5 editorHeaderTab">
            <BreadCrumb crumbs={breadCrumbs}></BreadCrumb>
            <EditorActionsTab
              imLoading={imLoading}
              setImLoading={setImLoading}
              campaignId={campaignId}
              setMail={setMail}
              saveDraft={saveDraft}
              mail={mail}
              handleEmailCreation={emailPageUpdateFully}
              filesProps={[files, setFiles, setIsUploading]}
            />
          </div>
          <div className="borderShade1 p-1">
            <div className="flex flex-row panel__top">
              <div className="panel__editor"></div>
            </div>
            <div id="gjs">
              <h1>Hello World</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editor;
