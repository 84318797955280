import { MenuOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Menu } from 'antd';
import Cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CurrentCompViewState } from '../../../contexts/CurrentCompanyViewContext';
import { firstLetterCaps, trunacteString } from '../../../utils/generics';
import { ReactComponent as ArrowDownIcon } from '../../../asset/Icons/arrow-down.svg';
import { getWidth } from '../shared-functions/getSideBarWidth';
import emptyTableIcon from '../../../asset/images/profleimg1.svg';
import charisolLogo from '../../../asset/images/profleimg2.svg';
import defaultProfileImg from '../../../asset/images/profleimg3.svg';
// import maildripLogo from '/assets/logo.svg';

export const AuthNav = ({ data: userDetails, activeSideBar, setActiveSideBar }) => {
  const [isOpen, setIsOpen] = useState(true);
  // const [mockedSharedTeamofTeams, setMockedSharedTeamofTeams] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];
  const { currentCompanyView, setCurrentCompanyView } = CurrentCompViewState();

  const logout = () => {
    Cookie.remove('token');
    localStorage.removeItem('userDetails');
    history.push('/login');
  };
  const mockedSharedTeamofTeams = [
    {
      id: '1001',
      name: userDetails?.name,
      email: userDetails?.email,
      img: userDetails?.picture ?? '/assets/avatar.svg',
      members: [
        {
          id: '12',
          name: userDetails?.fullname || userDetails?.companyName,
          img: userDetails?.picture ?? '/assets/avatar.svg',
          email: userDetails?.email,
          status: 'admin',
        },
      ],
      teamsCreated: [
        {
          id: '10004',
          title: 'Technology',
          members: [
            {
              id: '1',
              name: 'Olayinka Omosehin',
              img: defaultProfileImg,
              email: 'uduak.jackson@charisol.io',
            },
            {
              id: '2',
              name: userDetails?.companyName || userDetails?.fullname,
              img: userDetails?.picture ?? '/assets/avatar.svg',
              email: userDetails?.email,
            },
            {
              id: '3',
              name: 'Goke Ancla',
              img: defaultProfileImg,
              email: 'uduak.jackson@charisol.io',
            },
          ],
        },
        {
          id: '10005',
          title: 'Depository',
          members: [
            {
              id: '4',
              name: 'Uche Ogudu',
              img: emptyTableIcon,
              email: 'uduak.jackson@charisol.io',
            },
            {
              id: '5',
              name: 'Ray M',
              img: defaultProfileImg,
              email: 'samuel.oshin@charisol.io',
            },
            {
              id: '6',
              name: 'Yewande Akerele',
              img: emptyTableIcon,
              email: 'info@charisol.io',
            },
          ],
        },
      ],
    },
    // {
    //   id: '1002',
    //   name: 'Charisol Incorporation',
    //   email: 'info@chairol.io',
    //   img: charisolLogo,
    //   members: [
    //     {
    //       id: '1',
    //       name: 'Charisol LTD',
    //       img: charisolLogo,
    //       email: 'info@charisol.io',
    //       status: 'admin',
    //     },
    //     {
    //       id: '2',
    //       name: 'Uduak Jackson',
    //       img: emptyTableIcon,
    //       email: 'uduak.jackson@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       id: '3',
    //       name: 'Samuel Jackson',
    //       img: defaultProfileImg,
    //       email: 'samuel.oshin@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       id: '4',
    //       name: 'Lovejoy Okoye',
    //       img: emptyTableIcon,
    //       email: 'info@charisol.io',
    //       status: 'admin',
    //     },
    //     {
    //       id: '5',
    //       name: 'Oluwafemi Sosanya',
    //       img: charisolLogo,
    //       email: 'femi.sosanya@charisol.io',
    //       status: 'coAdmin',
    //     },
    //     {
    //       key: '6',
    //       name: 'Ray M',
    //       img: defaultProfileImg,
    //       email: 'samuel.oshin@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       key: '7',
    //       name: 'Yewande Akerele',
    //       img: emptyTableIcon,
    //       email: 'info@charisol.io',
    //       status: 'coAdmin',
    //     },
    //     {
    //       key: '8',
    //       name: 'Olayinka Omosehin',
    //       img: defaultProfileImg,
    //       email: 'uduak.jackson@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       key: '9',
    //       name: 'Uche Ogudu',
    //       img: emptyTableIcon,
    //       email: 'uduak.jackson@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       key: '10',
    //       name: 'Goke Ancla',
    //       img: defaultProfileImg,
    //       email: 'uduak.jackson@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       key: '11',
    //       name: 'Mayowa Adegunwa',
    //       img: emptyTableIcon,
    //       email: 'samuel.oshin@charisol.io',
    //       status: 'member',
    //     },
    //   ],
    //   teamsCreated: [
    //     {
    //       id: '10001',
    //       title: 'Marketing',
    //       members: [
    //         {
    //           id: '1',
    //           name: 'Charisol LTD',
    //           img: charisolLogo,
    //           email: 'info@charisol.io',
    //         },
    //         {
    //           id: '2',
    //           name: 'Uduak Jackson',
    //           img: emptyTableIcon,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //         {
    //           id: '3',
    //           name: 'Samuel Jackson',
    //           img: defaultProfileImg,
    //           email: 'samuel.oshin@charisol.io',
    //         },
    //         {
    //           id: '4',
    //           name: 'Lovejoy Okoye',
    //           img: emptyTableIcon,
    //           email: 'info@charisol.io',
    //         },
    //       ],
    //     },
    //     {
    //       id: '10002',
    //       title: 'Recruiting',
    //       members: [
    //         {
    //           id: '5',
    //           name: 'Uche Ogudu',
    //           img: emptyTableIcon,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //         {
    //           id: '6',
    //           name: 'Ray M',
    //           img: defaultProfileImg,
    //           email: 'samuel.oshin@charisol.io',
    //         },
    //         {
    //           id: '7',
    //           name: 'Yewande Akerele',
    //           img: emptyTableIcon,
    //           email: 'info@charisol.io',
    //         },
    //       ],
    //     },
    //     {
    //       id: '10003',
    //       title: 'Engineering',
    //       members: [
    //         {
    //           id: '8',
    //           name: 'Olayinka Omosehin',
    //           img: defaultProfileImg,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //         {
    //           id: '9',
    //           name: userDetails?.companyName || userDetails?.fullname,
    //           img: userDetails?.picture ?? '/assets/avatar.svg',
    //           email: userDetails?.email,
    //         },
    //         {
    //           id: '10',
    //           name: 'Goke Ancla',
    //           img: defaultProfileImg,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: '1003',
    //   name: 'Verb Travels & Tour',
    //   email: 'beep@verbmerchant.travel',
    //   img: '/assets/logo.svg',
    //   members: [
    //     {
    //       id: '13',
    //       name: 'Uche Ogudu',
    //       img: emptyTableIcon,
    //       email: 'uduak.jackson@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       id: '14',
    //       name: 'Ray M',
    //       img: defaultProfileImg,
    //       email: 'samuel.oshin@charisol.io',
    //       status: 'member',
    //     },
    //     {
    //       id: '15',
    //       name: 'Yewande Akerele',
    //       img: emptyTableIcon,
    //       email: 'info@charisol.io',
    //       status: 'coAdmin',
    //     },
    //     {
    //       id: '16',
    //       name: 'Oluwafemi Sosanya',
    //       img: charisolLogo,
    //       email: 'femi.sosanya@charisol.io',
    //       status: 'member',
    //     },
    //   ],
    //   teamsCreated: [
    //     {
    //       id: '10004',
    //       title: 'Technology',
    //       members: [
    //         {
    //           id: '1',
    //           name: 'Olayinka Omosehin',
    //           img: defaultProfileImg,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //         {
    //           id: '2',
    //           name: userDetails?.companyName || userDetails?.fullname,
    //           img: userDetails?.picture ?? '/assets/avatar.svg',
    //           email: userDetails?.email,
    //         },
    //         {
    //           id: '3',
    //           name: 'Goke Ancla',
    //           img: defaultProfileImg,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //       ],
    //     },
    //     {
    //       id: '10005',
    //       title: 'Depository',
    //       members: [
    //         {
    //           id: '4',
    //           name: 'Uche Ogudu',
    //           img: emptyTableIcon,
    //           email: 'uduak.jackson@charisol.io',
    //         },
    //         {
    //           id: '5',
    //           name: 'Ray M',
    //           img: defaultProfileImg,
    //           email: 'samuel.oshin@charisol.io',
    //         },
    //         {
    //           id: '6',
    //           name: 'Yewande Akerele',
    //           img: emptyTableIcon,
    //           email: 'info@charisol.io',
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];

  const currentCompanyViewHandler = (companyViewId) => {
    const compSelected = mockedSharedTeamofTeams.filter((comp) => comp.id === companyViewId);
    setCurrentCompanyView(compSelected[0]);
  };

  const profileDropdown = (
    <Menu className="emmaccen" style={{ width: '273px' }} defaultSelectedKeys="1">
      {mockedSharedTeamofTeams.map((mkShared) => (
        <Menu.Item className="menuItem" key={mkShared.id} onClick={() => currentCompanyViewHandler(mkShared.id)}>
          <div className="profileDropdown">
            <div className="flex item-center accountDetails">
              <div className="centered">
                <img className="mr-3" src={mkShared.img} alt=""></img>
              </div>
              <div className="flex flex-col">
                <span>{mkShared.name}</span>
                <p>{`${mkShared.members.length} ${mkShared.members.length <= 1 ? 'member' : 'members'}`}</p>
              </div>
            </div>
          </div>
        </Menu.Item>
      ))}
      <Menu.Item key="1104">
        <Divider style={{ margin: '0px' }} />
      </Menu.Item>
      <Menu.Item className="menuItem" key="1101">
        <Link className="flex" to="/settings/account">
          <span>Settings & Plans</span>
        </Link>
      </Menu.Item>
      <Menu.Item className="menuItem" key="1102">
        <Link className="flex" to={{ pathname: 'https://maildrip.io/help/' }} target="_blank">
          <span>Help Center</span>
        </Link>
      </Menu.Item>
      <Menu.Item className="menuItem" danger key="1103">
        <a className="flex " style={{ textDecoration: 'none' }} onClick={logout}>
          <span>Log Out</span>
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <nav className="authNavBar" style={{ width: `calc(100% - ${getWidth(activeSideBar)})` }}>
      <div className="content flex-space-btw">
        <div className="mobileController flex">
          <div className="flex-space-btw">
            <div className="flex logo_container-desktop">
              <img className="logo" src="/assets/logo.svg" alt="logo"></img>
              <div className="centered">
                <span className="title ml-1 font18">MailDrip</span>
              </div>
            </div>
            <div
              onClick={() => setActiveSideBar({ ...activeSideBar, topNavOpen: !activeSideBar.topNavOpen })}
              className="centered pointer hamburger"
            >
              <MenuOutlined />
            </div>
          </div>
          {activeSideBar.topNavOpen && (
            <div className="centered navLinks">
              <div id="mobileNav" className="flex">
                <span className="capitalize clt">{currentLocation.split('-').join(' ')}</span>
                {/* <Link to={"./"}>Contact</Link> */}
              </div>
            </div>
          )}
        </div>
        {activeSideBar.topNavOpen && (
          <div className="centered navLinks">
            <div id="mobileNav" className="flex items-center">
              {/* <Link to={'/campaigns'}>Campaign</Link>
              <div className="flex align-items-center">
                <Link className="" to={'/contacts'}>
                  Contact
                  <span className="tomato ml-2">new</span>
                </Link>
              </div> */}
              <div className="flex align-items-center">
                <Link className="whats_new flex align-items-center" to={'/whats-new'}>
                  <img alt="whahts-new" src="/assets/fluent_megaphone-16-filled.svg" />
                  What's New
                </Link>
              </div>
              <Dropdown overlay={profileDropdown}>
                <div className="flex pointer current_loggedin_user items-center" onClick={(e) => e.preventDefault()}>
                  <img
                    className="logo radius-50"
                    src={currentCompanyView?.img ?? '/assets/avatar.svg'}
                    alt="user"
                  ></img>
                  <div title={currentCompanyView.name}>
                    <p>{currentCompanyView.name}</p>
                    <p>{trunacteString(userDetails.email, 18)}</p>
                  </div>
                  <ArrowDownIcon />
                </div>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
