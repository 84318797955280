export const onSystemFileSelected = (e, files = [], setFiles, setIsUploading) => {
  let allFiles = [];
  const handleFile = (loadedFiles) => {
    setFiles([...files, ...loadedFiles]);
  };
  const selectedFiles = e.target.files;
  if (selectedFiles.length === 0) {
    console.log('No file chosen.');
  } else {
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push({
        file: selectedFiles[i],
        id: String(Date.now() + Math.random() * 1000),
        src: `${URL.createObjectURL(selectedFiles[i])}`,
        status: 'WAITING',
        filename: selectedFiles[i].name,
      });
    }
  }
  console.log({ allFiles });
  console.log({ files });
  handleFile(allFiles);
  setIsUploading(true);
};
