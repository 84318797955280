import React, { useContext, useEffect, useState } from 'react';
import { Divider } from 'antd';
import './style.scss';
import { getPlansRequest } from 'utils/service';
import PAYG from 'components/Payment/PaygEl';
import { paymentContext } from 'contexts/payment';
import { GET_USER_CARDS, SET_CARDS, SET_LOADING, SET_PLAN, SET_PLANS, SET_PRICE } from 'contexts/payment/reducer/types';
import PlansSection from 'components/Payment/PlansSection';
import CheckoutCard from 'components/Payment/CheckoutCard';
import LoadingScreen from 'components/LoadingScreen';

export default function PayComponent() {
  const {
    state: { loading },
    dispatch,
  } = useContext(paymentContext);

  useEffect(() => {
    (async () => {
      dispatch({ type: SET_LOADING, payload: true });
      const {
        data: { data },
      } = await getPlansRequest();

      dispatch({ type: SET_PLANS, payload: data });
      // dispatch({ type: SET_CARDS, payload: cards });
      dispatch({ type: SET_LOADING, payload: false });
    })();
  }, []);

  return (
    <div className="emmaccen">
      <div className="paymentPageContainer">
        <p className="desc">Select a plan that’s just right for you</p>
        <Divider />

        {loading ? (
          <LoadingScreen />
        ) : (
          <div className="payment_container">
            <div className="payment_container__grid">
              <div className="payment_container__plans">
                <PlansSection />
                <div className="divider_wrapper mx-auto">
                  <Divider>OR</Divider>
                </div>{' '}
                <PAYG />
              </div>
              <CheckoutCard />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
