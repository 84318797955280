import { Tooltip } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as CampaignIcon } from '../../../asset/Icons/campaignIcon.svg';
import { ReactComponent as SidbarCollapsableIcon } from '../../../asset/Icons/Component 27/iconoir_sidebar-collapse.svg';
import { ReactComponent as ContactsIcon } from '../../../asset/Icons/contactsIcon.svg';
import { getWidth } from '../shared-functions/getSideBarWidth';
import '../../../styles/sidebar.scss';
const sidebarNavItems = [
  // {
  //   display: 'Dashboard',
  //   icon: <HomeIcon />,
  //   to: '#',
  // },
  {
    display: 'Campaign',
    icon: <CampaignIcon />,
    to: '/campaigns',
  },
  // {
  //   display: 'Emails',
  //   icon: <EmailIcon />,
  //   to: '#',
  // },
  {
    display: 'Contact',
    icon: <ContactsIcon />,
    to: '/contacts',
  },
];
const Sidebar = ({ activeSideBar, setActiveSideBar }) => {
  const location = useLocation();
  const locationName = location.pathname;

  return (
    <div
      className="sidebar"
      style={{ width: getWidth(activeSideBar), display: activeSideBar.topNavOpen ? '' : 'none' }}
    >
      <div>
        <div className="sidebar__logo" style={{ width: () => activeSideBar.getWidth() }}>
          <img className="logo " src="/assets/logo.svg" alt="logo"></img>
          <div className="centered">
            <span className={`logo-title ${activeSideBar.active ? 'revealMe' : 'hideMe'}`}>MailDrip</span>
          </div>
        </div>
        <div className="sidebar__menu">
          <Tooltip
            placement="rightTop"
            title={`${!activeSideBar.active ? 'Hide navigation' : 'Open navigation'}`}
            arrowPointAtCenter
          >
            <div>
              <button
                className="mr-3"
                onClick={() => setActiveSideBar({ ...activeSideBar, active: !activeSideBar.active })}
              >
                <SidbarCollapsableIcon />
              </button>
            </div>
          </Tooltip>
          {sidebarNavItems.map((item, index) => (
            <Link to={item.to} key={index}>
              <Tooltip placement="rightTop" title={item.display} arrowPointAtCenter>
                <div
                  className={`sidebar__menu__item ${locationName === item.to && 'active'}`}
                  style={{ width: '100%' }}
                >
                  <div className="sidebar__menu__item__icon">
                    {/* <img src={item.icon} alt="" /> */}
                    {item.icon}
                  </div>
                  <div className={`sidebar__menu__item__text ${activeSideBar.active ? 'revealMe' : 'hideMe'}`}>
                    {item.display}
                  </div>
                </div>
              </Tooltip>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
