/**
 * Convert API error response.data.fieldError into key value i.e {error: message}
 */
export default function getErrors(fieldsError) {
  const fields = Object.keys(fieldsError);
  const errors = {};

  for (let field of fields) {
    errors[field] = fieldsError[field].message;
  }

  return errors;
}

// Extract 4xx and 5xx errors

export const errorMessage = (error, customErrorMessage) => {
  if (!error) {
    throw Error('error is not defined!');
  }
  console.log(error);
  const err = error?.response?.data;
  const errorMessage = err.message || err.error || customErrorMessage || 'Sorry, an error occured! Please try again';
  console.log(err);
  return errorMessage;
};
