import classNames from 'classnames';
import React from 'react';
import CircularSpinner from '../loaders/CircularSpinner';

const DEFAULT_BUTTON_ELEMENT = 'button';

function ButtonBase({ children, className, primary, full, component, loading, ...others }) {
  const Component = component ? component : DEFAULT_BUTTON_ELEMENT;

  return (
    <Component
      {...others}
      className={classNames(
        `p-2 px-2 font14 rounded shadow flex items-center text-base justify-center relative border-primary border`,
        {
          'bg-primary text-white': primary,
          'w-full': full,
        },
        className
      )}
    >
      {children}

      {loading && <CircularSpinner className="spinner absolute right-0 mr-5" />}
    </Component>
  );
}
export default ButtonBase;
