import React from 'react';
import 'styles/pay.scss';
import CircularSpinner from '../loaders/CircularSpinner';

export default function Pay({ loading, onClick, text }) {
  return (
    <div className="payBtnWrapper">
      <button disabled={loading} onClick={onClick} className="payBtn">
        {loading ? <CircularSpinner className="spinner right-0 mr-5" /> : text || 'Continue'}
      </button>
    </div>
  );
}
